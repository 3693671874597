const HeaderLinks = [
    { label: "Contact Support", url: "https://lcptracker.com/contact/contact-support" },
    { label: "Contact Sales", url: "https://lcptracker.com/contact/contact-sales" },
    { label: "More Information", url: "https://lcptracker.com/about/company" },
];

const FooterLinks = [
    { label: "Privacy Policy", url: "https://lcptracker.com/privacy-policy-for-lcptracker" },
    { label: "California Consumers Privacy Act", url: "https://lcptracker.com/ccpa/" },
    { label: "Terms of Service", url: "https://lcptracker.com/terms-of-service" },
    { label: "About our Ads", url: "https://lcptracker.com/about-our-ads/" },
];

const ProductLinks = [
    { productName: "LCPtracker Pro", logo: "./assets/images/icon_LCPtrackerPro.svg" },
    { productName: "LCPcertified", logo: "./assets/images/icon_LCPcertified.svg" },
    { productName: "Daily Reporter", logo: "./assets/images/icon_DailyReporter.svg" },
    { productName: "Active Insights", logo: "./assets/images/icon_ActiveInsights.svg" },
    { productName: "OnSite", logo: "./assets/images/icon_OnSite.svg" },
    { productName: "Workforce Manager", logo: "./assets/images/icon_WorkforceManager.svg" },
];

export { HeaderLinks, FooterLinks, ProductLinks };