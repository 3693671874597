<div class="bottom-sheet-wrapper">
  <div class="bottom-sheet-wrapper-content">
    <div class="bottom-sheet-wrapper-content-title">{{data.title}}</div>
    <div mat-dialog-content [innerHTML]="data.body| safeHtml" class="bottom-sheet-wrapper-content-body-row"></div>
    <div class="bottom-sheet-wrapper-content-buttons-row">
      <button mat-raised-button *ngIf="cancelButton" (click)="emitCloseComponentEvent()"
      (keypress)="emitCloseComponentEvent()">{{data.cancelButton}}</button>
      <button mat-raised-button *ngIf="confirmationButton" color="primary" (click)="onConfirm(true)">
        {{data.confirmationButton}}
      </button>
    </div>
  </div>
</div>