import { Injectable } from '@angular/core';
import { environment } from 'src/app/../environments/environment';
import { StorageService } from '../../../services/storage/storage.service';
import { Notification, NotificationDisplay } from './contentful.interface';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { from, map, Observable, take } from 'rxjs';
import * as contentful from 'contentful';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  //
  // Contentful Client API
  // https://contentful.github.io/contentful.js/contentful/7.13.1/ContentfulClientAPI.html
  private client = contentful.createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.token
  });

  constructor(
    private storage: StorageService
  ) { }

  //
  // Contentful Client API
  // https://contentful.github.io/contentful.js/contentful/7.13.1/ContentfulClientAPI.html
  //
  getEntriesContentTypeId (id: string) {
    this.client.getEntries({ content_type: id })
    .then((entries) => {
      console.log(entries)
      return entries;
    });
  }

  //
  // Gets a Content Type
  //
  getContentType(typeId : string) {
    this.client.getContentType(typeId)
    .then((contentType) => {
        console.log(contentType);
        this.storage.setItem('getContentType', contentType );
        return contentType;
    });
  }
  //
  // Gets a collection of Content Types
  //
  getContentTypes() {
    this.client.getContentTypes()
    .then((contentType) => {
        console.log(contentType);
        this.storage.setItem('getContentTypes', contentType );
    });
  }



  /**
  * Notification(s) ------------------------------------------------------------
  */
  public notifications$ = new BehaviorSubject<Notification[]>([]);
  public getNotifications () {
    return this.notifications$.asObservable();
  }
  public setNotifications (value: Notification[]) {
    this.notifications$.next(value);
  }
  public displayNotification$ = new BehaviorSubject<any>(null);
  public getDisplayNotifications () {
    return this.displayNotification$.asObservable();
  }
  public setDisplayNotifications (obj: NotificationDisplay) {
    this.displayNotification$.next(obj);
  }
  public totalNotification$ = new BehaviorSubject<number>(0);
  public getTotalNotifications () {
    return this.totalNotification$.asObservable();
  }
  public setTotalNotifications (obj: number) {
    this.totalNotification$.next(obj);
  }
  /**
  * fetch Notifications by order and flatten the object from fields + sys.
  */
  fetchNotification(query?: object):Observable<Notification[]> {
    const notify = this.client.getEntries<any>(Object.assign({}, query, { content_type: 'announcement', order: 'fields.order' }));
    return from(notify).pipe(
      take(1),
      map((response) => response.items.map((results) => Object.assign(results.fields,results.sys)))
    );
  }
  /**
  * Filter out the Notifications that is in Storage from the given response. Returns excluded object.
  */
  filterNotifications( response:Notification[]): Notification[] {
      let check = this.storage.isExisted('contentful-notifcations');
      if (check == false ) { return response; }
      let dismissed : Notification[] = JSON.parse(this.storage.getItem('contentful-notifcations'));
      let arry: Notification[] = [];
      if ( dismissed.length > 0 ) {
        response.forEach((el) => {
          let dup = dismissed.some((element: Notification) => element.id === el.id)
          if (!dup) { arry.push(el); }
        });
      }
      return arry;
  }  
  /**
  * Fetch all Notifications, exclude Storage Notifications, and send them through respective streams.
  */
  passAllNotifications(): void {
    this.fetchNotification().subscribe((response) => {
      response = this.filterNotifications(response);
      this.setDisplayNotifications({display: true, showAll:true });
      this.setNotifications(response);
      this.setTotalNotifications(response.length);
    })
  }
  /**
  * Fetch all Important Notifications ( Handpicked ) and send them through respective streams.
  */
  passImportantNotifications(): void {
    this.fetchNotification().subscribe((response) => {
      response = this.filterNotifications(response);
      this.setTotalNotifications(response.length);
      let notify: Notification[] = [];
      response.forEach((element) => {
        switch(element.category) {
          case 'Holiday':
            notify.push(element);
            break;
          case 'System Slowness':
            notify.push(element);
            break;
          case 'Event':
            notify.push(element);
            break;
          case 'Reminder':
            notify.push(element);
            break;        
        }
      });
      this.setDisplayNotifications({display: true, showAll:false });
      this.setNotifications(notify);
    })
  }
  /**
  * Flag Total Notifications down by one. Check Storage for duplicate, add new, or update the notification.
  */
  storeNotification(object: Notification): void {
    let totalNo = this.totalNotification$.getValue();
    this.setTotalNotifications(totalNo - 1);

    let check = this.storage.isExisted('contentful-notifcations');
    let value = [];
    if ( check == (null || undefined)) { return; }
    if (!check) {
      value.push(object);
      this.storage.setItem('contentful-notifcations', value);
    }
    if (check) {
      let value = JSON.parse(this.storage.getItem('contentful-notifcations'));
      let dup = value.some((element: Notification) => element.id === object.id)
      if (!dup) {
        value.push(object);
        this.storage.setItem('contentful-notifcations', value);
      }
    }
  }





}
