<ng-container *ngIf="showNotify">
  <div #close class="notification-card" *ngIf="notifications?.length">
    <div class="notification-card-header">
        <h6 class="title" *ngIf="notifications.length == 1">{{notifications[0].category}}</h6>
        <h6 class="title" *ngIf="notifications.length > 1">Notifications</h6>
        <button class="close-button"
         (click)="closeNotification()"
         (keyup.enter)="closeNotification()"
         >
            <mat-icon class="property-icon" aria-hidden="false" aria-label="Close button">close</mat-icon>
        </button>
    </div>
    <div class="notification-card-body">
      <div class="notification" *ngFor="let notification of notifications; let i = index;">
        <p class="notification-title">
          {{notification.description}}
        </p>
        <div class="notification-actions">
          <button mat-raised-button color="primary" *ngIf="notification.buttonText"
            (click)="callToAction(notification)"
            (keyup.enter)="callToAction(notification)"
            >{{notification.buttonText}}
          </button>
          <button mat-button color="primary"
            *ngIf="notification.category == 'Holiday' || 
                   notification.category == 'Event' || 
                   notification.category == 'General'"
            (click)="dismissNotification(notification, i)"
            (keyup.enter)="dismissNotification(notification, i)"
            >Dismiss
          </button>
        </div>
      </div>
    </div>
</div>
</ng-container>

