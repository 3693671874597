import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";


//Avoids user to bypasses the onboarding  process

export const UserOnboardingGuard: CanActivateFn = () => {

  //inject oidc security service
  const oidcSecurityService = inject(OidcSecurityService);

  let isUserOnboard = false ;

  oidcSecurityService.getPayloadFromIdToken().subscribe((payload) => {
    if (payload["LastOnboarded"]) {
      isUserOnboard = true;
      return;
    }
    localStorage.clear()
    oidcSecurityService.logoff();
  });
  return isUserOnboard;
};
