import { gql } from "apollo-angular";

export const GET_CONTACT_PAGE = gql`
query getContactData($sectionId: String = ""){
  infoCardCollection(where:{sectionId:$sectionId} ){
    items{
      title
      hours
      phone
      email
      description
      closingStatement
      iconOne
      iconTwo
      sectionId
    }
  }
  aboutUsCollection{
    items{
      description
      officeLocation
      email
      phoneNumber
      images: imagesCollection{
        items{
          url
        }
      }
    }
  }
}`;