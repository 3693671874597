import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UsersEdge } from '../../../graphql/support-console.queries.graphql';
import { ChangeLog } from '../../../interfaces/interfaces';
import { UsersService } from '../../../services/users.service';
import { ChangeLogComponent } from '../../modals/change-log/change-log.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  private _formBuilder = inject(FormBuilder);
  private _usersService = inject(UsersService);
  private _portalDialogService = inject(UserPortalDialogService);
  public _dialog = inject(MatDialog);

  userForm!: FormGroup;
  DEFAULT_PHONE_NUMBER = '000-000-0000';
  DEFAULT_NAME = '--';
  isSaving = new Observable<boolean>();
  editUserInfo: boolean = false;

  @Input() user!: UsersEdge;
  @Output() newEmail: EventEmitter<any> = new EventEmitter<any>();

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.UserFormInit();
    this.savingState();
  }

  savingState(): void {
    this.isSaving = this._usersService.isSaving$.pipe(takeUntil(this._unsubscribeAll));
  }

  /**
 * Initializes the user form controls
 */
  UserFormInit(): void {
    this.userForm = this._formBuilder.group({
      userEmailAddress: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] })
    });
  }

  saveChangeLog(user: UsersEdge): void {
    const changeLog: ChangeLog = {
      fieldsChanged: `From: ${user.node.email}, to: ${this.userForm.controls['userEmailAddress'].value}`,
      changedVia: 'Support Console',
      userId: user.node.id
    }
    this._usersService.saveChangeLog(changeLog).subscribe();
  }

/**
* Saves the new email value
* @param user
*/
  saveUserForm(user: UsersEdge): void {
    this._usersService.isSavingSubject.next(true);
    const userForm = new FormData();

    userForm.set('emailAddress', this.userForm.controls['userEmailAddress'].value);
    userForm.set('lookupUserName', user.node.userName);
    userForm.set('phoneNumber', user.node.phoneNumber ? user.node.phoneNumber : this.DEFAULT_PHONE_NUMBER);
    userForm.set('firstName', user.node.firstName ? user.node.firstName : this.DEFAULT_NAME);
    userForm.set('lastName', user.node.lastName ? user.node.lastName : this.DEFAULT_NAME);

    this._usersService.updateUser(userForm).subscribe({
      next: (res) => {
        this._usersService.isSavingSubject.next(false);
        this.editUserInfo = !this.editUserInfo;
        this._portalDialogService.openCustomUserPortalSnackBar(
          `<p>User email address updated</p>`,
          'OK');
        this.newEmail.emit({ state: true, expandPanelAfterRequest: user.node.id })
        this.saveChangeLog(user);
      },
      error: (err) => {
        this._usersService.isSavingSubject.next(false);
        this.editUserInfo = !this.editUserInfo;
        this._portalDialogService.openCustomUserPortalSnackBar(
          `<p>Error updating email address, please try again</p>`,
          'OK');
        console.log("Error: ", err)
      }
    })
  }

  /**
   * Open the change log modal
   * @param firstName
   * @param lastName
   * @param email
   * @param loginHistory
   */
  openChangeLogDialog(
    id: string,
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    userName: string,
  ): void {
    const _dialogRef = this._dialog.open(ChangeLogComponent, {
      width: '900px',
      maxHeight: '90vh',
      data: {
        id,
        name: `${firstName ? firstName : ' - '} ${lastName ? lastName : ' - '}`,
        email,
        userName
      },
      disableClose: false
    });

    _dialogRef.afterClosed().subscribe((result) => {
      if (environment.env !== 'prod') {
        console.log(`_dialog result: ${result}`);
      }
    });
  }

  /**
 * Enables the emailAddress input and buttons events.
 * @param user
 */
  enableUserForm(user: UsersEdge): void {
    this.userForm.controls['userEmailAddress'].setValue(user.node.email);
    this.editUserInfo = !this.editUserInfo;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }

}
