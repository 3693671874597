import { environment } from 'src/environments/environment'

export interface MENU {
  label: string;
  target: string;
  route: string;
}

export const MENU_ITEMS : MENU[] = [
  {
    label: 'Dashboard',
    target: 'user-portal',
    route: '/dashboard'
  },
  {
    label: 'Contact',
    target: 'user-portal',
    route: '/contact',
  },
  {
    label: 'Support',
    target: 'user-portal',
    route: '/support'
  },
  {
    label: 'Profile',
    target: 'fortify',
    route: environment.onboardingClaims.lastProfileChange
  }
]