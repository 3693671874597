import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { OidcClientNotification } from 'angular-auth-oidc-client/lib/public-events/notification';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';

export class LogLevel { 
  None = 0;
  Info = 1;
  Verbose = 2;
  Warn = 3;
  Error = 4;
}

@Injectable({
  providedIn: 'root'
})
export class AuthLoggerService {
  logLevel: LogLevel = new LogLevel();
  logger$ : BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(
    private authenticate : AuthenticationService
  ) { }


  log(data: any): void {
    this.logWith(this.logLevel.None, data);
  }
  info(data: any): void {
    this.logWith(this.logLevel.Info, data);
  }
  warn(data: any): void {
    this.logWith(this.logLevel.Warn, data);
  }
  error(data: any): void {
    this.logWith(this.logLevel.Error, data);
  }
  private logWith(level: any, msg: OidcClientNotification<any>): void {
    if (level <= this.logLevel.Error) {
      switch (level) {
        case this.logLevel.None:
          return console.log(msg);
        case this.logLevel.Info:
          return console.info('%c' + msg, 'color: #2A4B8B');
        case this.logLevel.Warn:
          return console.warn('%c' + msg, 'color: #FF8C00');
        case this.logLevel.Error:
          return console.error('%c' + msg, 'color: #DC143C');
        default:
          console.debug(msg);
      }
    }
  }

  emptyLogger() {
    this.logger$.next([]);
  }
  getLoggerValue() {
    return this.logger$.getValue();
  }
  addLog(value: any ) {
    this.logger$.next(this.getLoggerValue().concat(value));
    // console.dir(this.getLoggerValue());
  }
  logEventType(object : OidcClientNotification<any>) {
    this.addLog(object); // Add to behavior subject
    this.identifyEvent(object); // help console the events.
  }

  identifyEvent(object: any) : void {
    switch(object.type) {
      case 0 :  // ConfigLoaded
        this.info('0 ConfigLoaded : ' + JSON.stringify(object));
        break;
      case 1 : // checkingAuth
        this.warn('1 checkingAuth : ' + JSON.stringify(object));
        break;
      case 2 : // CheckingAuthFinished
        this.info('2 CheckingAuthFinished : ' + JSON.stringify(object));
        break;
      case 3 : // CheckingAuthFinishedWithError
        this.error('3 CheckingAuthFinishedWithError : ' + JSON.stringify(object));
        break;
      case 4 : // ConfigLoadingFailed
        this.error('4 ConfigLoadingFailed : ' + JSON.stringify(object));
        break;
      case 5 : // CheckSessionReceived
        this.log('5 CheckSessionReceived : ' + JSON.stringify(object));
        break;
      case 6 : // UserDataChanged
        this.info('6 UserDataChanged : ' + JSON.stringify(object));
        this.UserDataChanged(object);
        break;
      case 7 : // NewAuthenticationResult
        this.info('7 NewAuthenticationResult : ' + JSON.stringify(object));
        this.NewAuthenticationResult(object);
        break;
      case 8 : // TokenExpired
        this.warn('8 TokenExpired : ' + JSON.stringify(object));
        break;
      case 9 : // IdTokenExpired
        this.warn('9 IdTokenExpired : ' + JSON.stringify(object));
        break;
      case 10 : // SilentRenewStarted
        this.info('10 SilentRenewStarted : ' + JSON.stringify(object));
        break;
      case 11 : // SilentRenewFailed
        this.error('11 SilentRenewFailed : ' + JSON.stringify(object));
        break;
    };
   }

   UserDataChanged(event: any) : void {
    //
    // if the value is null
    if (event.value == null) {
      this.error('You are NOT Authorized');
    }
    //
    // if the userData and other fields are populated.

   }

   NewAuthenticationResult(event:any) : void {
    //
    // if the silent renew comes back and validates.
    if ( event.value.isAuthenticated === true && 
         event.value.isRenewProcess === true 
    ) {
      this.authenticate.getAccessToken()
      .subscribe(res => { 
        // console.log(res);
        if (res != null) {
          this.authenticate.setAccessToken(res);
          this.info('Updated Access Token');
          this.emptyLogger();
        };
      })
    }
   }



}
