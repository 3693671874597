<!-- Username Credentials Modal Wrapper -->
<div class="modal-wrapper">

  <!-- Username Credentials Modal Content Container -->
  <main class="modal-content">

    <!-- Username Credentials Modal Header -->
    <div class="modal-header">

      <!-- LCPtracker Logo -->
      <img [src]=logo alt="LCPtracker Logo" width="auto" height="auto">

      <!-- Username Credentials Modal Close Button -->
      <button
        mat-icon-button
        aria-label="Close"
        (click)="emitCloseComponentEvent()"
        (keypress)="emitCloseComponentEvent()"
      >
        <mat-icon>close</mat-icon>
      </button>

    </div>

    <!-- Username Credentials Modal Content Body -->
    <div class="modal-body">

      <!-- Username Verification Field Container -->
      <ng-container *ngIf="!productSelection">

        <!-- Modal Title -->
        <h5 class="custom-h5">Log in to your account</h5>

        <!-- Username Field -->
        <mat-form-field appearance="outline">

          <!-- Username Field Label -->
          <mat-label for="login-username">Username</mat-label>

          <!-- Username Field Input - Text -->
          <input #usernameEmail
            id="login-username"
            matInput
            aria-labelledby="username"
            title="username"
            type="text"
            [formControl]="userEmail"
            (keydown.enter)="login()"
          />

        </mat-form-field>

        <!-- Username Field Alert Message Container -->
        <div *ngIf="isAlertMessageON" class="alert-message-container border-blue-500" role="alert">

          <!-- Username Field Alert Message -->
          <p class="text-sm alert-message-p">We can't find that username. Check your spelling or try another
              username.
              <span>Keep in mind: Your username is usually your email.</span>
          </p>
        </div>

        <!-- Login Button -->
        <button
          id="login-username-button" 
          mat-raised-button
          color="primary"
          (click)="login()"
          [ngClass]='{
            "isLoading": isLoading$ | async,
            "isNotValid": !userEmail.valid
          }'
        >
          <!-- Login Button Default Label -->
          <span *ngIf="(isLoading$| async) === false">CONTINUE</span>

          <!-- Login Button Loading Label -->
          <span class="redirect-span" *ngIf="isLoading$|async">

            <!-- Login Button Loading Spinner -->
            <mat-spinner [diameter]="20"></mat-spinner>

            Checking username...

          </span>

        </button>

        <!-- Contact Support Label -->
        <p>Do you have any issue? <a [href]="contactLink" target="_blank">Contact Support</a></p>
      </ng-container>

      <!-- Apps Selector -->
      <ng-container *ngIf="productSelection">

        <!-- Apps Selector Container -->
        <div class="redirection-container">

          <!-- Apps Selector Title -->
          <h5>Select the app you would like to use</h5>

          <!-- Apps Selector Tiles -->
          <div class="redirect-options">
              <div class="product-tile" (click)="trackerRedirect()" (keydown)="trackerRedirect()">
                  <img mat-card-image [src]="trackerImg" alt="LCPtracker Pro" width="auto" height="auto">
                  <div class="product-tile-title">
                      <h6>LCPtracker Pro</h6>
                  </div>
              </div>
              <div class="product-tile" (click)="certifiedRedirect()" (keydown)="certifiedRedirect()">
                  <img mat-card-image [src]="certifiedImg" alt="LCPcertified" width="auto" height="auto">
                  <div class="product-tile-title">
                      <h6>LCPcertified</h6>
                  </div>
              </div>
          </div>
        </div>
      </ng-container>
    </div>
  </main>
</div>