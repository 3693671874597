import { userDataGuard } from './shared/layouts/portal/guards/userData.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { CheckValidSessionGuard, AuthenticationGuard } from './features/authentication/guards/authentication.guard';
import { LandingComponent } from './features/authentication/pages/landing/landing.component';
import { PortalComponent } from './shared/layouts/portal/portal.component';
import { PortalDataResolver} from './shared/layouts/portal/resolvers/portal-data.resolver';
import { SessionActiveGuard } from './features/authentication/guards/sessionActive.guard';
import { UserOnboardingGuard } from './features/authentication/guards/onboarded.guard';
import { WhitelistGuard } from './features/support-console/guards/whitelist.guard';
import { TokenExpiredGuard } from './features/authentication/guards/tokenExpired.guard';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivate: [SessionActiveGuard, CheckValidSessionGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [CheckValidSessionGuard]
  },
  {
    path: '',
    children: [
      {
        path: '',
        component: PortalComponent,
        resolve: {
          footer: PortalDataResolver
        },
        canActivate: [AuthenticationGuard,  UserOnboardingGuard, userDataGuard],
        children: [
          {
            path: 'dashboard',
            loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
            title: 'Dashboard - User Portal - LCPtracker',
            canActivate: [TokenExpiredGuard]
          },
          {
            path: 'contact',
            loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule),
            title: 'Contact - User Portal - LCPtracker',
            canActivate: [TokenExpiredGuard]
          },
          {
            path: 'support',
            loadChildren: () => import('./features/support/support.module').then(m => m.SupportModule),
            title: 'Support - User Portal - LCPtracker',
            canActivate: [TokenExpiredGuard]
          },
          {
            path: 'console',
            loadChildren: () => import('./features/support-console/support-console.module').then(m => m.SupportConsoleModule),
            canActivate: [WhitelistGuard,TokenExpiredGuard],
            title: 'Console - User Portal - LCPtracker'
          },
        ]
      },
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(
      routes,
     {
        useHash: 
          environment.env === 'local' || 
          environment.env === 'dev' ?
          false : true,
      } 
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
