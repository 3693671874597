import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { SupportResponse } from '../graphql/support-data.query';
import { SupportService } from '../support.service';

@Injectable({
  providedIn: 'root',
})
export class SupportContentResolver implements Resolve<SupportResponse> {
  constructor(private supportService: SupportService) {}

  resolve(): Promise<any> {
    return this.supportService.fetchSupportPage('network-only');
  }
}
