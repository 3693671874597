import { gql } from 'apollo-angular';
import { BoolResultModel } from './reset-password-user-by-username.query.graphql';

export const UNLOCK_USER_BY_USERNAME = gql`
  mutation unlockUserByUserName($userName: String!) {
    unlockUser(username: $userName) {
      id
      data
      message
    }
  }
`;

export interface UnlockUserReturningEntity {
  unlockUser: BoolResultModel;
}
