import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Certified, FindWaldo, LcpTracker } from '../../interfaces/auth-interface';
import { environment } from 'src/environments/environment';

export const FORTIFY_2_2: string = '2_2';
export const FORTIFY_NET_6: string = '6';
export const SOURCE_TYPE: number = 0;
@Component({
  selector: 'username-credentials',
  templateUrl: './username-credentials.component.html',
  styleUrls: ['./username-credentials.component.scss']
})
export class UsernameCredentialsComponent {

  isDesktop: boolean = false;
  action: string = 'delete';
  username: string = '';
  bottomSheetRef!: MatBottomSheetRef<UsernameCredentialsComponent>;
  matDialogRef!: MatDialogRef<UsernameCredentialsComponent>
  logo: string = 'assets/images/lcptracker-full-color.svg';
  userEmail = new FormControl('', [Validators.required]);
  isAlertMessageON: boolean = false;
  trackerImg = './assets/images/icon_LCPtrackerPro.svg';
  certifiedImg = './assets/images/icon_LCPcertified.svg';
  trackerFlag: boolean = false;
  certifiedFlag: boolean = false;
  productSelection: boolean = false;
  contactLink = "https://lcptracker.com/contact/contact-support";

  @ViewChild('usernameEmail') usernameEmail! : ElementRef<HTMLInputElement>;
  @Output() closeComponent: EventEmitter<boolean> = new EventEmitter();
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  constructor(
    public authenticationService: AuthenticationService,
  ) { }

  ngAfterViewInit(): void {
    this.focusOnUsername();
  }

 /**
 * focus on username when dialog / bottomsheet opens up.
 */
  focusOnUsername() : void {
    if (this.usernameEmail !== undefined) {
      this.usernameEmail.nativeElement.focus();
    };
  }
/**
 * when the username value exists : check with Waldo to determine which fortify version exists.
 */
  login(): void {
    if (this.userEmail.value != null) {
      this.isAlertMessageON = false;
      this.isLoadingSubject.next(true);
      this.authenticationService.checkUserIDPSource(this.userEmail.value).subscribe((res: FindWaldo) => {
        if (res.source == FORTIFY_2_2) {
          location.href = environment.applications.fortify.username_old + this.userEmail.value;
        } else if (res.source == FORTIFY_NET_6) {
          this.authenticationService.login(this.userEmail.value || '');
        } else {
          this.retrieveTrackerUserInfo();
        }
      });
    }
  }

  /**
 * Check if user exists in LCPtrackerApi 
 * then calls retrieveCertifiedUserInfo
 */
  retrieveTrackerUserInfo(): void {
    if (!this.userEmail.value) {
      return;
    }

    this.authenticationService.checkUserExistsTrackerPro(this.userEmail.value).subscribe({
      next: (res: LcpTracker) => {
        if (res.FortifyUserSourceType == SOURCE_TYPE) {
          this.trackerFlag = true;
        } else {
          this.trackerFlag = false;
        }
      },
      error: () => {
        console.error('User not found in LCPtrackerApi');
        this.retrieveCertifiedUserInfo();
      },
      complete: () => {
        this.retrieveCertifiedUserInfo();
      }
    });
  }

  /**
   * Check if user exists in CertifiedApi
   * then calls checkApiSourceResponse
   */
  retrieveCertifiedUserInfo(): void {
    if (!this.userEmail.value) {
      return;
    }

    this.authenticationService.checkUserExistsCertified(this.userEmail.value).subscribe({
      next: (res: Certified) => {
        if (res.FortifyUserSourceType == SOURCE_TYPE) {
          this.certifiedFlag = true;
        } else {
          this.certifiedFlag = false;
        }
      },
      error: () => {
        console.error('User not found in CertifiedApi');
        this.checkApiSourceResponse(this.trackerFlag, this.certifiedFlag);
      },
      complete: () => {
        this.checkApiSourceResponse(this.trackerFlag, this.certifiedFlag)
      }
    });
  }

  /**
   * Evaluate tracker and certified flags to select where
   * user wants to be redirected
   */
  checkApiSourceResponse(trackerFlag: boolean, certifiedFlag: boolean): void {
    this.isLoadingSubject.next(false);

    if (trackerFlag && !certifiedFlag) {
      this.trackerRedirect();
      return;
    }

    if (!trackerFlag && certifiedFlag) {
      this.certifiedRedirect();
      return;
    }

    if (trackerFlag && certifiedFlag) {
      this.productSelection = true;
      return;
    }

    this.isAlertMessageON = true;

  }

  /**
   * If Username only exists in TrackerAPI this method is
   * called to redirect user from User Portal to Tracker CDN
   */
   trackerRedirect(): void {
    location.href = `${environment.applications.lcptracker.login}/login/login/${this.userEmail.value}/password`;
  }

  /**
   * If Username only exists in CertifiedAPI this method is
   * called to redirect user from User Portal to Certified CDN
   */
  certifiedRedirect(): void {
    location.href = `${environment.applications.certified.login}/login/login/${this.userEmail.value}/password`;
  }
  
  emitCloseComponentEvent() {
    this.closeComponent.emit(true);
  }

}