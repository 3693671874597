<section class="contact-layout-container">
  <div class="contact-layout-body">
    <div *ngIf="informationCards" class="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2.28 sm:gap-1.14 body-info-cards">
      <ng-container *ngFor="let item of informationCards">
        <app-info-card [data]="item"></app-info-card>
      </ng-container>
    </div>
    <div *ngIf="aboutUs" class="my-2.28 body-about-us">
      <app-about-us [data]="aboutUs" ></app-about-us>
    </div>
  </div>
</section>


