import { Component, Input, OnInit } from '@angular/core';
import { AboutUs } from './about-us.interface';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  @Input() data: AboutUs[] = [];
  gridValue: string = '';

  constructor() { }

  ngOnInit(): void {
    this.createGrid();
  }

  createGrid(): void {
    this.gridValue = `grid-cols-${this.data[0]?.images?.items.length}`;
  }
}
