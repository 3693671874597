<div class="user-information">
  <form [formGroup]="userForm" (submit)="saveUserForm(user)">
    <div class="user-col-header">
      <h6>User</h6>
      <div class="users-actions">
        <button type="button" class="edit-btn" mat-stroked-button (click)="enableUserForm(user)" *ngIf="!editUserInfo">Edit</button>
        <button type="sumbit" class="save-btn" color="primary" mat-flat-button *ngIf="editUserInfo" [disabled]="userForm.controls['userEmailAddress'].invalid || userForm.controls['userEmailAddress'].value === user.node.email ||(isSaving | async)">
          {{(isSaving | async) ? 'Saving' : 'Save'}}</button>
        <button [ngClass]="{'disable-button-state': (isSaving | async)}" class="cancel-btn" type="button" mat-stroked-button *ngIf="editUserInfo" (click)="enableUserForm(user)" [disabled]="isSaving | async">Cancel</button>
      </div>
    </div>
  
    <div class="pl-0.57">
      <p class="label">Username</p>
      <p class="value">{{user.node.userName ? user.node.userName : '-'}}</p>
  
      <p class="label">First name</p>
      <p class="value">{{user.node.firstName ? user.node.firstName : '--'}}</p>
  
      <p class="label">Last name</p>
      <p class="value">{{user.node.lastName ? user.node.lastName : '--'}}</p>
  
      <div *ngIf="!editUserInfo">
        <p class="label">Email</p>
        <p class="value">{{user.node.email ? user.node.email : '-'}}</p>
      </div>
  
      <mat-form-field appearance="fill" class="w-full" *ngIf="editUserInfo">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="userEmailAddress" name="{{user.node.email}}">
      </mat-form-field>
  
      <p class="label">Phone Extension</p>
      <p class="value">{{user.node.phoneExtension ? user.node.phoneExtension : '-'}}</p>
  
      <p class="label">Phone Number</p>
      <p class="value">{{user.node.phoneNumber ? user.node.phoneNumber : '000-000-0000'}}</p>
  
      <p class="label">PIN</p>
      <p class="value">{{user.node.primaryIdentifierNumber ? user.node.primaryIdentifierNumber : '-'}}</p>
  
      <p class="label">Suffix</p>
      <p class="value">{{(user.node.suffix) ? user.node.suffix : '-'}}</p>
  
      <p class="label">Prefix</p>
      <p class="value">{{(user.node.prefix) ? user.node.prefix : '-'}}</p>
  
      <button class="change-log-btn" mat-button type="button" (click)="openChangeLogDialog(user.node.id ,user.node.firstName, user.node.lastName, user.node.email, user.node.userName)">Console Change Log</button>
    </div>
  </form>
</div>