import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { HomePageLinks, TilesNode } from '../../components/app-card/app-list.interface';
import { LayoutStateService } from 'src/app/shared/layouts/portal/services/layout-state.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HowCanIInterface } from '../../components/how-can-i/how-can-i';
import { UserNode } from '../../graphql/profile-info.query';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { CarouselItem, MarketingBannerItem } from '../../graphql/dashboard-data.query';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardComponent implements OnInit, OnDestroy {

  profileCard!: UserNode;
  appTiles!: HomePageLinks;
  howCanIData!: HowCanIInterface[];
  slidesData!: CarouselItem[];
  marketingBannerData!: MarketingBannerItem;
  isDesktop = new Observable();

  private unsubscribe = new Subject<boolean>();

  constructor(
    private layoutState: LayoutStateService,
    private _route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    const routeDashSubscription = this._route.data.subscribe({
      next: data => {
        this.slidesData = data['dashboard'].carouselCollection.items;
        this.howCanIData = data['dashboard'].howCanICollection.items;
        this.marketingBannerData = data['dashboard'].marketingBannerCollection.items[0];
        this.profileCard = data['profileUserInfo'];

        // Filters the apps in a single array of app tiles to be displayed
        const filteredAppTiles = data['appTiles'].tilesNodes.filter(
          (appTile: TilesNode): TilesNode | void => {
            // If the app tile does not have a restriction that only staff
            // can see it, the app tile gets in the list of displayed tiles
            if (!appTile.staffOnly) {
              return appTile
            }

            // If the app tile can only be displayed to staff users and the
            // the user has admin permissions, the app tile gets in the list
            // of displayed tiles
            if (this.authenticationService.canUseSupportToolsSubject.value) {
              return appTile
            }

          }
        )

        // Filtered app tiles get in the displayed array
        this.appTiles = {
          tilesNodes: filteredAppTiles
        };

      },
      error: error => {
        routeDashSubscription.unsubscribe();
      },
      complete: () => { routeDashSubscription.unsubscribe() }
    })

    this.isDesktop = this.layoutState.isDesktopLayout$.pipe(takeUntil(this.unsubscribe));
  }

  ngOnDestroy(): void {
    this.unsubscribe.complete();
  }
}
