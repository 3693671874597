import { Component, OnInit } from '@angular/core';
import { InformationCard } from '../../../../shared/components/info-card/info-card';
import { ActivatedRoute } from '@angular/router';
import { AboutUs } from '../../components/about-us/about-us.interface';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  informationCards: InformationCard[] | null = [];
  aboutUs: AboutUs[] | null = [];

  constructor(private _route: ActivatedRoute) { }

  ngOnInit(): void {
    const routeContactSubscription = this._route.data.subscribe({
      next: (data) =>{
        this.informationCards = data['contact'].infoCardCollection.items;
        this.aboutUs = data['contact'].aboutUsCollection.items;
      },
      error: (error) => {
        routeContactSubscription.unsubscribe();
      },
      complete: () => {routeContactSubscription.unsubscribe()}
    })
  }
}

