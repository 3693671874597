import { gql } from "apollo-angular";
import { SupportQuestion } from "../components/support-question-card/support-question-card.interface";

export const GET_SUPPORT_PAGE = gql`
query getSupportData($sectionId: String = "Support") {
  infoCardCollection(where: {sectionId: $sectionId}) {
    items {
      title
      hours
      phone
      email
      description
      closingStatement
      iconOne
      iconTwo
      sectionId
    }
  }
  trainingMaterialsCardCollection {
    items {
      title
      bodyMessage
      assetFile {
        title
        description
        contentType
        fileName
        size
        url
      }
      linkLabelName
      externalLink
    }
  }
}`;


export interface SupportResponse {
   data: SupportData;
 }
 export interface SupportData {
   infoCardCollection: InfoCardCollection;
   trainingMaterialsCardCollection: TrainingMaterialsCardCollection;
 }
 export interface InfoCardCollection {
   items?: (ItemsEntity)[];
 }
 export interface ItemsEntity {
   title: string;
   hours: string;
   phone: string;
   email: string;
   description: string;
   closingStatement: string;
   iconOne: string;
   iconTwo: string;
   sectionId: string;
 }
 export interface TrainingMaterialsCardCollection {
   items?: ContenfulSupportQuestions[];
 }
 export interface ContenfulSupportQuestions extends SupportQuestion {}
 
 export interface AssetFile {
   title: string;
   description: string;
   contentType: string;
   fileName: string;
   size: number;
   url: string;
 }
