import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FindWaldo } from '../../interfaces/auth-interface';
import { FORTIFY_NET_6 } from '../../components/username-credentials/username-credentials.component';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss']
})
export class LoginSSOComponent implements OnInit {
  paramId!: string;
  usernameFromRedirect: string;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.usernameFromRedirect = (this.route.snapshot.paramMap.get('username') || '').trim();
  }

  ngOnInit(): void {
    if (this.usernameFromRedirect) {
      this.validateUsernameParam(this.usernameFromRedirect);
      return
    }
    this.authenticationService.login();
  }

  validateUsernameParam(username: string): void {
    this.authenticationService.checkUserIDPSource(username).subscribe((res: FindWaldo) => {
      if (res.source === FORTIFY_NET_6) {
        this.authenticationService.login(username);
      } else {
        this.router.navigate(['']);
      }
    });
  }
}
