import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-dialog-wrapper',
  template: `
    <username-credentials (closeComponent)="handleCloseEvent($event)"></username-credentials>
  `
})
export class LoginDialogWrapperComponent {

  constructor(public dialogRef: MatDialogRef<LoginDialogWrapperComponent>) {}

  handleCloseEvent($event: any): void {
    this.dialogRef.close();
  }
}