<div class="marketing-advertisement-wrapper">
  <div [ngStyle]="{
    'background-color': advertisement.background || 'transparent'}">
    <a [href]="advertisement.link" [target]="advertisementTarget">
      <img
        width="auto" height="auto"
        [src]="advertisement.asset.url"
        [alt]="advertisement.title">
    </a>
  </div>
</div>
