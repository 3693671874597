<div class="login-history-information">
  <h6>Login history</h6>
  <mat-progress-bar *ngIf="isLoadingProgressBar" mode="indeterminate"></mat-progress-bar>
  <ng-container *ngIf="!isLoadingProgressBar">
    <div class="login-wrapper pl-0.57 flex justify-between" *ngFor="let item of loginHistoryGraphQL">
      <mat-icon>laptop_mac</mat-icon>
      <div class="flex justify-between w-11/12">
        <div class="login-details">
          <p class="date">{{item.node.event | date}}</p>
          <div class="time-ip">
            <span class="time">{{item.node.event | date: 'shortTime'}}</span><span> | </span><span>IP: {{item.node.gatewayIPAddress}}</span>
          </div>
        </div>
        <div class="px-4">
          <span
            *ngIf="!showFullAuthTypeBadge"
            class="inline-flex items-center justify-center w-24 gap-2 px-2 py-px mx-2 text-xs font-medium rounded-md ring-1 ring-inset"
            [ngClass]="validateAuthTypeBadgeColor(item.node.authType) ? 'bg-red-50 text-red-700 ring-red-600/10' : 'bg-green-50 text-green-700 ring-green-600/20'"
          >
            <mat-icon class="m-0 text-xl">{{validateAuthTypeBadgeColor(item.node.authType) ? 'close' : 'done'}}</mat-icon>
              {{validateAuthTypeMessage(item.node.authType)}}
          </span>
          <mat-icon
            *ngIf="showFullAuthTypeBadge"
            class="mr-2"
            [ngClass]="validateAuthTypeBadgeColor(item.node.authType) ? 'text-red-700' : 'text-green-700'"
          >
            {{validateAuthTypeBadgeColor(item.node.authType) ? 'close' : 'done'}}
          </mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
  <button mat-button *ngIf="loginHistoryGraphQL.length > 4" (click)="openLoginHistoryDialog(user.node.firstName, user.node.lastName, user.node.email, user.node.id)">Show Details</button>
  <p class="results-not-found" *ngIf="!loginHistoryGraphQL.length && !isLoadingProgressBar">No login history records were found for this user.</p>
</div>