import { Injectable } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { GET_PROFILE_INFO, UserProfileResponse } from 'src/app/features/dashboard/graphql/profile-info.query';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetUserDataByIDService {

  applyPolicy!: WatchQueryFetchPolicy;
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private graphqlHost: GraphqlHostService,
    private authenticationService: AuthenticationService,
  ) {}

  fetchUserDataByID(policy: FetchPolicy): Promise<any> {
    this.isLoadingSubject.next(true);
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const optionalContext = null;

    const vars = { id: this.authenticationService.userId.value };

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_PROFILE_INFO,
        vars,
        optionalContext,
        this.applyPolicy,
      ).then((response: UserProfileResponse) => {
        this.authenticationService.userName.next(response.userProfile.userNodes[0].userName || '')
        resolve(response.userProfile.userNodes[0]);
        this.isLoadingSubject.next(false);
      }).catch((error: any) => {
        reject(error);
      })
    })
  }
}
