import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortText',
})
export class ShortTextPipe implements PipeTransform {
    transform(text: string, limit: number): string | null {
        if (!text) {
            return null;
        }
        if (text.length > limit) {
            return text.slice(0, limit) + '...';
        }
        return text;
    }
}
