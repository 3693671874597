import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { MENU } from './../menu';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FooterResponse } from 'src/app/shared/components/footer/footer-interface';

interface RouteElement {
  path: string | null;
  label: string;
  data: any;
  node: RouterNodeElement | null;
}


interface RouterNodeElement {
  label: string;
  children: RouteElement[]
}

@Component({
  selector: 'app-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss']
})
export class DesktopLayoutComponent implements OnInit {
  /**
  * These are the route elements used to build the virtual router and to render the list on the sidebar
  */
  @Input()
  routes: RouteElement[] = [];

  // This will be removed in the future because it is no longer necessary, it was not removed so as not to break the components that use it
  @Input()
  routerNodes: RouterNodeElement[] = [];

  @Input() overlay: boolean = true;

  @Input() menuItems: MENU[] = [];

  secondLevelNode!: RouterNodeElement | null;

  thirdLevelNode!: RouterNodeElement | null;

  isExpanded!: boolean;

  thirdLevelLabel = '';

  currentActive = '';

  flatRouterNodes: RouteElement[] = [];

  filteredRoutes: RouteElement[] = [];

  isThirdLevel: boolean = false;

  @Input() observerMode: boolean = false;

  private unsusbcribe = new Subject<boolean>();
  footer!: FooterResponse[];

  constructor(
    private _route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this._route.data.pipe(
      takeUntil(this.unsusbcribe)
    ).subscribe(data => {
      this.footer = data['footer'].footerCollection.items;
    })
  }

  ngOnInit(): void {
    this.sidebarStatusHandler();
  }

  /**
   * Creates dynamically virtual routes for this component
   */
  sidebarStatusHandler(name?: string, isCloseClick?: boolean): void {
    if (isCloseClick) {
      this.isExpanded = false;
      this.secondLevelNode = null;
    }

    if (this.routes.length) {
      if (name) {
        this.currentActive = name;
      }

      if (!isCloseClick) {
        this.isExpanded = false;
      }
    }
  }

  /**
   * Set the current node to show in the secondary sidebar
   */

  setCurrentNode(currentNode: RouterNodeElement, name?: string) {
    if (this.secondLevelNode === currentNode) {
      this.isExpanded = !this.isExpanded;
    } else {
      this.isExpanded = true;
    }
    this.secondLevelNode = currentNode;
    if (name) {
      this.currentActive = name;
    }

    if (this.isThirdLevel) {
      this.isThirdLevel = false;
    }
  }

  setLevelNavigation(label?: string, node?: RouterNodeElement) {
    if (node) {
      this.thirdLevelNode = node;
    }

    if (label) {
      this.thirdLevelLabel = label;
    }
    this.isThirdLevel = !this.isThirdLevel;
  }

  /**
   * Triggers the action to logout the user from the identity server
   */
  logout(): void {
    this.authenticationService.logout();
  }

}
