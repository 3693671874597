<div class="actions-information">
    <h6>Actions</h6>

    <div class="actions-information-buttons-wrapper">
        <!-- Unlock Account Button -->
        <button type="button" mat-stroked-button color="primary" (click)="unlockUser(user.node.userName, user.node.id)"
            (keydown.enter)="unlockUser(user.node.userName, user.node.id)" *ngIf="lockedEnd(user.node.lockoutEnd)">
            Unlock Account
        </button>

        <!-- 2FA Recovery Button -->
        <button type="button" mat-stroked-button color="primary"
            (click)="getRecoveryCode(user.node.id, user.node.id, user.node.userName)"
            (keydown.enter)="getRecoveryCode(user.node.id, user.node.id, user.node.userName)"
            *ngIf="user.node.twoFactorEnabled">
            2FA recovery
        </button>

        <!-- Reset Password Button -->
        <button type="button" mat-stroked-button color="primary"
            (click)="resetPassword(user.node.userName, user.node.id)"
            (keydown.enter)="resetPassword(user.node.userName, user.node.id)" *ngIf="user.node.emailConfirmed">
            Reset password
        </button>

        <!-- Close Sessions Button -->
        <button type="button" mat-stroked-button color="primary"
            (click)="closeSessions(user.node.id, user.node.userName)"
            (keydown.enter)="closeSessions(user.node.id, user.node.userName)">
            Close sessions
        </button>
    </div>
</div>