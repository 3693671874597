<!-- Modal Component Wrapper -->
<div class="modal-wrapper">

  <!-- Modal Content Container -->
  <main class="modal-content">

    <!-- Modal Title -->
    <h5>{{title}}</h5>

    <!-- Modal Content/Text -->
    <div mat-dialog-content [innerHTML]="htmlContent | safeHtml" class="body-row"></div>

    <!-- Modal Actions/Buttons -->
    <div mat-dialog-actions class="buttons-row">

      <!-- Modal Cancel/Close Button -->
      <button *ngIf="cancelButton" mat-button mat-dialog-close color="primary"
        [id]="'cancel-' + id"
        >{{cancelButton}}
      </button>

      <!-- Modal Confirm Button -->
      <button *ngIf="confirmButton" (click)="onConfirm(true)" mat-raised-button color="primary"
        [id]="'confirm-' + id"
        >{{confirmButton}}
      </button>

    </div>
  </main>
</div>