<div class="button-wrapper">
  <button
    mat-button
    type="button"
    [disabled]="isDisabled"
    [ngClass]="classes"
    [ngStyle]="{ 'width': isDemo ? 'fit-content' : '' }"
    (click)="emitButtonWasClicked()"
  >
    <!-- Prefix icon -->
    <mat-icon *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>

    <!-- Button Label -->
    {{ label }}

    <!-- Suffix icon -->
    <mat-icon *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>
  </button>
</div>

