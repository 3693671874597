<!-- <app-desktop-layout [overlay]="true" [routes]="routes"></app-desktop-layout> -->
<div class="main-container">
  <div class="header-container">
    <app-header></app-header>
  </div>
  <main class="drawer-container">
    <div class="drawer-sidebar">
      <!-- secondary-sidebar -->
      <ng-container *ngIf="secondLevelNode && secondLevelNode.children && isExpanded">

        <div class="secondary-sidebar" *ngIf="!isThirdLevel">
          <header>
            <h6>{{secondLevelNode.label}}</h6>
          </header>
          <div class="sidebar-item ripple" routerLinkActive="item-selected" (click)="sidebarStatusHandler()"
            (keypress)="sidebarStatusHandler()" *ngFor="let secondTierNode of secondLevelNode.children">
            <!-- second level routes -->
            <ng-container  *ngIf="secondTierNode.path && !secondTierNode.node">
              <a [routerLink]="'/'+secondTierNode.path">
                {{secondTierNode.label}}
              </a>
            </ng-container>
            <!-- second level nodes -->
            <ng-container *ngIf="!secondTierNode.path && secondTierNode.node">
              <a (click)="setLevelNavigation(secondTierNode.label, secondTierNode.node)">
                {{secondTierNode.label}}
                <mat-icon aria-hidden="Arrow Right Icon" aria-label="Arrow Right Icon">chevron_right</mat-icon>
              </a>
            </ng-container>
          </div>
        </div>
        <!-- 3rd level navigation -->
        <div class="secondary-sidebar" *ngIf="isThirdLevel">
          <header>
            <div class="back-indicator">
              <a (click)="setLevelNavigation()">
                <mat-icon aria-hidden="Arrow Left Icon" aria-label="Arrow Left Icon">chevron_left</mat-icon>
                Back to {{thirdLevelLabel}}
              </a>
            </div>
            <ng-container *ngIf="thirdLevelNode">
              <h6>{{thirdLevelNode.label}}</h6>
            </ng-container>
          </header>
          <ng-container *ngIf="thirdLevelNode">
            <div class="sidebar-item ripple" routerLinkActive="item-selected" (click)="sidebarStatusHandler()"
              (keypress)="sidebarStatusHandler()" *ngFor="let thirdLevelNode of thirdLevelNode.children">
              <a [routerLink]="'/'+ thirdLevelNode.path">
                {{thirdLevelNode.label}}
              </a>
            </div>
          </ng-container>
        </div>
        <div class="backdrop-drawer-main-container" (click)="isExpanded = false" (keydown)="isExpanded = false"></div>
      </ng-container>

      <div class="drawer-list">
        <!-- Side Navbar Top Options -->
        <div>
          <ng-container *ngFor="let menu of menuItems">
            <ng-container *ngIf="menu.target === 'user-portal'">
              <a id={{menu.label}} class="sidebar-item ripple" [routerLink]="menu.route" routerLinkActive="item-selected">
                {{menu.label}}
              </a>
            </ng-container>
            <ng-container *ngIf="menu.target !== 'user-portal'">
              <a id={{menu.label}} class="sidebar-item ripple" [attr.href]="menu.route" [attr.target]="'_self'">
                {{menu.label}}
              </a>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngFor="let route of routes; let i = index">
          <!-- Routes -->
          <ng-container *ngIf="route.label && route.path && !route.node">
            <!-- <div class="sidebar-item ripple" routerLinkActive="item-selected"  [ngClass]="{'item-selected': currentActive === route.label }"
              (click)="sidebarStatusHandler(route.label, true)"
              (keydown)="sidebarStatusHandler(route.label, true)">
              <a [routerLink]="'/'+route.path">
                {{route.label}}
              </a>
            </div> -->
          </ng-container>
          <!-- Nodes -->
          <ng-container *ngIf="route.label && !route.path && route.node">
            <div class="sidebar-item ripple"  [ngClass]="{'item-selected': currentActive === route.label}"
              (click)="setCurrentNode(route.node, route.label )" (keydown)="setCurrentNode(route.node, route.node.label)">
              <a>
                {{route.label}}
                <mat-icon aria-hidden="false" aria-label="Example home icon">chevron_right</mat-icon>
              </a>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="drawer-content" [ngClass]="[isExpanded && !overlay ? 'tight-content':'expanded-content']">
      <app-notification></app-notification>
      <!-- Router outlet projection -->
      <ng-content></ng-content>
      <!--End Router outlet projection -->
      <app-footer [footerData]="footer" ></app-footer>
    </div>
  </main>
</div>