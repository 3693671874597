import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router, private authenticationService: AuthenticationService
    ) { }
  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated$.value) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class CheckValidSessionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
    ) { }
  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated$.value) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }
}