import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject, takeUntil, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../../../consts/consts';
import { Login, LoginHistoryEdge } from '../../../graphql/login-history.queries.graphql';
import { PaginationState } from '../../../graphql/support-console.queries.graphql';
import { LoginHistoryService } from '../../../services/login-history.service';
import { MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT } from '../../users/users.component';

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.scss']
})
export class LoginHistoryComponent implements OnInit {
  /*Dependency Injections Angular 14 Feature*/
  private _loginHistoryService = inject(LoginHistoryService);
  private _breakpointObserver = inject(BreakpointObserver);
  private _dialogRef = inject(MatDialogRef<LoginHistoryComponent>);

  /*Data and Pagination Variables*/
  loginHistoryGraphQL: LoginHistoryEdge[] = [];
  paginationVars: any;
  paginationState: PaginationState = INIT_PAGINATION_STATE;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  /* Flags */
  isLoading = new Observable<boolean>();
  errorServer = new Observable<boolean>();
  shouldDialogBeClosed: boolean = false;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {name: string | null, email: string | null, userId: string},
  ) {
  }

  ngOnInit(): void {
    this.initValues();
    this.loadingAndServerState();
    this.pageDataSearchInit();
    this.getLoginHistoryData();
    this.forceCloseDialog();
  }

  /**
   * Initializes the values for pagination and search in the ChangeLogService.
   * This method sets initial values for pagination variables, pagination state, and search text.
   */
  initValues(): void {
    if (this._loginHistoryService.paginationVarsSubject) {
      this._loginHistoryService.paginationVarsSubject.next(INIT_PAGINATION_VARS);
    }
    if (this._loginHistoryService.paginationStateSubject) {
      this._loginHistoryService.paginationStateSubject.next(INIT_PAGINATION_STATE);
    }
  }

  /**
  * Initializes the loading var
  */
  loadingAndServerState(): void {
    if (this._loginHistoryService.isLoading$) {
      this.isLoading = this._loginHistoryService.isLoading$.pipe(
        takeUntil(this._unsubscribeAll),
      );
    }
    if (this._loginHistoryService.errorServer$) {
      this.errorServer = this._loginHistoryService.errorServer$.pipe(
        takeUntil(this._unsubscribeAll)
      );
    }
  }

  /**
     * Initializes the pagination state and vars
     */
  pageDataSearchInit(): void {
    if (this._loginHistoryService.paginationVars$) {
      this._loginHistoryService.paginationVars$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: pageInfo => {
          this.paginationVars = pageInfo;
        },
        error: error => {
          console.error('Error to get the pagination vars obs: ', error);
        },
        complete: () => { },
      });
    }
    if (this._loginHistoryService.paginationState$) {
      this._loginHistoryService.paginationState$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: (pageState: PaginationState) => {
          this.paginationState = pageState;
        },
        error: error => {
          console.error('Error to get the pagination state obs: ', error);
        },
        complete: () => { },
      });
    }

  }

  /**
   * Calls the service to get a list of loginHistory based on the given User's id
   */
  getLoginHistoryData(): void {
    this._loginHistoryService.getLoginHistory('network-only', this.data.userId).then((res: Login) => {
      this.loginHistoryGraphQL = res.edges;
    }).catch(error => {
      console.error('Something went wrong for getLoginHistory: ', error);
    });
  }

  /**
   * Validates the text in authType to define the text displayed in the badge
   * @param authType 
   * @returns 
   */
  validateAuthTypeMessage(authType: string): string {
    switch(authType) {
      case 'FAILED_PWD':
        return 'Password Failed';
      case 'FAILACODE':
        return '2FA Code Failed';
      case 'FAILED_OTP':
        return 'Email OTP Failed';
      default:
        return 'Login Successful';
    }
  }

  /**
   * Validates if authType contains the text 'FAILED' to define
   * the color of the badge and the icon
   * @param authType 
   * @returns 
   */
  validateAuthTypeBadgeColor(authType: string): boolean {
    if(authType.includes('FAIL'))
    {
      return true;
    }else{
      return false;
    }
  }

  /**
 * Handles the pagination events
 */
  handlePage($event: PageEvent): void {
    if (this.paginationState.pageSize !== $event.pageSize) {
      this.handleOnPageSizeChange($event);
    }

    if ($event.pageIndex === 0) {
      this.goToFirstPage($event);
      return;
    }

    if ($event.pageIndex == this.getNumberOfPages()) {
      this.goToLastPage($event);
      return;
    }

    if ($event.previousPageIndex !== undefined && $event.pageIndex < $event.previousPageIndex) {
      this.handlePreviousPage($event);
      return;
    }

    if ($event.previousPageIndex !== undefined && $event.pageIndex > $event.previousPageIndex) {
      this.handleNextPage($event);
    }
  }

  /**
   * Calculates number of pages base on the total elements and the current page size
   */
  getNumberOfPages(): number {
    if (this.paginationState.totalElements && this.paginationState.pageSize) {
      return (Math.ceil(this.paginationState.totalElements / this.paginationState.pageSize) - 1);
    }
    return 0;
  }

  /**
   * Changes the current pageSize and update the pagination vars to trigger the query
   * @param $event
   */
  handleOnPageSizeChange($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: null,
      before: null,
      last: null,
    };
    this._loginHistoryService.paginationVarsSubject.next(this.paginationVars);

  }

  /**
   * Changes page to the next one
   * @param $event
   */
  handleNextPage($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: this.paginationState.endCursor,
      before: null,
      last: null,
    };
    this._loginHistoryService.paginationVarsSubject.next(this.paginationVars);
    this.getLoginHistoryData();
    this.paginationState.currentPage = $event.pageIndex;
  }

  /**
   * Changes page to the next one
   * @param $event
   */
  handlePreviousPage($event: PageEvent): void {
    this.paginationVars = {
      first: null,
      after: null,
      before: this.paginationState.startCursor,
      last: $event.pageSize,
    };
    this._loginHistoryService.paginationVarsSubject.next(this.paginationVars);
    this.getLoginHistoryData();
    this.paginationState.currentPage = $event.pageIndex;
  }

  /**
   * Changes the page to the firts one
   * */
  goToFirstPage($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: null,
      before: null,
      last: null,
    };
    this._loginHistoryService.paginationVarsSubject.next(this.paginationVars);
    this.getLoginHistoryData();
  }

  /**
   * Changes the page to the last one
   * */
  goToLastPage($event: PageEvent): void {
    this._loginHistoryService.lastPageSizeChange.next($event.pageSize);
    this.paginationVars = {
      first: null,
      after: null,
      before: null,
      last: null,
    };
    if ($event.length % $event.pageSize !== 0) {
      this.paginationVars.last = $event.length % $event.pageSize;
      this._loginHistoryService.paginationVarsSubject.next(this.paginationVars);
    } else {
      this.paginationVars.last = $event.pageSize;
      this._loginHistoryService.paginationVarsSubject.next(this.paginationVars);
    }
    this.getLoginHistoryData();
  }

  /**
   * Monitors the screen width using BreakpointObserver and closes the dialog
   * if the screen width is below a certain threshold defined by MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT.
   */
  forceCloseDialog(): void {
    this._breakpointObserver
      .observe(`(max-width: ${MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT}px)`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.shouldDialogBeClosed = result.matches;
        if (this.shouldDialogBeClosed) {
          this._dialogRef.close(true); // Close the dialog if shouldDialogBeClosed is true
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
