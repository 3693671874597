import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { ContentfulService } from 'src/app/shared/services/contentful-http/contentful.service';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { UserPortalDialogService } from '../../services/dialog/user-portal-dialog.service';

@Component({
  selector    : 'app-header',
  templateUrl : './header.component.html',
  styleUrls   : ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  //
  // Header Settings
  //
  @Output() openNav = new EventEmitter<boolean>();
  @Output() headerAction = new EventEmitter<Event>();
  @Input() isOpen = false;
  @Input() animateBell = false;
  @Input() showNotify: boolean = true;                      // this.contentful.getNotifications() error handling will always override. Test there..
  streamNotify! : Subscription;                             // to remove the observable when it leaves.
  @Input() notifyCount: number = 99;                        // Receives the amount of unread notifications
  @Input() loading : boolean = false;
  //
  // Logo
  //
  @Input() logo: string = './assets/images/logo.png';       // Stores the logo image path

  //
  // Application Settings
  //
  @Input() hasApplication: boolean = true;                  // Manages the display of the Application Button
  @Input() applicationName: string = 'QA v2 LCP';           // Stores the value to show in the Application Button
  @Input() hasRole: boolean = true;                         // Manages the display of the Role Button

  //
  // User Settings Icons
  //
  @Input()
  additionalLinks: boolean = true;
  @Input() search: boolean = true;                          //Enables search icon
  @Input() apps: boolean = true;                            //Enables app grid icon
  @Input() account: boolean = true;                         //Enables user account icon 
  @Input() settings: boolean = true;                        //Enables settings icon
  //
  // Presentation Settings (additional storybook)
  //
  @Input() default: boolean = true;                        // Standard Header Navigation to copy
  @Input() mobileOnly: boolean = false;                    // Mobile only presentation. 
  /**
   * npm run testOnce ran into security problems with onlyMobile. 
   * Looks like Angular reserves the prefix 'on' as their own click handlers and supresses others under the hood.
   * This causes the binds for mobileLayout to fail. changed the word to be mobileOnly.
   * https://stackoverflow.com/questions/57181653/binding-to-event-property-onlycurrentmonth-is-disallowed-for-security-reasons
   * https://github.com/CirclonGroup/angular-tree-component/issues/223
   * https://github.com/highcharts/highcharts-angular/issues/191
   * 
   */

  constructor(
    public dialog: MatDialog,
    private _authenticationService: AuthenticationService,
    private _contentful : ContentfulService,
    private _userPortalDialogService: UserPortalDialogService
  ) {  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
  unsubscribe() : void {
    this.streamNotify?.unsubscribe();
  }

  ngOnInit(): void {
    this.streamNotify = this._contentful.getTotalNotifications()
      .subscribe((value:number) => {
        if (value == (null || undefined )) { return; }
        if (value === 0) {
          // nothing returning.
          this.showNotify = false;
        }
        if (value > 0) {
          // something returning grab count and show icon.
          this.notifyCount = value;
          this.showNotify = true;
        }
      })
  } // End of ngOnInit.


  /**
   * Processes the number to be shown in the notification bell badge
   */
  get unreadNotifications(): string {
    if (this.notifyCount > 99) {
      return '99+';
    }
    return this.notifyCount.toString();
  }


  toggleHam(): void {
    this.isOpen = !this.isOpen;
    if ( this.isOpen) {
      this.openNav.emit(true);
    } else {
      this.openNav.emit(false);
    }
  }

  openNotify(): void {
    /**
   * This set's the Flag for ALl notifications and calls Notification again for up to date latest.
   * If using multiple places then would have to make those calls / read token here again.
   */
    this._contentful.passAllNotifications();
  }

  /**
   * Triggers the action to logout the user from the identity server
   */
  logout(): void {
    this._authenticationService.logout();
  }

  /**
   * Opens the Confirmation Dialog with the data a object that contains its content
   */
  openLogoutConfirmationDialog(): void {

    // Creates the Dialog and stores its reference
    const logoutConfirmationDialogRef = this._userPortalDialogService.userPortalDialog(
      'Are you sure you want to leave?',
      `
        <p style="padding: 0.57rem 0 0 0">
          You are logging out of all LCPtracker applications.
        </p>
      `,
      'Confirm',
      'Cancel'
    );

    // Listens to the dialog, so when it closes it does an action
    logoutConfirmationDialogRef.afterClosed().subscribe(value => {

      // If the value is truthy, the user confirmed the action
      if (value) {
        // Logs out
        this.logout();
      }
      // Else, the user canceled the action
    })
    
  }

}
