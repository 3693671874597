<section class="support-layout">
  <div class="w-full grid grid-cols-1 md:grid-cols-12 gap-2.28 my-2.28">
    <div class="sm:max-w-fit lg:col-span-6 col-span-12 lg:float-left">
      <ng-container *ngFor="let card of cards">        
        <app-info-card [data]="card"></app-info-card>
        <div class="mb-2.28"></div>
      </ng-container>
    </div>
    <div class="sm:max-w-fit col-span-12 lg:col-span-6 lg:float-right">
      <ng-container *ngFor="let question of questions; let last = last">
        <div class="mb-2.28" [ngClass]="{'mb-0': last}">
          <app-support-question-card [question]="question"></app-support-question-card>
        </div>
      </ng-container>
    </div>
  </div>
</section>
