import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import {
  PaginationState,
  PaginationVars,
  BUILD_SUPPORT_CONSOLE_QUERY,
  SupportConsoleQueryVars,
  UserData,
  UsersRequestResponse,
} from '../graphql/support-console.queries.graphql';
import {
  BoolResultModel,
  RESET_PASSWORD_USER_BY_USERNAME,
  ResetPasswordReturningEntity,
} from '../graphql/mutations/reset-password-user-by-username.query.graphql';
import {
  UNLOCK_USER_BY_USERNAME,
  UnlockUserReturningEntity,
} from '../graphql/mutations/unlock-user-by-username.query.graphql';
import { RECOVERY_CODE_USER_BY_ID, RecoveryCodeReturningEntity, StringResultModel } from '../graphql/mutations/recovery-code-user-by-id.query.graphql';
import { HttpClient } from '@angular/common/http';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../consts/consts';
import { ChangeLog } from '../interfaces/interfaces';

export const PAGE_CONTACTS_SIZE_INIT = 10;
@Injectable({
  providedIn: 'root',
})
export class UsersService {

  applyPolicy!: WatchQueryFetchPolicy;

  /*Pagination and Search*/

  //PaginationVars as query parameter.
  paginationVars: PaginationVars = INIT_PAGINATION_VARS;
  paginationVarsSubject = new BehaviorSubject<PaginationVars>(this.paginationVars);
  paginationVars$ = this.paginationVarsSubject.asObservable();
  //PaginationState for persisting page state. State holds values for populating paginationVars during navigation.
  paginationState: PaginationState = INIT_PAGINATION_STATE;
  paginationStateSubject = new BehaviorSubject<PaginationState>(this.paginationState);
  paginationState$ = this.paginationStateSubject.asObservable();

  lastPageSizeChange = new BehaviorSubject<number>(0);

  searchText: string = '';
  onSearchTextSubject = new BehaviorSubject<string>(this.searchText);
  onSearchText$ = this.onSearchTextSubject.asObservable();

  filterByCriteria: string | null = null;
  onFilterBySubject = new BehaviorSubject<string | null>(this.filterByCriteria);
  onFilterBy$ = this.onSearchTextSubject.asObservable();

  /*Loading States*/
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  isSavingSubject = new BehaviorSubject<boolean>(false);
  isSaving$ = this.isSavingSubject.asObservable();

  constructor(private _graphqlHostService: GraphqlHostService, private httpClient: HttpClient) {

    this.paginationVars$.subscribe((response): void => {
      this.paginationVars = response;
    });

    this.onSearchText$.subscribe((response): void => {
      this.searchText = response;
    });

    this.onFilterBy$.subscribe((response): void => {
      this.filterByCriteria = response;
    });
  }

  /**
 * Searches and filters the data based on the provided criteria.
 * @param searchCriteria The search criteria to filter the data.
 * @param filter The optional filter criteria to further refine the search.
 */
  searchAndFilterBy(searchCriteria: string, filter: string | null): void {
    this.onSearchTextSubject.next(searchCriteria);
    this.onFilterBySubject.next(filter);
  }


  getDataBasedOnVars(): SupportConsoleQueryVars {
    return {
      searchCriteria: this.searchText,
      first: this.paginationVars.first,
      after: this.paginationVars.after,
      last: this.paginationVars.last,
      before: this.paginationVars.before,
    };
  }


  updatePageState(requestResponse: UsersRequestResponse, paginationVars: any): PaginationState {
    let pageState: PaginationState;

    if (requestResponse.users.pageInfo.hasNextPage) {
      // If there is a next page, set the pageState accordingly
      pageState = {
        endCursor: requestResponse.users.pageInfo.endCursor,
        startCursor: requestResponse.users.pageInfo.startCursor,
        totalElements: requestResponse.users.totalCount,
        pageSize: paginationVars.first ? paginationVars.first : paginationVars.last,
      };
    } else {
      // If it's the last page, determine the pageSize based on the totalCount
      const pageSizeOnLastPage: number = paginationVars.first ? paginationVars.first : paginationVars.last;
      // If the totalCount is less than or equal to the pageSizeOnLastPage, use pageSizeOnLastPage
      // Otherwise, use the lastPageSizeChange value (most recently selected pageSize)
      pageState = {
        endCursor: null,
        startCursor: requestResponse.users.pageInfo.startCursor,
        totalElements: requestResponse.users.totalCount,
        pageSize: requestResponse.users.totalCount <= pageSizeOnLastPage ? pageSizeOnLastPage : this.lastPageSizeChange.value,
      };
    }

    return pageState;
  }


  /**
   * Builds the "where" clause based on the provided filter.
   * @param filterBy - The filter to determine the field to search.
   * @returns The constructed "where" clause as a string.
   */
  buildWhereClause(filterBy: string | null): string {
    let where: string = '';

    switch (filterBy) {
      case 'searchByUsername':
        // Construct the "where" clause for searching by username
        where = `userName: { startsWith: $searchCriteria }`;
        break;

      case 'searchByLastName':
        // Construct the "where" clause for searching by last name
        where = `lastName: { startsWith: $searchCriteria }`;
        break;

      case 'searchByFirstName':
        // Construct the "where" clause for searching by first name
        where = `firstName: { startsWith: $searchCriteria }`;
        break;

      case 'searchByOrganizationName':
        // Construct the "where" clause for searching by organization name
        where = `usersInOrg: { some: { org: { orgName: { startsWith: $searchCriteria } } } }`;
        break;

      case 'searchByEmail':
        // Construct the "where" clause for searching by organization name
        where = `email: { startsWith: $searchCriteria }`;
        break;

      case 'searchByPhoneNumber':
        // Construct the "where" clause for searching by organization name
        where = `phoneNumber: { startsWith: $searchCriteria }`;
        break;

      default:
        // Default to searching by username if no valid filter is provided
        where = `userName: { startsWith: $searchCriteria }`;
        break;
    }
    return where;
  }

  /**
   * Makes the HTTP request to fetch the list of users
   * @returns The list of paginated users
   */
  getUsers(policy: FetchPolicy): Promise<UserData> {
    this.isLoadingSubject.next(true);
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }

    const VARS: SupportConsoleQueryVars = this.getDataBasedOnVars();
    const WHERE_CLAUSE = this.buildWhereClause(this.onFilterBySubject.value);
    const QUERY = BUILD_SUPPORT_CONSOLE_QUERY(WHERE_CLAUSE);
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          QUERY,
          VARS,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: UsersRequestResponse) => {
          this.paginationStateSubject.next(this.updatePageState(response, VARS));
          this.isLoadingSubject.next(false);
          resolve(response.users);
        })
        .catch((error) => {
          this.isLoadingSubject.next(false);
          reject(error);
        });
    });
  }


  /**
   * Reset password for user by username using graphql mutations
   * @param userName
   */
  resetPassword(userName: string): Promise<BoolResultModel> {
    this.isLoadingSubject.next(true);
    const vars = {
      userName,
    };
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          RESET_PASSWORD_USER_BY_USERNAME,
          vars,
        )
        .then((response: ResetPasswordReturningEntity) => {
          this.isLoadingSubject.next(false);
          resolve(response.resetPassword);
        })
        .catch((error: string) => {
          this.isLoadingSubject.next(false);
          const gError = {
            origin: 'resetPassword',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Unlock user by username using graphql mutations
   * @param userName
   */
  unlockUser(userName: string): Promise<BoolResultModel> {
    this.isLoadingSubject.next(true);
    const vars = {
      userName,
    };
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          UNLOCK_USER_BY_USERNAME,
          vars,
        )
        .then((response: UnlockUserReturningEntity) => {
          this.isLoadingSubject.next(false);
          resolve(response.unlockUser);
        })
        .catch((error: string) => {
          this.isLoadingSubject.next(false);
          const gError = {
            origin: 'unlockUser',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Get recovery code user by user ID using graphql mutations
   * @param userId
   */
  getRecoveryCode(userId: string): Promise<StringResultModel> {
    this.isLoadingSubject.next(true);
    const vars = {
      userId,
    };
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          RECOVERY_CODE_USER_BY_ID,
          vars
        )
        .then((response: RecoveryCodeReturningEntity) => {
          this.isLoadingSubject.next(false);
          resolve(response.recoveryCode);
        })
        .catch((error: string) => {
          this.isLoadingSubject.next(false);
          const gError = {
            origin: 'getRecoveryCode',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Service call to fortify api to update user information
   * @param user 
   * @returns http response
   */
  updateUser(user: FormData) {
    const url = environment.applications.fortify.api + '/User/V02/json/UpdateUserData';
    return this.httpClient.post(url, user, {}).pipe(
      map(resp => resp)
    )
  }

  /**
   * Service call to fortify api to post a change log from a user update
   * @param changeLog
   * @returns http response
   */
  saveChangeLog(changeLog: ChangeLog) {
    const url = environment.applications.fortify.api + '/support/changelog';
    return this.httpClient.post(url, changeLog, {}).pipe(
      map(resp => resp)
    )
  }
  
  /**
   * Service call to fortify api to retrieve all sessions from a user
   * @param userName 
   * @returns http response
   */
  getUserSessions(userName: string) {
    const expireDate = new Date().toISOString();
    const url = environment.applications.fortify.api + `/support/UserSession?Filter=displayName eq '${userName}' and expires gt ${expireDate}`;
    return this.httpClient.get(url, {}).pipe(
      map(resp => resp)
    )
  }

  /**
   * Service call to delete sessions from a user
   * @param userId 
   * @returns http response
   */
  deleteActiveSessionsByUserId(userId: string) {
    const url = environment.applications.fortify.api + `/support/usermanager/V02/json/deletesessions/user/${userId}`;
    return this.httpClient.post(url, {}).pipe(
      map(resp => resp)
    )
  }
}
