import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfoCard } from 'src/app/shared/components/info-card/info-card';
import { SupportQuestion } from '../../components/support-question-card/support-question-card.interface';
import { SupportData } from '../../graphql/support-data.query';

@Component({
  selector: 'page-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportComponent implements OnInit {

  supportData!: SupportData;

  questions: SupportQuestion[] = [];

  cards: InfoCard[] = [];

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    const routeSupportSubscription = this._route.data.subscribe({
      next: (data) => {
        this.supportData = data['supportData'];
        if(this.supportData.trainingMaterialsCardCollection.items?.length){
          this.questions = this.supportData.trainingMaterialsCardCollection.items
        }
        if(this.supportData.infoCardCollection.items?.length){
          this.cards = this.supportData.infoCardCollection.items
        }
      },
      error: () => {
        routeSupportSubscription.unsubscribe();
      },
      complete: () => {
        routeSupportSubscription.unsubscribe();
      },
    });
  }
}
