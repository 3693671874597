<!-- How Can I Component Wrapper -->
<div *ngIf="linksHowCanI.length && linksHowCanI" class="how-can-i-wrapper">

  <!-- How Can I Links Wrapper -->
  <div class="how-can-i-wrapper-links">

    <!-- How Can I Title -->
    <h4 class="how-can-i-wrapper-links-title">How can I... ?</h4>

    <!-- How Can I Links -->
    <ul *ngFor="let column of linksResultByColumn " [@listAnimation]="showMore">

      <!-- How Can I Element -->
      <li *ngFor="let item of (showMore) ? column : column | slice:0:3" >

        <!-- How Can I Link -->
        <a href="{{ item?.link }}" target="{{ item?.target }}">
          {{ item?.label }}
        </a>

      </li>

    </ul>

  </div>

  <!-- How Can I Training Section -->
  <div class="how-can-i-wrapper-training">

    <!-- How Can I Training Section - Redirection Button -->
    <button 
      mat-flat-button
      *ngIf="hiddenButtonShowMore"
      (click)="showMore = !showMore"
      [ngClass]="{ 'py-4' : linksResultByColumn.length === 1  }"
    >
      {{showMore ? 'View less Training Resources' : 'View all Training Resources'}}
    </button>

  </div>

</div>