<mat-expansion-panel class="support-question-card"
  [expanded]="openExpandable"
  hideToggle
  >
    <mat-expansion-panel-header class="support-question-card-header" (click)="openPanel($event)"(keydown)="openPanel($event)">
        <mat-panel-title class="support-question-card-header-title">
            <button type="button" #expand id="btn-expand-more" class="mat-icon-btn" mat-icon-button disableRipple>
              <mat-icon>{{openExpandable ? 'expand_more' : 'chevron_right'}}</mat-icon>
            </button>
            <p class="support-question-card-header-title-content">
              {{question.title}}
            </p>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="support-question-card-body">
      <p>{{question.bodyMessage}}</p>
    </div>

    <mat-action-row *ngIf="question.assetFile || question.externalLink">
      <button mat-button color="primary"
      (click)="actionLink()"
      (keyup.enter)="actionLink()"
        >{{question.linkLabelName}}
      </button>
    </mat-action-row>

</mat-expansion-panel>