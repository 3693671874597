import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { UserIdleService } from 'src/app/core/user-idle/user-idle.service';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { MENU, MENU_ITEMS } from './menu';
import { UserPortalDialogService } from '../../services/dialog/user-portal-dialog.service';
import { LayoutStateService } from './services/layout-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit, OnDestroy {
  menu: MENU[] = MENU_ITEMS;

  htmlContent!: string;
  idleStart: boolean = false;
  isAuthenticatedState: boolean = false;
  isDesktop = new Observable();
  private unsusbcribe = new Subject<boolean>();

  constructor(
    private authenticationService: AuthenticationService,
    private userIdle: UserIdleService,
    private layoutStateService: LayoutStateService,
    private portalDialogService: UserPortalDialogService) {
  }

  ngOnInit(): void {
    this.isDesktop = this.layoutStateService.isDesktopLayout$.pipe(takeUntil(this.unsusbcribe));
    this.checkingUserActivity();
    this.checkUserIp();
  }

  /**
   * Checks if a user is IP whitlisted
   */
  checkUserIp(): void {
    this.authenticationService.isUserIpInTheWhilist().subscribe(
      isUserIpInTheWhilist => {
        if(environment.env !== 'prod'){
          console.log('WHITELIST: ', isUserIpInTheWhilist);
          console.log('CAN SUPPORT TOOLS: ', this.authenticationService.canUseSupportToolsSubject.value);
          console.log('VALID DOMAIN: ', this.authenticationService.validDomainEmail(this.authenticationService.userName.value));
        }

        if (isUserIpInTheWhilist &&
            this.authenticationService.canUseSupportToolsSubject.value &&
            this.authenticationService.validDomainEmail(this.authenticationService.userName.value)) {
          this.addITtoolsToTheMenu();
        }
      }
    )
  }

  /**
   * Adds IT tools to the side navigation bar
   */
  addITtoolsToTheMenu(): void {
    this.menu.push({
      label: 'Support Console',
      target: 'user-portal',
      route: `console`
    })
  }

  checkingUserActivity(): void {
    this.userIdle.startWatching();

    /**
    * Start watching when user idle is starting.
    */
    this.userIdle.onTimerStart().subscribe((count: number) => {
      if (!this.idleStart && this.authenticationService.isAuthenticated$.value) {
        this.idleStart = true;
        let countInSeconds = 90;
        const dialogRef = this.portalDialogService.userPortalDialog(
          'Session about to expire.',
          `<p>Your session is about to expire, please confirm that you are here.
            If not you will be automatically logged out in <span style="color: #000"><strong>90</strong></span> seconds.<p>
          `,
          'Yes, I am here',
          'Log Out'
        );

        interval(1000).subscribe(() => {
          countInSeconds--;
          if (dialogRef && dialogRef.componentInstance && countInSeconds >= 0) {
            dialogRef.componentInstance.htmlContent = `
            <p>Your session is about to expire, please confirm that you are here.
            If not you will be automatically logged out in <span style="color: #000"><strong>${countInSeconds}</strong></span> seconds.<p>
            `;
          }
        });

        dialogRef.componentInstance.htmlContent = this.htmlContent;
        dialogRef.afterClosed().subscribe((response: any) => {
          if (response) {
            this.userIdle.resetTimer();
            this.idleStart = false;
          }
          else {
            this.authenticationService.logout();
          }
        })
      }
    });

    /**
    * Start watch when time is up.
    */
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.authenticationService.logout();
    });
  }

  ngOnDestroy(): void {
    this.userIdle.stopWatching();
    this.unsusbcribe.complete();
    this.unsusbcribe.unsubscribe();
  }
}
