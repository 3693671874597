import { PaginationState, PaginationVars } from '../graphql/support-console.queries.graphql'

export const PAGINATION = {
  PAGE_SIZE: 5,
  ORDER_BY: 'UserName',
  MINIMUM_LENGTH_TO_SEARCH: 2,
  LOGIN_HISTORY_RECORDS: 5,
}

export const ORG_TYPES = {
  FEDERAL: 'FEDERAL',
  STATE: 'STATE',
  AGENCY: 'AGENCY',
  PRIME: 'PRIME',
  CONTRACTOR: 'CONTRACTOR',
  MILITARY: 'MILITARY',
  REHABILITATION: 'REHABILITATION',
  TRAINING: 'TRAINING',
}

export const INIT_PAGINATION_STATE: PaginationState = {
  endCursor: null,
  startCursor: null,
  totalElements: 0,
  currentPage: 0,
  pageSize: null,
}

export const INIT_PAGINATION_VARS: PaginationVars = {
  first: 5,
  after: null,
  last: null,
  before: null,
}

export const SEARCH_FILTER_OPTIONS =
  [
    { criteria: 'searchByUsername', label: 'Username' },
    { criteria: 'searchByFirstName', label: 'First Name' },
    { criteria: 'searchByLastName', label: 'Last Name' },
    { criteria: 'searchByEmail', label: 'Email' },
    { criteria: 'searchByPhoneNumber', label: 'Phone Number' },
    { criteria: 'searchByOrganizationName', label: 'Organization Name' },
  ];
