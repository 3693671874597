<!-- Header Component Wrapper -->
<div class="header-wrapper">

  <!-- Header Component - Desktop View Container -->
  <ng-container *ngIf="default">

    <!-- Header Container -->
    <header class="w-full absolute">


      <div class="header-mobile inline-block lg:hidden">
        
        <!-- Loading bar -->
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

        
        <div class="header-mobile-company p-2.28">
          
          <!-- Header Logo Container -->
          <div class="header-logo">

            <!-- Logo -->
            <div class="logo">

              <!-- Logo redirection route -->
              <a tabindex="0" title="Logo" [routerLink]="['/dashboard']">

                <!-- Logo Image -->
                <img alt="Lcptracker Logo" src="assets/images/logo_white.png" width="auto" height="auto"/>

              </a>

            </div>

          </div>

          <!-- Header Actions Container -->
          <div class="header-actions">


            <div id="nav-trigger">
              
              <!-- Notification Container -->
              <ng-container *ngIf="showNotify">

                <!-- Notification Button / Icon Container -->
                <a 
                  id="notify-bell"
                  tabindex="0" 
                  (click)="openNotify()"
                  (keyup.enter)="openNotify()"
                  [ngClass]="{ 'notify-bell header-notify' : true}"
                >
                  
                  <!-- Notifications Badge Implementation -->
                  <div class="badge badge-mobile">

                    <!-- Notification Icon - Bell -->
                    <mat-icon 
                      [matBadge]="unreadNotifications"
                      matBadgeColor="accent"
                      matBadgeSize="medium"
                    >
                      notification_important
                    </mat-icon>

                    <!-- Notification Alternative Text -->
                    <span class="cdk-visually-hidden">
                        You have {{unreadNotifications}} Notifications
                    </span>

                  </div>

                </a>

              </ng-container>

            </div>

            <!-- Hamburger Menu - Mobile Display Only -->
            <div [ngClass]="{'mobile-menu': true, 'close-nav': isOpen === true}">

              <!-- Hamburger Menu Icon -->
              <div class="icon" (click)="toggleHam()" (keydown)="toggleHam()">

                <!-- Hamburger Menu Icon Element -->
                <div class="bar b1"><span></span></div>

                <!-- Hamburger Menu Icon Element -->
                <div class="bar b2"><span></span></div>

                <!-- Hamburger Menu Icon Element -->
                <div class="bar b3"><span></span></div>

              </div>
              
            </div>

          </div>

        </div>

      </div>


      <div class="header-container hidden lg:flex lg:flex-col lg:justify-start">
        <div class="header-app-bar">
          
          <!-- Header Left Side Content -->
          <div class="header-desk-left">
            
            <!-- Logo Button -->
            <a [routerLink]="['/dashboard']" class="header-desk-logo">

              <!-- Logo Image -->
              <img alt="lcptracker-logo" [attr.src]="logo" class="desk-logo-image" width="auto" height="auto" />

            </a>
            <!-- Role dropdown -->
            <!-- <div class="header-role" *ngIf="hasApplication"> -->
              <!-- Account  -->
              <!-- <span class="label-indicator">Account:</span>
              <button mat-button type="button">
                {{applicationName}} <span class="material-icons md-14">arrow_drop_down</span>
              </button> -->
            <!-- </div> -->
          </div>

          <!-- Header Right Side Content -->
          <div class="header-desk-right">

            <!-- Notifications Button Container -->
            <div class="header-notify" *ngIf="showNotify">

              <!-- Notifications Button -->
              <button
                mat-icon-button
                (click)="openNotify()"
                (keyup.enter)="openNotify()"
              >

                <!-- Notifications Button Icon -->
                <mat-icon
                  class="notification-bell"
                  matBadgeColor="accent"
                  matBadgeOverlap="true"
                  matBadgeSize="medium"
                  [matBadge]="unreadNotifications"
                >
                  notification_important
                </mat-icon>

              </button>

            </div>

            <!-- Logout Button -->
            <app-button
              buttonType="header"
              label="Logout"
              prefixIcon="logout"
              (buttonClicked)="openLogoutConfirmationDialog()"
            >
            </app-button>

          </div>

        </div>

        <!-- Actions Bar -->
        <div class="header-user-bar">

          <!-- Update Additional Links -->
          <div *ngIf="additionalLinks" class="header-user-console"></div>

        </div>

      </div>

    </header>

  </ng-container>

  <!-- PRESENTATION ONLY - NOT FOR COPY / PASTE -->
  <!-- Header Component - Mobile View Container -->
  <ng-container *ngIf="mobileOnly">
    <header class="w-full m-auto">
      <div class="header-mobile">
        <mat-progress-bar *ngIf="loading === true" mode="indeterminate"></mat-progress-bar>
        <div class="header-mobile-company p-1.14">
            <div class="header-logo">
                <div class="logo">
                    <a tabindex="0" title="Logo" [routerLink]="['/home']">
                      <img alt="Lcptracker Logo" src="assets/images/logo_white.png" width="auto" height="auto" />
                    </a>
                </div>
            </div>
            <div class="header-actions">
              <div id="nav-trigger">
                <ng-container *ngIf="showNotify === true">
                  <a id="notify-bell" tabindex="0" (click)="openNotify()"
                      [ngClass]="{ 'notify-bell header-notify' : true}"
                      >
                      <div class="badge badge-mobile">
                        <mat-icon [matBadge]="unreadNotifications"
                                matBadgeColor="accent"
                                matBadgeSize="medium"
                                >notification_important
                        </mat-icon>
                        <span class="cdk-visually-hidden">
                            You have {{unreadNotifications}} Notifications
                        </span>
                      </div>
                  </a>
                </ng-container>
              </div>
              <div id="mobile-menu" [ngClass]="{'mobile-menu': true, 'close-nav': isOpen === true}">
                <div class="icon" (click)="toggleHam()" (keydown)="toggleHam()">
                    <div class="bar b1"><span></span></div>
                    <div class="bar b2"><span></span></div>
                    <div class="bar b3"><span></span></div>
                </div>
              </div>

            </div>
          </div>
      </div>
    </header>
  </ng-container>
</div>
