import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { INIT_PAGINATION_VARS, INIT_PAGINATION_STATE } from '../../../consts/consts';
import { Login, LoginHistoryEdge } from '../../../graphql/login-history.queries.graphql';
import { UsersEdge } from '../../../graphql/support-console.queries.graphql';
import { LoginHistoryService } from '../../../services/login-history.service';
import { LoginHistoryComponent } from '../../modals/login-history/login-history.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-login-history-detail',
  templateUrl: './login-history-detail.component.html',
  styleUrls: ['./login-history-detail.component.scss']
})
export class LoginHistoryDetailComponent implements OnInit {

  private _loginHistoryService = inject(LoginHistoryService);
  public _dialog = inject(MatDialog);
  private _breakpointObserver = inject(BreakpointObserver);

  isLoadingProgressBar = true;
  showFullAuthTypeBadge: boolean = false;
  @Input() user!: UsersEdge;
  loginHistoryGraphQL: LoginHistoryEdge[] = [];

  ngOnInit(): void {
    this.getLoginsPreview(this.user.node.id);
    this.loginHistoryAuthTypeBadgeStyle();
  }

  /**
 * Listens to changes in the screen width to change
 * the style of the AuthType Badge in Login History Preview
 */
  loginHistoryAuthTypeBadgeStyle(): void {
    this._breakpointObserver
      .observe(`(max-width: 1500px)`)
      .subscribe((result): void => {
        this.showFullAuthTypeBadge = result.matches;
      });
  }

  /**
   *
   * Calls the service to get a list of loginHistory based on the selected User's card
  */
  getLoginsPreview2(userId: string): void {
    this.loginHistoryGraphQL = [];
    this.isLoadingProgressBar = true;
    this._loginHistoryService.getLoginHistory('network-only', userId).then((res: Login) => {
      this.loginHistoryGraphQL = res.edges;
    }).catch(error => {
      console.error('Something went wrong for getLoginHistory: ', error);
    }).finally(() => {
      this.isLoadingProgressBar = false;
    });
  }

  /**
 * Calls the service to get a list of users
 */
  async getLoginsPreview(userId: string): Promise<void> {
    this.loginHistoryGraphQL = [];
    this.isLoadingProgressBar = true;
    try {
      const res = await this._loginHistoryService.getLoginHistory('network-only', userId);
      this.loginHistoryGraphQL = res.edges;
      this.isLoadingProgressBar = false;
    } catch (error) {
      console.error('Something went wrong for getUsers', error);
      this.isLoadingProgressBar = false;
    }
  }


  /**
 * Validates if authType contains the text 'FAILED' to define
 * the color of the badge and the icon
 * @param authType 
 * @returns 
 */
  validateAuthTypeBadgeColor(authType: string): boolean {
    if (authType.includes('FAIL')) {
      return true;
    } else {
      return false;
    }
  }

  /**
 * Validates the text in authType to define the text displayed in the badge
 * @param authType 
 * @returns 
 */
  validateAuthTypeMessage(authType: string): string {
    switch (authType) {
      case 'FAILED_PWD':
        return 'Failed';
      case 'FAILACODE':
        return 'Failed';
      case 'FAILED_OTP':
        return 'Failed';
      default:
        return 'Success';
    }
  }

  /**
* Open the login history modal
* @param firstName
* @param lastName
* @param email
* @param loginHistory
*/
  openLoginHistoryDialog(
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    userId: string | null,
  ): void {
    const _dialogRef = this._dialog.open(LoginHistoryComponent, {
      width: '600px',
      autoFocus: false,
      data: {
        name: `${firstName ? firstName : ' - '} ${lastName ? lastName : ' - '}`,
        email,
        userId
      },
    });

    _dialogRef.afterClosed().subscribe((result) => {
      this._loginHistoryService.paginationVarsSubject.next(INIT_PAGINATION_VARS);
      this._loginHistoryService.paginationStateSubject.next(INIT_PAGINATION_STATE);
      if (environment.env !== 'prod') {
        console.log(`_dialog result: ${result}`);
      }
    });
  }

}
