import { Component, OnInit, Input } from '@angular/core';
import { InformationCard, InfoCard, InfoContact } from './info-card';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit {

  @Input('data')
  infoCardData?: InformationCard;

  @Input('infoCard')
  infoCard?: InfoCard;

  constructor() { }

  ngOnInit(): void { }

  contactAction(type : InfoContact) {
    window.open(type.slug);
  }

  /**
   * Sets the Browser to accept the link as a new entity not a route.
   */
   actionLink() : void {
    window.open(this.infoCard?.linkSlug, this.infoCard?.target);
  }

}
