
import { Injectable, NgZone, Optional } from '@angular/core';

import {
    from,
    fromEvent,
    interval,
    merge,
    Observable,
    of,
    Subject,
    Subscription,
    timer
} from 'rxjs';
import {
    bufferTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import { UserIdleConfig } from './user-idle.config';

@Injectable({
    providedIn: 'root'
})
export class UserIdleService {
    ping$!: Observable<any>;

    /**
     * Events that can interrupts user's inactivity timer.
     */
    protected activityEvents$!: Observable<any>;

    protected timerStart$ = new Subject<boolean>();
    protected idleDetected$ = new Subject<boolean>();
    protected timeout$ = new Subject<boolean>();
    protected idle$!: Observable<any>;
    protected timer$!: Observable<any>;
    /**
     * Idle value in milliseconds.
     * Default equals to 10 minutes.
     */
    protected idleMillisec = 600 * 1000;
    /**
     * Idle buffer wait time milliseconds to collect user action
     * Default equals to 1 Sec.
     */
    protected idleSensitivityMillisec = 1000;
    /**
     * Timeout value in seconds.
     * Default equals to 5 minutes.
     */
    protected timeout = 300;
    /**
     * Ping value in milliseconds.
     * Default equals to 2 minutes.
     */
    protected pingMillisec = 120 * 1000;
    /**
     * Timeout status.
     */
    protected isTimeout!: boolean;
    /**
     * Timer of user's inactivity is in progress.
     */
    protected isInactivityTimer!: boolean;
    protected isIdleDetected!: boolean;

    protected idleSubscription!: Subscription;

    constructor(@Optional() config: UserIdleConfig, private _ngZone: NgZone) {
        if (config) {
            this.setConfig(config);
        }
    }

    /**
     * Start watching for user idle and setup timer and ping.
     */
    startWatching(): void {
        if (!this.activityEvents$) {
            this.activityEvents$ = merge(
                fromEvent(window, 'mousemove'),
                fromEvent(window, 'resize'),
                fromEvent(document, 'keydown')
            );
        }

        this.idle$ = from(this.activityEvents$);

        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }

        /**
         * If any of user events is not active for idle-seconds when start timer.
         */
        this.idleSubscription = this.idle$
            .pipe(
                bufferTime(this.idleSensitivityMillisec),
                filter(
                    arr => !arr.length && !this.isIdleDetected && !this.isInactivityTimer
                ),
                tap(() => {
                    this.isIdleDetected = true;
                    this.idleDetected$.next(true);
                }),
                switchMap(() =>
                    this._ngZone.runOutsideAngular(() =>
                        interval(1000).pipe(
                            takeUntil(
                                merge(
                                    this.activityEvents$,
                                    timer(this.idleMillisec).pipe(
                                        tap(() => {
                                            this.isInactivityTimer = true;
                                            this.timerStart$.next(true);
                                        })
                                    )
                                )
                            ),
                            finalize(() => {
                                this.isIdleDetected = false;
                                this.idleDetected$.next(false);
                            })
                        )
                    )
                )
            )
            .subscribe();

        this.setupTimer(this.timeout);
        this.setupPing(this.pingMillisec);
    }

    stopWatching(): void {
        this.stopTimer();
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    }

    stopTimer(): void {
        this.isInactivityTimer = false;
        this.timerStart$.next(false);
    }

    resetTimer(): void {
        this.stopTimer();
        this.isTimeout = false;
    }

    /**
     * Return observable for timer's countdown number that emits after idle.
     */
    onTimerStart(): Observable<number> {
        return this.timerStart$.pipe(
            distinctUntilChanged(),
            switchMap(start => (start ? this.timer$ : of(null)))
        );
    }

    /**
     * Return observable for idle status changed
     */
    onIdleStatusChanged(): Observable<boolean> {
        return this.idleDetected$.asObservable();
    }

    /**
     * Return observable for timeout is fired.
     */
    onTimeout(): Observable<boolean> {
        return this.timeout$.pipe(
            filter(timeout => !!timeout),
            tap(() => (this.isTimeout = true)),
            map(() => true)
        );
    }

    getConfigValue(): UserIdleConfig {
        return {
            idle: this.idleMillisec / 1000,
            idleSensitivity: this.idleSensitivityMillisec / 1000,
            timeout: this.timeout,
            ping: this.pingMillisec / 1000
        };
    }

    // /**
    //  * Set config values.
    //  * @param config
    //  */
    setConfigValues(config: UserIdleConfig): void {
        if (this.idleSubscription && !this.idleSubscription.closed) {
            return;
        }

        this.setConfig(config);
    }

    // /**
    // * Setup timer.
    // *
    // * Counts every seconds and return n+1 and fire timeout for last count.
    // * @param timeout Timeout in seconds.
    // */
    protected setupTimer(timeout: number): void {
        this._ngZone.runOutsideAngular(() => {
            this.timer$ = of(() => new Date()).pipe(
                map(fn => fn()),
                switchMap(startDate => interval(1000).pipe(
                    map(() => Math.round((new Date().valueOf() - startDate.valueOf()) / 1000)),  //   convert elapsed count to seconds
                    tap((elapsed: number) => {
                        if (elapsed >= timeout) {
                            this.timeout$.next(true);
                        }
                    }),
                )
                ));
        });
    }

    //    /**
    //    * Setup ping.
    //    *
    //    * Pings every ping-seconds only if is not timeout.
    //    * @param pingMillisec
    //    */
    protected setupPing(pingMillisec: number): void {
        this.ping$ = interval(pingMillisec).pipe(filter(() => !this.isTimeout));
    }

    private setConfig(config: UserIdleConfig): void {
        if (config.idle) {
            this.idleMillisec = config.idle * 1000;
        }
        if (config.ping) {
            this.pingMillisec = config.ping * 1000;
        }
        if (config.idleSensitivity) {
            this.idleSensitivityMillisec = config.idleSensitivity * 1000;
        }
        if (config.timeout) {
            this.timeout = config.timeout;
        }
    }

    /**
     * Set custom activity events
     *
     * @param customEvents Example: merge(
     *   fromEvent(window, 'mousemove'),
     *   fromEvent(window, 'resize'),
     *   fromEvent(document, 'keydown'),
     *   fromEvent(document, 'touchstart'),
     *   fromEvent(document, 'touchend')
     * )
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    setCustomActivityEvents(customEvents: Observable<any>): void {
        if (this.idleSubscription && !this.idleSubscription.closed) {
            return;
        }

        this.activityEvents$ = customEvents;
    }

}
