import { Component, Input } from '@angular/core';
import { ORG_TYPES } from '../../../consts/consts';
import { UsersEdge, UserInOrganization } from '../../../graphql/support-console.queries.graphql';
@Component({
  selector: 'app-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss']
})
export class OrganizationDetailComponent {

  @Input() user!: UsersEdge;
  ORG_TYPES = ORG_TYPES;

  /**
   * Filter the org type by Agency
   * @param message
   */
  hasAccountAgency(orgs: UserInOrganization[]): boolean {
    const agencies = orgs.filter((response: any) => response.org.orgType === 'AGENCY');
    return agencies.length > 0 ? true : false;
  }

    /**
   * Filter the org type by Contractor
   * @param message
   */
    hasAccountContractor(orgs: UserInOrganization[]): boolean {
      const contractors = orgs.filter((response: any) => response.org.orgType === 'CONTRACTOR');
      return contractors.length > 0 ? true : false;
    }

}
