import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { DashboardContentfulPage, GET_DASHBOARD_PAGE } from './graphql/dashboard-data.query';
import { environment } from 'src/environments/environment';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { GET_APP_TILES, GET_PROFILE_INFO, HomePageLinks, HomePageLinksResponse, UserNode, UserProfileResponse } from './graphql/profile-info.query';
import { AuthenticationService } from '../authentication/services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  applyPolicy!: WatchQueryFetchPolicy;
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private graphqlHost: GraphqlHostService,
    private authenticationService: AuthenticationService,
  ) { }

  /**
   *
   * @param policy
   * @returns The dashboard page data pulled from Contentful Graphql API
   */

  fetchDashboardPage(policy: FetchPolicy): Promise<DashboardContentfulPage> {
    this.isLoadingSubject.next(true);
    this.applyPolicy = 'cache-first';

    if (policy) {
      this.applyPolicy = policy;
    }

    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_DASHBOARD_PAGE,
        null,
        optionalContext,
        this.applyPolicy,
      ).then((response: DashboardContentfulPage) => {
        resolve(response);
        this.isLoadingSubject.next(false);
      }).catch((error: any) => {
        reject(error);
      })
    })
  }

  fetchProfileCardInfo(policy: FetchPolicy): Promise<UserNode> {
    this.isLoadingSubject.next(true);
    this.applyPolicy = 'cache-first';

    if (policy) {
      this.applyPolicy = policy;
    }

    const optionalContext = null;

    const vars = { id: this.authenticationService.userId.value };

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_PROFILE_INFO,
        vars,
        optionalContext,
        this.applyPolicy,
      ).then((response: UserProfileResponse) => {
        this.authenticationService.userName.next(response.userProfile.userNodes[0].userName || '')
        resolve(response.userProfile.userNodes[0]);
        this.isLoadingSubject.next(false);
      }).catch((error: any) => {
        reject(error);
      })
    })
  }

  fetchAppTiles(policy: FetchPolicy): Promise<HomePageLinks> {
    this.isLoadingSubject.next(true);
    this.applyPolicy = 'cache-first';

    if (policy) {
      this.applyPolicy = policy;
    }

    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_APP_TILES,
        null,
        optionalContext,
        this.applyPolicy,
      ).then((response: HomePageLinksResponse) => {
        resolve(response.homePageLinks);
        this.isLoadingSubject.next(false);
      }).catch((error: any) => {
        reject(error);
      })
    })
  }

}