import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheet } from './bottom-sheet-interface';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit {

  @Input() confirmationButton = 'Accept';
  @Input() cancelButton = 'Cancel';
  @Output() closeComponent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: BottomSheet,
    public activeBottomSheet: MatBottomSheetRef<BottomSheetComponent>,
  ) { }

  ngOnInit(): void {
  }

  /**
   * 
   * @param value 
   * When is clicked on, this sends the confirmation
   * and complete the function the this has 
   * assigned
   */
  onConfirm(value: boolean): void {
    this.activeBottomSheet.dismiss(value);
  }

  /**
   * Close the bottom sheet using the closeComponent event in
   * confirmation component.
   */
  emitCloseComponentEvent() {
    this.closeComponent.emit(true);
  }
}
