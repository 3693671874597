import { environment } from 'src/environments/environment';
import { LogLevel } from 'angular-auth-oidc-client';
import { OpenIdConfiguration } from './auth-config';

export const configUserPortSpa : OpenIdConfiguration = {  // intentionally referenced for documentation.
    authority: environment.authentication.issuer,
    clientId: 'UserPortSpa',
    forbiddenRoute: '/not-authorized',
    logLevel: LogLevel.Debug,
    postLogoutRedirectUri: environment.authentication.postLogoutRedirectUri,
    redirectUrl: environment.authentication.redirectUrl,
    responseType: 'code',
    renewTimeBeforeTokenExpiresInSeconds: 30,
    scope: environment.authentication.scope, // 'openid profile offline_access ' + your scopes
    silentRenew: true,
    silentRenewUrl: environment.authentication.silentRenewUrl,
    silentRenewTimeoutInSeconds: 30,
    tokenRefreshInSeconds: 30,
    triggerAuthorizationResultEvent: true,
    triggerRefreshWhenIdTokenExpired: true,
    unauthorizedRoute: '/login',
    useRefreshToken: true,
}