import { gql } from "apollo-angular";

export const GET_FOOTER_COMPONENT = gql`
query getFooterComponent {
  footerCollection(order: label_ASC) {
    items {
      label
      type
      categoryIndex
      link
      target
      belongsTo
    }
  }
}
`;

export const GET_INFO_CARD_COMPONENT = gql`
query getCardComponent {
  infoCardCollection(where: {sectionId: "Global"}) {
    items {
      title
      hours
      phone
      email
      description
      closingStatement
      iconOne
      iconTwo
      sectionId
    }
  }
}
`;