<!-- Dashboard Profile Card Component Wrapper -->
<div class="dashboard-profile-card-wrapper">

  <!-- Dashboard Profile Card Container -->
  <mat-card *ngIf="profile" class="profile-card-container">

    <!-- Dashboard Profile Card Header -->
    <mat-card-header>
      <!-- <img src="assets/images/placeholder_profile.jpg" alt="" mat-card-avatar /> -->

      <!-- Dashboard Profile Card Header - Title Container -->
      <mat-card-title>

        <!-- Dashboard Profile Card Header - Title -->
        <h5 class="profile-name">{{profile.firstName ? profile.firstName : ''}} {{profile.lastName ? profile.lastName : ''}}</h5>

        <!-- Waiting for card redesign -->
        <!-- <h5 *ngIf="profile.usersInOrg.length > 0" class="profile-company" >{{profile.usersInOrg[0].org.orgName ? profile.usersInOrg[0].org.orgName : 'Company' }}</h5>
        <h5 *ngIf="profile.usersInOrg.length === 0" class="profile-company">
          Company
        </h5> -->

      </mat-card-title>

    </mat-card-header>

    <!-- Dashboard Profile Card Content -->
    <mat-card-content>

      <!-- Dashboard Profile Card Main Content Container -->
      <ul>

        <!-- Dashboard Profile Card - User Email -->
        <li class="email">Username: <span class="user-email">{{profile.userName}}</span></li>

        <!-- Dashboard Profile Card - User Email -->
        <li class="email">Contact Email: <span  class="user-email">{{profile.email}}</span></li>

        <!-- Dashboard Profile Card - 2FA Indicator -->
        <li class="two-factor-auth">2FA is turned {{profile.twoFactorEnabled ? 'ON': 'OFF'}}</li>

      </ul>

      <!-- Dashboard Profile Card Links Container -->
      <div class="profile-links">

        <!-- Dashboard Profile Card - Edit Profile in Fortify Link -->
        <a id="edit-profile-dashboard" [href]="profileFortifyUrl" target="_blank" rel="noopener noreferrer">Edit Profile</a>

      </div>

    </mat-card-content>

  </mat-card>

</div>
