import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';
import { StringResultModel } from '../../../graphql/mutations/recovery-code-user-by-id.query.graphql';
import { BoolResultModel } from '../../../graphql/mutations/reset-password-user-by-username.query.graphql';
import { UsersEdge } from '../../../graphql/support-console.queries.graphql';
import { UsersService } from '../../../services/users.service';
import { UsersComponent } from '../../users/users.component';
@Component({
  selector: 'app-actions-detail',
  templateUrl: './actions-detail.component.html',
  styleUrls: ['./actions-detail.component.scss']
})
export class ActionsDetailComponent implements OnInit {
  private _usersService = inject(UsersService);
  private _portalDialogService = inject(UserPortalDialogService);
  private _usersComponent = inject(UsersComponent);

  @Input() user!: UsersEdge;
  @Output() expandPanel: EventEmitter<any> = new EventEmitter<any>();

  sessionsButtonEnabled: boolean = false;

  constructor( ) { }

  ngOnInit(): void {
  }

  lockedEnd(lockedEnd: any): boolean {
    const now = new Date();
    const apiDate = new Date(lockedEnd);
    return apiDate > now;
  }

  /**
   * Unlock user request method
   * @param userId
   * @param userName
   */
  unlockUser(userName: string, userId: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Unlock User',
      `<p> You are about to unlock <strong> ${userName} </strong> account, do you want to continue?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe((response) => {
      if (!response) {
        return;
      }

      if (!userName) {
        return;
      }

      this.expandPanel.emit({ state: true, expandPanelAfterRequest: userId });
      this._usersService.unlockUser(userName).then(() => {
        this._portalDialogService.openUserPortalSnackBar('User account unlocked successfully');
        this._usersComponent.getUsers();
      }).catch(error => {
        this._portalDialogService.openCustomUserPortalSnackBar(`<p>Error in unlock user</p>`,'OK');
      });
    })
  }
  
  /**
   * Recovery code request method
   * @param userId
   * @param userName
   * @param panelId
   */
  getRecoveryCode(userId: string, panelId: string, username: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Recovery code',
      `<p> Are you requesting a recovery code for <strong> ${username} </strong>?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (userId) {
          this._usersService
            .getRecoveryCode(userId)
            .then((recoveryCoderesponse: StringResultModel): void => {
              if (recoveryCoderesponse.data?.includes('No recovery codes available')) {
                this._portalDialogService.openCustomUserPortalSnackBar(
                  `<p>${recoveryCoderesponse.data}</p>`,
                  'OK');
              } else {
                this._portalDialogService.userPortalDialog('Success', `<p> You have retrieved the user code: <strong> ${recoveryCoderesponse.data} </strong>. 
                Be sure to save this code before closing this window.</p>`,
                  'Close',
                  null)
              }
            })
            .catch((_error: string) => {
              console.log(_error);
              this._portalDialogService.openCustomUserPortalSnackBar(
                `<p>Error in recovery code for user</p>`,
                'OK');
            });
        }
      }
    })
  }

  /**
   * Reset password request method
   * @param userName
   * @param panelId
   */
  resetPassword(userName: string, panelId: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Reset Password',
      `<p> Are you requesting a password reset link for <strong> ${userName} </strong>?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (userName) {
          this._usersService
            .resetPassword(userName)
            .then((resetResponse: BoolResultModel): void => {
              this._portalDialogService.openCustomUserPortalSnackBar(
                `<p>Link to reset password successfully sent via email<p>`,
                'OK');
            })
            .catch((_error: string) => {
              console.error(_error);
              this._portalDialogService.openCustomUserPortalSnackBar(
                `<p>Error in reset password for user</p>`,
                'OK');
            });
        }
      }
    })
  }

  closeSessions(userId: string, userName: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Close Sessions',
      `<p> You are about to close all sessions of <strong> ${userName} </strong> account, do you want to continue?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (userId) {
          this._usersService.deleteActiveSessionsByUserId(userId).subscribe({
            next: () => {
              this.expandPanel.emit({ state: true, expandPanelAfterRequest: userId });
              this._portalDialogService.openUserPortalSnackBar('The sessions have been closed');
            },
            error: (_error) => {
              console.log(_error);
              this._portalDialogService.openCustomUserPortalSnackBar(`<p>Error closing sessions for the user selected</p>`,'OK');
            }
          });
        }
      }
    })
  }
}
