import { gql } from 'apollo-angular';

export const RECOVERY_CODE_USER_BY_ID = gql`
  mutation recoveryCodeUserById($userId: UUID!) {
    recoveryCode(userId: $userId) {
      id
      data
      status
      message
      unhandledException
    }
  }
`;

export interface StringResultModel {
  id: string | null;
  message: string | null;
  status: number | null;
  unhandledException: string | null;
  data: string | null;
}

export interface RecoveryCodeReturningEntity {
  recoveryCode: StringResultModel;
}
