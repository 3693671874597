import { Component, Input, OnInit } from '@angular/core';
import { UserNode } from '../../graphql/profile-info.query';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {

  @Input()
  profile!: UserNode | null;
  profileFortifyUrl:string = environment.onboardingClaims.lastProfileChange;

  constructor() { }

  ngOnInit(): void { }
}
