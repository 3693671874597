import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { environment } from 'src/environments/environment';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    private accessToken = '' ;
    constructor(private oidcSecurityService: OidcSecurityService,
        private authenticacionService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedRequest = request.clone();
        if (this.authenticacionService.isAuthenticated$) {
            this.oidcSecurityService.getAccessToken().subscribe({
                next: accessToken => {
                    this.accessToken = accessToken;
                    if (modifiedRequest.url.includes(environment.graphql.rbs) || modifiedRequest.url.includes(environment.applications.fortify.api)) {
                        modifiedRequest = request.clone({
                            headers: request.headers.set('authorization', 'bearer ' + this.accessToken)
                        });
                    }
                },
                error(err) {
                    console.error('something wrong occurred while retrieving data: ' + err);
                  },
            });
        }
        return next.handle(modifiedRequest);
    }
}