<div class="landing-container">

  <!-- header -->

  <header>
    <div class="header-container">
      <img [src]=logo alt="LCPtracker Logo" height="auto" width="auto">
      <div *ngIf="headerLinkList" class="header-links-set">
        <ng-container *ngFor="let headerLink of headerLinkList">
          <a class="header-links-set-link" [href]="headerLink.url" target="_blank">{{headerLink.label}}</a>
        </ng-container>
      </div>
    </div>
  </header>

  <div class="hero-section xxl:max-w-screen-xxl">
    <div class="hero-content">
      <div class="headline-container">
        <h1 class="headline">Empowering <strong>people</strong> to build <strong>better communities</strong> </h1>
      </div>
      <p class="tagline">{{tagline}}</p>
        <button id="login-button-home"class="flex justify-center" mat-raised-button color="primary"
          (click)="openUsernameCredentialComponent()">
          <strong>LOGIN</strong>
        </button>
    </div>
    <div class="apps-grid">
      <div *ngIf="productList" class="grid grid-cols-3 md:gap-2.28">
        <ng-container *ngFor="let product of productList">
          <div class="product-tile">
            <img [src]="product.logo" alt="{{product.productName}} Logo" height="auto" width="auto"/>
            <div class="product-tile-title">
              <p class="h6">{{product.productName}}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Footer -->

  <footer>
    <div class="footer-span">
      <span>{{lcpCopyrightLine}}</span>
    </div>
    <div *ngIf="footerLinkList" class="footer-links-set">
      <ng-container *ngFor="let footerLink of footerLinkList">
        <a class="footer-links-set-link" [href]="footerLink.url" target="_blank" rel="noopener noreferrer">{{footerLink.label}}</a>
      </ng-container>
    </div>
  </footer>
</div>