import { NgModule } from "@angular/core";
import { AuthModule } from "angular-auth-oidc-client";
import { configUserPortSpa } from "../services/auth/authenticate.config";

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: configUserPortSpa
    }),
  ],
  exports: [AuthModule],
})
export class OidcConfigModule {}
