import { MaterialsModule } from '../../core/materialIo.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './pages/contact/contact.component';
import { ContactRoutingModule } from './contact.routing';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ContactComponent,
    AboutUsComponent,
  ],
  imports: [
    CommonModule,
    ContactRoutingModule,
    MaterialsModule,
    SharedModule,
  ],
  exports: [
    AboutUsComponent
  ]
})
export class ContactModule { }
