import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @Input() id = '';
  @Input() title = 'Title';
  @Input() confirmButton = 'Accept';
  @Input() cancelButton = 'Cancel';
  htmlContent!: string;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      title: string;
      body: string;
      confirmButton: string;
      cancelButton: string;
    },
    public activeDialog: MatDialogRef<DialogComponent>,
  ) { }

  ngOnInit(): void {
    this.id = this.data.id ? this.data.id : '';
    this.title = this.data.title;
    this.htmlContent = this.data.body;
    this.confirmButton = this.data.confirmButton;
    this.cancelButton = this.data.cancelButton;
  }

  onConfirm(value: boolean): void {
    this.activeDialog.close(value);
  }

}
