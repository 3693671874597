import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { LoginSSOComponent } from './pages/login-sso/login-sso.component';

const routes: Routes = [
  {
    path: 'success',
    component: LoginCallbackComponent,
    title: 'Login - User Portal - LCPtracker'
  },
  {
    path: 'sso',
    component: LoginSSOComponent,
    title: 'Login - User Portal - LCPtracker'
  },
  {
    path: 'sso/:username',
    component: LoginSSOComponent,
    title: 'Login - User Portal - LCPtracker',
    pathMatch: 'full',
  },
  {
    path: 'user-redirected-from-v1/:username',
    component: LoginSSOComponent,
    title: 'Login - User Portal - LCPtracker',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
