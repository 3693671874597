import { Component, Input, OnInit } from '@angular/core';
import { ApplicationService, OrgInService, UserInOrganization } from '../../../graphql/support-console.queries.graphql';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscriptions-detail',
  templateUrl: './subscriptions-detail.component.html',
  styleUrls: ['./subscriptions-detail.component.scss']
})
export class SubscriptionsDetailComponent implements OnInit {

  @Input() subscriptions!: UserInOrganization[];
  // List of apps that the user can access
  userAppsSubscriptions: ApplicationService[] = [];

  ngOnInit(): void {
    this.filterRepeatedSubscriptions(this.subscriptions);
  }

   /**
   * Creates an array of the Application Services the user has access to based
   * on the Organizations he is part of
   *
   * @param organizations
   * @returns
   */
   filterRepeatedSubscriptions(organizations: UserInOrganization[]): void {
    // Reset Array of App Subscriptions
    this.userAppsSubscriptions = []

    // Stores the Services of each Organization in
    let appServicesInOrg: OrgInService[][] = [];

    // Iterate on every organization that the user exists on
    organizations.forEach((organization) => {

      // Stores the list of Application Services that the
      // Organization has access to
      appServicesInOrg.push(organization.org.orgsInServices);

    });

    // Turns Matrix into array
    const orgsInServicesFlatted = appServicesInOrg.flat();
    const appServices = orgsInServicesFlatted.map((orgInService) => orgInService.service);

    // Removes duplicated App Services
    appServices.forEach((appService: ApplicationService) => {

      // Checks if apps subscription is duplicated
      if (!this.userAppsSubscriptions.includes(appService)) {

        // If not, the app is stored in the array
        this.userAppsSubscriptions.push(appService);
      }

    });

  }

  /**
 * Gets in which environment is User Portal running
 */
  get runningEnvironment(): string {
    return environment.env;
  }

  displayAppIconFor(appServiceName: string): string {
    switch (appServiceName) {
      case 'LCPCertified':
        return 'assets/images/icon_LCPcertified.svg'

      case 'LCPTracker Professional':
        return './assets/images/icon_LCPtrackerPro.svg'

      case 'LCPt-Pro Staff Admin':
        return './assets/images/icon_LCPtrackerPro.svg'

      case 'Workforce Manager':
        return './assets/images/icon_WorkforceManager.svg'

      case 'Active Insights':
        return './assets/images/icon_ActiveInsights.svg'

      case 'Case Manager':
        return './assets/images/icon_CaseManager.svg'

      default:
        return './assets/images/placeholder_product.png'
    }
  }

}
