import { gql } from "apollo-angular";


export const SUPPORT_CONSOLE_CHANGE_LOGS = gql`
query getChangeLogs(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $dateAt: DateTime
) {
  users(
    first: $first
    after: $after
    last: $last
    before: $before
    where: { dateUpdated: { lte: $dateAt } }
    order: { dateUpdated: DESC }
  ) {
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
      __typename
    }
    edges {
      node {
        id
        firstName
        lastName
        email
        userName
        dateUpdated
        __typename
      }
      cursor
      __typename
    }
    totalCount
    __typename
  }
}`;

export const SUPPORT_CONSOLE_CHANGE_LOGS_TEST = gql`
query getChangeLogs(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $userId: UUID
  $dateAt: DateTime
) {
  editLogs (
    first: $first
    after: $after
    last: $last
    before: $before
    where: {userId:{eq:$userId} , changeDate:{ lte: $dateAt }}
    order: { changeDate: DESC }
  ) {
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
      __typename
    }
    edges {
      node {
        id
        rowNumber
        userId
        fieldsChanged
        changedById
        changeDate
        __typename
      }
      cursor
      __typename
    }
    totalCount
    __typename
  }
}`;


export const SUPPORT_CONSOLE_CHANGE_LOGS_BY_USERNAME = gql`
query getChangeNameByID ($userNameByIds:[String!]!) {
  usersByIds(ids: $userNameByIds){
    id
    userName
    firstName
    lastName
  }
}`;


export interface ChangeLogQueryVars {
  dateAt?: string | null,
  first: number | null,
  after: string | null,
  last: number | null,
  before: string | null,
  userId?:string,
}

export interface PageInfo {
  startCursor: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  endCursor: string;
  __typename: string;
}

export interface Node {
  id: string;
  rowNumber: number;
  userId: string;
  fieldsChanged: string;
  changedById: string;
  changeDate: string;
  username?:string;
  __typename: string;
}

export interface EditLogsEdge {
  node: Node;
  cursor: string;
  __typename: string;
}

export interface ChangeLogs {
  pageInfo: PageInfo;
  edges: EditLogsEdge[];
  totalCount: number;
  __typename: string;
}

export interface ChangeLogResponse {
  editLogs: ChangeLogs;
}

export interface UserEditedData {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
}

export interface UserIdsResponse {
  usersByIds: UserEditedData[];
}

export interface UserNameByIds {
  userNameByIds: string[];
}
