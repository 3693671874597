import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupportComponent } from './pages/support/support.component';
import { SupportContentResolver } from './resolver/support-content.resolver';

const routes: Routes = [
  {
    path: '',
    component: SupportComponent,
    resolve:{
      supportData: SupportContentResolver
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupportRoutingModule { }
