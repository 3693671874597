import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialsModule } from '../../core/materialIo.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SupportRoutingModule } from './support.routing';

import { SupportQuestionCardComponent } from './components/support-question-card/support-question-card.component';
import { SupportComponent } from './pages/support/support.component';
import { SearchSupportTopicCardComponent } from './components/search-support-topic-card/search-support-topic-card.component';


@NgModule({
  declarations: [
    SupportComponent, 
    SupportQuestionCardComponent, 
    SearchSupportTopicCardComponent,
  ],
  imports: [
    CommonModule,
    SupportRoutingModule,
    MaterialsModule,
    SharedModule
  ],
  exports: [
    SupportQuestionCardComponent,
    SearchSupportTopicCardComponent,
  ]
})
export class SupportModule { }
