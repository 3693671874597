<!-- Dashboard Component Container -->
<section class="home-dashboard-container">

  <!-- Dashboard Body Content and Profile Card Container -->
  <div class="home-dashboard-body">

    <!-- Dashboard Body Content Container -->
    <div class="body-content grid-cols-1">

      <!-- Body Title -->
      <h3 class="p-0 py-2 m-0 home-dashboard-title">Your Dashboard</h3>

      <!-- Profile Card Component -->

      <ng-container *ngIf="(isDesktop | async)">

        <!-- Profile Card Component -->
        <app-profile-card class="profile-card" [profile]="profileCard"></app-profile-card>
  
      </ng-container>

      <!-- Slideshow -->
      <app-slide-show *ngIf="slidesData" [slides]="slidesData"></app-slide-show>
      
      <!-- App Tiles Container -->
      <div *ngIf="appTiles" class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-3 lcp-tracker-card">

        <!-- App Tiles -->
        <ng-container *ngFor="let product of appTiles.tilesNodes">

          <!-- App Tile Component -->
          <ng-container *ngIf="product.uri && product.name">
            <app-card [product]="product"></app-card>
          </ng-container>

        </ng-container>

      </div>

      <!-- Marketing Advertisement Container -->
      <div *ngIf="marketingBannerData" class="marketing-advertisement-container">

        <!-- Marketing Advertisement Component -->
        <app-marketing-advertisement [advertisement]="marketingBannerData"></app-marketing-advertisement>

      </div>
      
      <!-- How Can I Component -->
      <app-how-can-i *ngIf="howCanIData" [linksHowCanI]="howCanIData"></app-how-can-i>

    </div>

  </div>

</section>

