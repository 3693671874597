import { LoginResponse } from "angular-auth-oidc-client/lib/login/login-response";
import { AuthenticationService } from "./features/authentication/services/authentication.service";
import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "User Portal";
  constructor(
    private authenticationService: AuthenticationService
    ) {
    this.authenticationService.checkAuth()
    .subscribe((loginResponse: LoginResponse) => {
      const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
      console.log(loginResponse);
      /*...*/
    });    
    this.authenticationService.checkSessionEvent();
    this.authenticationService.checkIsAuthenticated();
    this.authenticationService.getUserData();
    
  }
  
  ngOnInit() {
    
  }
}
