import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../../components/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class UserPortalDialogService {
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  /**
  * Opens a generic dialog with expanded dialog controls.
  * @param message
  */
  expandedDialog(
    id: string,
    width: string | null,
    height: string | null,
    title: string,
    body: string | any,
    confirmButton: string | null,
    cancelButton: string | null,
    disableClose: boolean,
    panelClass: string | null
  ): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      id: id,
      width: width? width : '430px',
      height: height? height : 'auto',
      autoFocus: true,
      restoreFocus: true,
      panelClass: panelClass? panelClass : 'custom-mat-dialog-container',
      disableClose: disableClose,
      data: {
        id,
        title,
        body,
        confirmButton,
        cancelButton
      },
    });
  }



  userPortalDialog(
    title: string,
    body: string | any,
    confirmButton: string | null,
    cancelButton: string | null
  ): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      width: '430px',
      height: 'auto',
      autoFocus: false,
      panelClass: 'custom-mat-dialog-container',
      disableClose: true,
      data: {
        title,
        body,
        confirmButton,
        cancelButton
      },
    });
  }

  /**
  * Opens the generic snackBar with the specified message
  * @param message
  */
  openUserPortalSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
    });
  }

  /**
  * Opens a custom snackBar with the specified message
  * @param message
  */
  openCustomUserPortalSnackBar(message: string, action: string): void {
    const snackbarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 6000,
    });
    snackbarRef.instance.message = message;
    snackbarRef.instance.action = action;
  }
}