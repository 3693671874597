<ng-container *ngIf="data">
  <div *ngFor="let item of data" class="about-us-container">
    <h4 class="about-us-title">About us</h4>
    <p class="about-us-description">
      {{this.item.description}}
    </p>

    <div class="about-us-body grid" [ngClass]="gridValue">
      <div *ngFor="let image of item.images.items; let i = index" class="sm:col-span-1 md:col-span-1 about-us-office">
        <img class="img-office" src="{{image.url}}"  alt="Office in {{item.officeLocation[i]}}" height="auto" width="auto" />
        <h5>{{item.officeLocation[i]}}</h5>
        <ul>
          <li><a href="mailto:{{item.email[i]}}"><mat-icon class="icon-size">mail</mat-icon>Email us</a></li>
          <li><a href="tel:{{item.phoneNumber[i]}}"><mat-icon class="icon-size">support_agent</mat-icon>Call us</a></li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
