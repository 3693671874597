import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LinkList } from "../../interfaces/hyperlink-list.interface";
import { ProductList } from "../../interfaces/product-list.interface";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { LoginBottomSheetWrapperComponent } from "./../../components/login-bottom-sheet-wrapper.component";
import { LoginDialogWrapperComponent } from "../../components/login-dialog-wrapper.component";
import { DatePipe } from "@angular/common";
import {
  OidcSecurityService,
} from "angular-auth-oidc-client";
import { AuthenticationService } from "../../services/authentication.service";
import { CustomSnackbarComponent } from "src/app/shared/components/custom-snackbar/custom-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HeaderLinks, FooterLinks, ProductLinks,  } from "./landing-consts";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  logo: string = "assets/images/lcptracker-full-color.svg";

  tagline: string = `
  Cloud-based certified payroll, labor compliance, and workforce management solutions that provide insight on the transformative impact of your projects on our communities, and more importantly, help workers create a path to success.
  `;

  headerLinkList: LinkList[] | null = HeaderLinks;
  footerLinkList: LinkList[] | null = FooterLinks;
  productList: ProductList[] | null = ProductLinks;

  currentYear: string = this._datePipe.transform(new Date(), "yyyy") || "2023";
  lcpCopyrightLine: string = `© COPYRIGHT ${this.currentYear} LCPTRACKER, INC.`;

  shouldOpenModal!: boolean;

  constructor(
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private _datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    public oidcSecurityService: OidcSecurityService,
    public authenticationService: AuthenticationService,
  ) {
    this.breakpointObserver
      .observe("(min-width: 640px)")
      .subscribe((state: BreakpointState) => {
        this.shouldOpenModal = state.matches;
      });
  }

  ngOnInit(): void { 
    this.authenticationService.createAuthDataObjectInLocalStorage();
    this.displayLogoutConfirmationSnackbar();
  }
  
  /**
   * Displays a Snack bar with a message that user logout successfully and
   * removes from the local storage the flag that indicates that user
   * logged out
   */
  displayLogoutConfirmationSnackbar(): void {
    // Stores the value from the item in local storage called session_ended_at
    const userPortalAuthData = this.authenticationService.getAuthDataFromLocalStorage();
    
    const sessionEndedAt = userPortalAuthData.sessionEndedAt;
    
    // Checks if the item session_ended_at has a value or not
    if (sessionEndedAt) {
      
      // If the session_ended_at item has a value opens a Snack Bar with a message
      this._snackBar.openFromComponent(CustomSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 6000,
        data: {
          message: 'You are now logged out.'
        }
      });
  
      // Removes the session_ended_at item from local storage
      userPortalAuthData.sessionEndedAt = null;

      // Store new object in local storage
      this.authenticationService.setNewAuthenticationDataObjectInLocalStorage(userPortalAuthData);
      
    }

  }

  openLoginDialogWrapper(): void {
    this.dialog.open(LoginDialogWrapperComponent, {
      autoFocus: false,
      width: "515px",
      height: "fit-content",
    });
  }

  openUsernameCredentialComponent(): void {
    if (this.shouldOpenModal) {
      this.openLoginDialogWrapper();
    } else {
      this.openLoginBottomSheetWrapperComponent();
    }
  }

  openLoginBottomSheetWrapperComponent() {
    this.bottomSheet.open(LoginBottomSheetWrapperComponent);
  }
}
