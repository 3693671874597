<div class="app-card-wrapper">
    <div class="app-card" tabindex="0" (click)="onCardClick(product.uri)"
    (keypress)="onCardClick(product.uri)">
        <div class="app-card-content-wrapper">
            <img [src]="addProductImageBasedOnName(product.name)" alt="{{product.name}} Logo" height="auto" width="auto"/>
            <div class="app-card-title-wrapper" id={{product.name}} >
                <h4>{{product.name}}</h4>
            </div>
            <div class="link-component">
                <!-- <a href="appItem.appUrl">Learn More</a> -->
                <!-- <mat-icon class="icon-size"> chevron_right </mat-icon> -->
            </div>
        </div>
    </div>
</div>