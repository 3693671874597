import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FooterResponse } from 'src/app/shared/components/footer/footer-interface';

import { MENU } from '../menu';
import { ProfileCard } from 'src/app/features/dashboard/components/profile-card/profile-card';
import { DashboardService } from 'src/app/features/dashboard/dashboard.service';
import { UserNode } from 'src/app/features/dashboard/graphql/profile-info.query';
import { environment } from 'src/environments/environment';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConfirmationBottomSheetComponent } from '../confirmation-bottom-sheet.component';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent implements OnInit {
  @Input() menuItems: MENU[] = [];

  @ViewChild('drawer', { static: true }) drawer!: MatDrawer;

  accounts: string[] = [
    'CN - Tracker-IL DOT',
    'Prime Contractor - Freeway Rehabilitation',
    'American Concrete Pumping',
    'David Shoring',
    'e.g., 113272 & 113270 - BELL/CLARK JV - BRIDGESTONE ARENA & CENTRAL POLICE PRECINCT',
  ];

  open: boolean = false;

  selectedAccount = '';

  lastItemSelected = '';

  selectedSubMenu = [
    'profile',
    'support',
    'default',
    'account-selector',
    'user-profile',
    'user-support',
  ];

  filteredText = '';

  selectedProfile = '';

  thirdLevelTitle = '';

  selectedRole = '';
  panelOpenState = false;

  profileFortifyUrl: string = environment.onboardingClaims.lastProfileChange;

  userProfile: ProfileCard = {
    name: '',
    company: '',
    email: '',
    comment: '',
    photo: '',
  };

  private unsusbcribe = new Subject<boolean>();
  footer: FooterResponse[] | null = null;
  profileCard!: UserNode;

  /**
   * This variables control all steps  to open or close the 
   * log out bottomSheet or Modal, these are depending on screen size.
   */
  isMobileScreen: boolean = false;
  bottomSheetRef!: MatBottomSheetRef<MobileLayoutComponent>;
  matDialogRef!: MatDialogRef<MobileLayoutComponent>;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private dashboardService: DashboardService,
    private breakPointerObserver: BreakpointObserver,
    private bottomSheet: MatBottomSheet,
    private _userPortalDialogService: UserPortalDialogService
  ) {
    this._route.data.pipe(takeUntil(this.unsusbcribe)).subscribe((data) => {
      this.footer = data['footer']?.footerCollection?.items;
    });

    this.selectedSubMenu = ['default'];

    this.breakPointerObserver.observe("(max-width: 640px)")
    .subscribe((state: BreakpointState) => {
      this.isMobileScreen = state.matches;
    });
  }

  ngOnInit(): void {
    this.selectedAccount = this.accounts[0] ? this.accounts[0] : '';
    this.changeStateDefaultDrawer();
    this.getUserInfo();
  }

  getUserInfo(): void {
    this.dashboardService
      .fetchProfileCardInfo('network-only')
      .then((response) => {
        this.profileCard = response;
      });
  }

  goToPanel(subMenuName: string, subMenuLabel?: string): void {
    this.lastItemSelected = this.selectedSubMenu[0];
    this.selectedSubMenu = [subMenuName];
    if (subMenuLabel) {
      this.thirdLevelTitle = subMenuLabel;
    }
  }

  goBackToMainMenu(isBackStep?: boolean): void {
    if (isBackStep) {
      this.goToPanel(this.lastItemSelected);
      this.lastItemSelected = 'default';
      return;
    }
    this.selectedSubMenu = ['default'];
  }

  changeStateDefaultDrawer(): void {
    this.drawer.openedChange.subscribe((opener: boolean) => {
      const menuItems = [
        'profile',
        'support',
        'account-selector',
        'user-profile',
        'user-support'
      ];
      if (opener && menuItems.includes(this.selectedSubMenu[0])) {
        this.goBackToMainMenu();
      }
    });
  }

  navigateFromSubLevel(url: string): void {
    this.drawer.close();
    this.goBackToMainMenu();
    this.router.navigateByUrl(url);
  }

  setRole(role: string): void {
    this.selectedRole = role;
  }

  setProfile(profile: string): void {
    this.selectedProfile = profile;
  }

  setAccount(account: string): void {
    this.selectedAccount = account;
    this.drawer.close();
    this.filteredText = '';
    this.goBackToMainMenu();
  }

  ngOnDestroy(): void {
    this.drawer.openedChange.next(false);
    this.drawer.openedChange.unsubscribe();
  }

  // getUserInfo(): void {
  //   let claims: any = this.oAuthService.getIdentityClaims();
  //   this.userProfile.email = claims['email'];
  //   this.userProfile.name = claims['name'];
  // }

  /*
   * Triggers the action to logout the user from the identity server
   */

  /**
   * Opens LogoutDialog after screen size is considered
   * tablet screen size or horizontal screen. 
   */
  showLogoutDialog(): void {
    this.drawer.close();
    const logoutConfirmationDialogRef = this._userPortalDialogService.userPortalDialog(
      `Are you sure you want to leave?`,
      `   
      You are logging out of all LCPtracker applications.
      `,
      'Confirm',
      'Cancel'
    );

    logoutConfirmationDialogRef.afterClosed().subscribe(value => {
      if(value) {
        this.logout();
      } else {
        this.drawer.open();
      }
    })
  }
  /**
   * Opens BottomSheet when screen size is validated.
   */
  openConfirmationBottomSheet(): void {
    this.drawer.close();
    const logoutBottomSheetRef = this.bottomSheet.open(ConfirmationBottomSheetComponent, {
      data: {
          title: 'Are you sure you want to leave?',
          body: `<p>
                  You are logging out of all LCPtracker applications.
                  </p>`,
          confirmationButton: 'Confirm',
          cancelButton: 'Cancel'
          },
    });
    logoutBottomSheetRef.afterDismissed().subscribe(value => {
      if (value) {
        this.logout()
      } else {
        this.drawer.open();
      }
    });
  }

  /**
   * Evaluates if screen size is valid to display 
   * the confirmation bottom sheet
   */
  showLogoutConfirmation(): void {
    if (this.isMobileScreen) {
      this.openConfirmationBottomSheet() 
    } else {
      this.showLogoutDialog();
    }
  }
  
  logout(): void {
    this.authenticationService.logout();
  }
}
