import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
//
// Non-Angular : Will need a module for unit testing.
import { MaterialsModule } from './core/materialIo.module';
import { GraphQLModule } from './core/graphql.module';
import { OidcConfigModule } from './core/oidc-config.module';
import { SharedModule } from './shared/shared.module';
import { UserIdleModule } from './core/user-idle/user-idle.module';
//
// Services
import { AuthenticationService } from './features/authentication/services/authentication.service';
import { AuthLoggerService } from './services/auth/auth-logger.service';
import { HeaderInterceptor } from './shared/services/auth-interceptor/rbs-interceptor';
//
// Route Modules, Features, and other stuff
import { AppComponent } from './app.component';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { ContactModule } from './features/contact/contact.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { SupportModule } from './features/support/support.module';
import { SupportConsoleModule } from './features/support-console/support-console.module';

export const INACTIVITY_TIME_IN_SECONDS = 1800;
export const LOG_OUT_COUNTDOWN_IN_SECONDS = 90;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialsModule,
    GraphQLModule,
    DashboardModule,
    ContactModule,
    SupportModule,
    SharedModule,
    SupportConsoleModule,
    UserIdleModule.forRoot({
      idle: INACTIVITY_TIME_IN_SECONDS,
      timeout: LOG_OUT_COUNTDOWN_IN_SECONDS,
    }),
    AuthenticationModule,
    OidcConfigModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
  ],
})
export class AppModule {
  constructor(
    private readonly authenticate: AuthenticationService,
    private authlogger : AuthLoggerService
    ) {
    this.authenticate.registerForEvents().subscribe((result) => {
      this.authlogger.logEventType(result);
    })
  }
}
