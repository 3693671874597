import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { FooterResponse } from 'src/app/shared/components/footer/footer-interface';
import { PortalSharedService } from 'src/app/shared/services/portal-shared/portal-contentful.service';
import { GetUserDataByIDService } from '../services/get-user-data-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class PortalDataResolver implements Resolve<FooterResponse[]> {

  constructor( private portalContentful: PortalSharedService) {}

  resolve(): Promise<FooterResponse[]> {
    return this.portalContentful.fetchFooterComponent('network-only');
  }
}