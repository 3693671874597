import { gql } from "apollo-angular";

export const GET_PROFILE_INFO = gql`
  query userProfile($id: String) {
    userProfile: users(where: { id: { eq: $id } }) {
      userNodes: nodes {
        id
        firstName
        lastName
        email
        userName
        prefix
        suffix
        twoFactorEnabled
        emailConfirmed
        lockoutEnabled
        phoneExtension
        phoneNumber
        primaryIdentifierNumber
        suffix
        usersInOrg {
          org {
            orgName
          }
          isStaff
        }
      }
    }
  }
`;

export const GET_APP_TILES = gql`
  query getApps {
    homePageLinks(where: { isEnabled: { eq: true } }, order: { order: ASC }) {
      tilesNodes: nodes {
        name
        description
        uri
        order
        className
        requireAuth
        isPublicLink
        isEnabled
        staffOnly
      }
    }
  }
`;

export interface UserProfileResponse {
  userProfile: UserProfile;
}
export interface UserProfile {
  userNodes: UserNode[];
}

export interface UserNode {
  id?: string;
  firstName: string;
  lastName: string;
  email?: string;
  userName?: string;
  prefix?: string;
  suffix?: string;
  twoFactorEnabled?: boolean;
  emailConfirmed?: boolean;
  lockoutEnabled?: boolean;
  phoneExtension?: string;
  phoneNumber?: string;
  primaryIdentifierNumber?: string;
  usersInOrg: UsersInOrg[];
}

export interface UsersInOrg {
  org: Org;
  isStaff: boolean | number;
}

export interface Org {
  orgName: string;
}

export interface TileNode {
  name: string;
  description: string;
  uri: string;
  order: number;
  className: string;
  requireAuth: boolean;
  isPublicLink: boolean;
  isEnabled: boolean;
  staffOnly: boolean;
  __typename: string;
}

export interface HomePageLinks {
  tilesNodes: TileNode[];
}

export interface HomePageLinksResponse {
  homePageLinks: HomePageLinks;
}

