import { Component, Input } from '@angular/core';
import { MarketingBannerItem } from '../../graphql/dashboard-data.query';
@Component({
  selector: 'app-marketing-advertisement',
  templateUrl: './marketing-advertisement.component.html',
  styleUrls   : ['./marketing-advertisement.component.scss']
})
export class MarketingAdvertisementComponent {

  @Input() advertisement!: MarketingBannerItem;

  /**
   * Gets the advertisement behavior for the banner
   */
  get advertisementTarget(): string {
    // If the advertisement object isn't empty, the target of the object gets
    // checked and returns the string of the behavior to be set from the HTML
    switch (this.advertisement.target) {
      case 'new tab':
        return '_blank';
      case 'same tab':
        return '_self';
      case 'previous tab':
      default:
        return 'undefined'
    }
  }
}
