import { gql } from 'apollo-angular';

export const RESET_PASSWORD_USER_BY_USERNAME = gql`
  mutation resetPasswordUserByUsername($userName: String!) {
    resetPassword(username: $userName) {
      id
      message
      data
      unhandledException
      status
    }
  }
`;

export interface BoolResultModel {
  id: string | null;
  message: string | null;
  data: boolean | null;
  unhandledException: string | null;
  status: number | null;
}

export interface ResetPasswordReturningEntity {
  resetPassword: BoolResultModel;
}
