<div class="sso-page-container">
  <div class="sso-page-card sm:mx-2.28">
      <img src="assets/images/lcptracker-full-color.svg" alt="LCPtracker Logo" width="auto" height="auto">
      <div class="progress-spinner-container">
          <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate" diameter="50">
          </mat-progress-spinner>
      </div>
      <h4>Verification</h4>
      <p>We are verifying your identity...</p>
  </div>
  <footer></footer>
</div>