import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ContentfulService } from '../../services/contentful-http/contentful.service';
import { Notification, NotificationDisplay } from 'src/app/shared/services/contentful-http/contentful.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() title = 'Notifications';
  @Input() showNotify: boolean = true; 
  @Input() showAllNotifications: boolean = false;

  @Input() notifications: Notification[] = [];
  @Input() importantNotifications: Notification[] = [];
  
  streamNotify! : Subscription;     
  displayNotify! : Subscription;  
  
  constructor(
    private router : Router,
    private contentful : ContentfulService,
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
  unsubscribe() : void {
    this.streamNotify?.unsubscribe();
    this.displayNotify?.unsubscribe();
  }
  ngOnInit(): void {
  /**
   * Listens to the ContentfulService Behavior Subject (notifications$) to push in notifications
   */
    this.streamNotify = this.contentful.getNotifications()
      .subscribe((value : Notification[] ) => {
        if (value == (null || undefined )) { return; }
        if (!value.length) { return; }
        this.notifications = value;
    });
  /**
   * Listens to the ContentfulService Behavior Subject (displayNotification$) to show notifications (important) 
   */
    this.displayNotify = this.contentful.getDisplayNotifications()
      .subscribe((value : NotificationDisplay ) => {
        if (value == (null || undefined )) { return; }
        this.showNotify = value.display;
        this.showAllNotifications = value.showAll;
    });
    /**
     * Check for existing Behavior Subjects. ie. tablets switching from mobile to desktop.
     * Base data on streams than component control.
     */
     this.checkNotifications();  
  } // end of ngOnInit()

  /**
   * There could me more services to flag ( authentication services if onboarding is
   * NOT complete.
   */
  checkNotifications() {
    if (this.notifications.length === 0 ) {
      if (this.showAllNotifications === false) {
        this.contentful.passImportantNotifications();
      } 
      if (this.showAllNotifications === true) {
        this.contentful.passAllNotifications();
      }
    }
  }
  /**
   * There could me more services to flag ( authentication services if onboarding is
   * NOT complete for example ).
   */
  closeNotification():void {
    this.contentful.setDisplayNotifications({display: false, showAll: true });
    this.showNotify = false;
  }
  /**
   * With the Object decide on how to handle the action event.
   * 
   */
  callToAction(object : Notification) {
    // object.actionType  options: 'website-url', 'user-portal-route', 'video'
    // keeping a switch case for future growth.
    if ( !object.actionType ) { return; }
    switch (object.actionType) {
      case 'user-portal-route':
        let obj = object.redirect ? object.redirect : '';
        this.router.navigateByUrl(obj);
        break;
      case 'website-url':
        window.open(object.redirect, object.target);
        break;
    }
  }
  /**
   * Remove selected Object from the Stack, call contentful service to 
   * remove Total count in header, and put in Storage.
   * 
   */
  dismissNotification(object : Notification, index: number) {
    this.notifications.splice(index, 1);
    this.contentful.storeNotification(object);
  }



}
