<div class="organization-information">
  <h6>Organizations</h6>
  <div class="pl-1.14">
      <h6>Agencies</h6>
      <mat-divider></mat-divider>
      <ng-container *ngIf="hasAccountAgency(user.node.usersInOrg)">
          <ng-container *ngFor="let item of user.node.usersInOrg">
            <div class="org-wrapper pl-0.57" *ngIf="item.org.orgType === ORG_TYPES.AGENCY">
                <mat-icon>domain</mat-icon>
                <div class="org-details"> 
                  <span class="label">Name (TPA Rule)</span> 
                  <span class="value">{{item.org.orgName}} ({{item.org.tpaRule | replace: '_' : ' '}})</span> 
                </div>
            </div>
          </ng-container>
      </ng-container>
      <p class="results-not-found" *ngIf="!hasAccountAgency(user.node.usersInOrg)">No agencies were found for this users.</p>
      <h6>Contractors</h6>
      <mat-divider></mat-divider>
      <ng-container *ngIf="hasAccountContractor(user.node.usersInOrg)">
          <ng-container *ngFor="let item of user.node.usersInOrg">
            <div class="contractor" *ngIf="item.org.orgType === ORG_TYPES.CONTRACTOR ">
                <div class="name-wrapper">
                  <mat-icon>apartment</mat-icon>
                  <p class="contractor-name">{{item.org.orgName}}</p>
                </div>
            </div>
          </ng-container>
      </ng-container>
      <p class="results-not-found" *ngIf="!hasAccountContractor(user.node.usersInOrg)">No contractors were found for this users.</p>
  </div>
</div>