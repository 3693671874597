<section class="change-log-container">
  <div class="change-log-header">
    <h6>Email Change Log</h6>
    <div class="flex justify-between items-center">
      <div class="">
        <p class="name">{{data.name}}</p>
        <p class="email">{{data.email}}</p>
      </div>
      <div class="date-picker-container">
        <mat-form-field appearance="fill">
          <mat-label>Search By Change Date</mat-label>
          <input matInput [formControl]="searchDateControl" [matDatepicker]="picker" [max]="currentDate"
            onkeydown="javascript:void(0)" (click)="picker.open()" readonly>
          <mat-datepicker-toggle *ngIf="!searchDateControl.value" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint class="text-sm">MM/DD/YYYY</mat-hint>
          <button mat-icon-button matSuffix *ngIf="searchDateControl.value" aria-label="Clear"
            (click)="resetDateSearchAndPagination()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="change-log-body">
    <mat-dialog-content class="mat-typography">
      <mat-progress-bar *ngIf="isLoading | async" mode="indeterminate"></mat-progress-bar>
      <div class="login-history">
        <div class="project-wage-table-card">
          <table mat-table aria-label="Table Template" class="project-wage-table-card-data" [dataSource]="dataSource"
            matSort>
            <ng-container>
              <ng-container class="project-wage-table-card-data-container"
                [matColumnDef]="tableColsRefConsts.note">
                <th class="project-wage-table-card-data-container-header" scope="col" mat-header-cell *matHeaderCellDef
                  mat-sort-header>Note</th>
                <td mat-cell *matCellDef="let element">{{element.node.fieldsChanged }}</td>
              </ng-container>
              <ng-container class="project-wage-table-card-data-container" [matColumnDef]="tableColsRefConsts.changedBy">
                <th class="project-wage-table-card-data-container-header" scope="col" mat-header-cell *matHeaderCellDef
                  mat-sort-header>Changed By</th>
                <td mat-cell *matCellDef="let element">{{element.username}}</td>
              </ng-container>
              <ng-container class="project-wage-table-card-data-container" [matColumnDef]="tableColsRefConsts.date">
                <th class="project-wage-table-card-data-container-header" scope="col" mat-header-cell *matHeaderCellDef
                  mat-sort-header>Date/Time</th>
                <td mat-cell *matCellDef="let element">{{element.node.changeDate | date:'medium'}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="tableHeaderReferences"></tr>
              <tr mat-row *matRowDef="let row; columns: tableHeaderReferences" class="project-wage-table-card-data-row">
              </tr>
            </ng-container>
          </table>
          <div *ngIf="changeLogData.length === 0 && (hasBeenSearching$ | async) && !(errorServer | async)"  class="no-results-table-banner">
            <p class="title">The current criteria did not yield any matching results.</p>
            <button (click)="resetDateSearchAndPagination()" mat-button color="primary">Clear search criteria</button>
          </div>
          <div *ngIf="changeLogData.length === 0 && !(hasBeenSearching$ | async) && !(isLoading |async) && !(errorServer | async)"  class="no-results-table-banner">
            <p class="title">The current user does not have any change log data available yet.</p>
          </div>
          <div *ngIf="(errorServer | async)" class="no-results-table-banner-error">
            <p class="title">Apologies, an error occurred while retrieving the change log data. Please try again later.</p>
            <p class="subtitle">If the issue persists, please contact our support team for assistance.</p>
          </div>
        </div>

      </div>
    </mat-dialog-content>
  </div>
  <mat-dialog-actions align="end" class="snap-always">
    <div class="flex flex-col align-items-end justify-end gap-2">
      <div [hidden]="changeLogData.length === 0">
        <mat-paginator #paginator [length]="this.paginationState.totalElements" [pageSize]="this.paginationState.pageSize"
          [pageSizeOptions]="[5, 10]" aria-label="Select page" showFirstLastButtons="true"
          (page)="handlePage($event)">
        </mat-paginator>
      </div>
      <div>
        <input cdkFocusInitial type="text" class="hidden" />
        <button mat-button [mat-dialog-close]="true">Close</button>
      </div>
    </div>
  </mat-dialog-actions>
</section>