import { Injectable } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { GET_FOOTER_COMPONENT, GET_INFO_CARD_COMPONENT } from '../../graphql/shared-data.query';
import { GraphqlHostService } from '../graphql-host/graphql-host.service';


@Injectable({
  providedIn: 'root'
})
export class PortalSharedService {

  applyPolicy!: WatchQueryFetchPolicy;
  constructor(private graphqlHost: GraphqlHostService) {}

  /**
   *
   * @param policy
   * @returns The footer User Portal data pulled from Contentful Graphql API
   */
  fetchFooterComponent(policy: FetchPolicy): Promise<any> {
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_FOOTER_COMPONENT,
        null,
        optionalContext,
        this.applyPolicy
      ).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  }

  /**
   *
   * @param policy
   * @returns The Info Cards User Portal data pulled from Contentful Graphql API
   */
  fetchCardComponent(policy: FetchPolicy): Promise<any> {
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const optionalContext = null;
    const vars = { sectionId: 'Contact' };

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_INFO_CARD_COMPONENT,
        vars,
        optionalContext,
        this.applyPolicy
      ).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  }

}
