import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { DashboardContentfulPage } from '../graphql/dashboard-data.query';


@Injectable({
  providedIn: 'root'
})
export class DashboardContentResolver implements Resolve<DashboardContentfulPage> {

  constructor(private _dashboardService: DashboardService) {}

  resolve(): Promise<any> {
    return this._dashboardService.fetchDashboardPage('network-only');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProfileUserContentResolver implements Resolve<any> {

  constructor(private _dashboardService: DashboardService) {}

  resolve(): Promise<any> {
    return this._dashboardService.fetchProfileCardInfo('network-only');
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppTilesContentResolver implements Resolve<any> {

  constructor(private _dashboardService: DashboardService) {}

  resolve(): Promise<any> {
    return this._dashboardService.fetchAppTiles('network-only');
  }
}
