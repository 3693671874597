import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { 
  AuthOptions, 
  AuthenticatedResult, 
  EventTypes, 
  LoginResponse, 
  OidcClientNotification, 
  OidcSecurityService, 
  OpenIdConfiguration, 
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { distinctUntilChanged,  filter,  Observable,  map,  BehaviorSubject,  concatMap,  of,  forkJoin,} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Certified, FindWaldo, LcpTracker } from '../interfaces/auth-interface';
import { UserPortalAuthData } from './interfaces/local-storage-auth-data.interface';
import {
  USER_PORTAL_AUTH_DATA_EMPTY,
  USER_PORTAL_AUTH_DATA_ITEM,
} from './consts';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  token : string | null = null; // AccessToken : something we do not have to subscribe to.
  token$ = new BehaviorSubject<string>(''); // IdToken : for components that subscribe to user info.
  isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  userId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  userName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  canUseSupportToolsSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  configuration!: OpenIdConfiguration[];
  userPortalAuthData: UserPortalAuthData = USER_PORTAL_AUTH_DATA_EMPTY;

  private urlTrackerApi = environment.LcpTrackerApi;
  private urlCertifiedApi = environment.CertifiedApi;

  constructor(
    private http: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService,
    private router: Router
  ) {}

  /**
   * angular-auth-oidc-client Managed Services Below
   */
  authorize() : void {
    this.oidcSecurityService.authorize();
  }

  checkAuth() : Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth();
  }

  checkAuthIncludingServer(configId?: string | undefined): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuthIncludingServer(configId);
  }

  checkAuthMultiple() : Observable<LoginResponse[]> {
    return this.oidcSecurityService.checkAuthMultiple();
  }

  checkConfigLoaded() : Observable<OidcClientNotification<any>> {  // app.module when it bootstraps
    return this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded));
  }

  checkSessionChanged() : Observable<boolean> {
    return this.oidcSecurityService.checkSessionChanged$;
  }

  forceRefreshSession(
    customParams?: {[key: string]: string | number | boolean } | undefined, 
    configId?: string | undefined): Observable<LoginResponse> {
    return this.oidcSecurityService.forceRefreshSession(customParams, configId);
  }

  getAccessToken(configId?: string | undefined): Observable<string> {
    return this.oidcSecurityService.getAccessToken(configId);
  }

  getConfigurations() : OpenIdConfiguration[]  {
    return this.oidcSecurityService.getConfigurations();
  }

  getRefreshToken(configId?: string | undefined): Observable<string>{
    return this.oidcSecurityService.getRefreshToken(configId);
  }

  getAllRefreshTokens() : Observable<string[]> {
    this.configuration = this.getConfigurations();
    let result: any = [];
    for (let config of this.configuration ) {
      if (config.configId) {
        result.push(this.oidcSecurityService.getRefreshToken(config.configId));
      }
    }
    return of(result);
  }

  getState(configId?: string | undefined): Observable<string> {
    return this.oidcSecurityService.getState(configId);
  }

  isAuthenticated(configId?: string | undefined): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated(configId);
  }

  isAuthenticatedObservable() :Observable<AuthenticatedResult> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  loginWithClientId(configIdOrNull: string ) : void {
    this.oidcSecurityService.authorize(configIdOrNull);
  }

  logoff(configId?: string | undefined, authOptions?: AuthOptions | undefined): void {
    this.oidcSecurityService.logoff(configId, authOptions);
  }

  logoffAndRevokeTokens(configId?: string | undefined): Observable<any> {
    return this.oidcSecurityService.logoffAndRevokeTokens(configId);
  }

  stsCallbackObservable() : Observable<any> {
    return this.oidcSecurityService.stsCallback$;
  }

  registerForEvents(): Observable<OidcClientNotification<any>> {
    return this.eventService.registerForEvents();
  }

  revokeAccessToken(accessToken?: any, configId?: string | undefined): Observable<any> {
    return this.oidcSecurityService.revokeAccessToken(accessToken, configId);
  }

  revokeRefreshToken(refreshToken?: any, configId?: string | undefined): Observable<any> {
    return this.oidcSecurityService.revokeRefreshToken(refreshToken, configId);
  }

  /**
   * Application Managed Services Below
   */
  setAccessToken(token: string) {
    this.token = token;
  }

  /**
   * checkSessionEvent
   */
  checkSessionEvent(): void {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.CheckSessionReceived
        ),
        distinctUntilChanged((prev, curr) => prev.value === curr.value)
      )
      .subscribe((notification: OidcClientNotification<string>) => {
        const currentDate = new Date();
        if (environment.env !== 'prod') {
          console.log(
            'CheckSessionReceived with value from app',
            notification,
            `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
          );
        }
        if (notification.value == 'changed') {
          if (environment.env !== 'prod') {
            console.log(
              `%cShould logout`,
              'background-color: red ; color: white ; font-weight: bold ; padding: 8px ;'
            );
          }
          this.logout();
          this.router.navigateByUrl('/');
        }
      });
  }

  checkIsAuthenticated(): void {
    this.oidcSecurityService.isAuthenticated$.subscribe((response) => {
      if (response.isAuthenticated === true) {
        if (environment.env !== 'prod') {
          console.log(
            `%cIS AUTH`,
            'background-color: green ; color: white ; font-weight: bold ; padding: 8px ;'
          );
        }
        this.isAuthenticated$.next(true);
      } else {
        if (environment.env !== 'prod') {
          console.log(
            `%cIS NOT AUTH`,
            'background-color: red ; color: white ; font-weight: bold ; padding: 8px ;'
          );
        }
        this.isAuthenticated$.next(false);
      }
    });
  }

  /**
   * Creates the an empty instance of the authentication data object and
   * stores it in the local storage.
   */
  createAuthDataObjectInLocalStorage(): void {
    // Gets the data stored in the local storage
    let userPortalAuthenticationData: UserPortalAuthData | string | null =
      localStorage.getItem(USER_PORTAL_AUTH_DATA_ITEM);
    // The object gets created with null value in each object attribute
    // and stored in the local storage
    userPortalAuthenticationData = USER_PORTAL_AUTH_DATA_EMPTY;
    localStorage.setItem(
      USER_PORTAL_AUTH_DATA_ITEM,
      JSON.stringify(userPortalAuthenticationData)
    );
  }

  /**
   * Returns the item with the authentication data
   *
   * @returns
   */
  getAuthDataFromLocalStorage(): UserPortalAuthData {
    const userPortalAuthData: string | null = localStorage.getItem(
      USER_PORTAL_AUTH_DATA_ITEM
    );

    if (!userPortalAuthData) {
      return USER_PORTAL_AUTH_DATA_EMPTY;
    }

    const userPortalAuthDataParsed: UserPortalAuthData =
      JSON.parse(userPortalAuthData);

    return userPortalAuthDataParsed;
  }

  /**
   * Sets the the object in the User Portal Authentication Data in the
   * local storage
   *
   * @param newAuthData
   */
  setNewAuthenticationDataObjectInLocalStorage(
    newAuthData: UserPortalAuthData
  ): void {
    localStorage.setItem(
      USER_PORTAL_AUTH_DATA_ITEM,
      JSON.stringify(newAuthData)
    );
  }

  login(username?: string): void {
    if (username) {
      const options: AuthOptions | undefined = {
        customParams: {
          login_hint: username,
        },
      };
      this.oidcSecurityService.authorize(undefined, options);
    } else {
      this.oidcSecurityService.authorize();
    }
  }

  logout(): void {
    const userPortalAuthData = this.getAuthDataFromLocalStorage();

    userPortalAuthData.expirationDate = null;

    userPortalAuthData.sessionStatedAt = null;

    userPortalAuthData.sessionEndedAt = new Date();

    this.setNewAuthenticationDataObjectInLocalStorage(userPortalAuthData);

    this.oidcSecurityService.logoff();
  }

  getUserData(): void {
    this.isAuthenticated$
      .pipe(
        concatMap((validToken) => {
          if (validToken) {
            return forkJoin({
              idTokenPayload: this.oidcSecurityService.getPayloadFromIdToken(),
              accessTokenPayload:
                this.oidcSecurityService.getPayloadFromAccessToken(),
            });
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: (response) => {
          this.userId.next(response?.idTokenPayload.sub);
          response?.accessTokenPayload?.CanUseSupportTools === 'true'
            ? this.canUseSupportToolsSubject.next(true)
            : this.canUseSupportToolsSubject.next(false);
        },
      });
  }

  validDomainEmail(email: string): boolean {
    if (
      email.toLowerCase().includes('@lcptracker.com') ||
      email.toLowerCase().includes('@cikume.com')
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if a user's IP is in the company IP whitelist
   * @returns if a user's IP whitelisted
   */
  isUserIpInTheWhilist(): Observable<boolean> {
    return this.http
      .get<any>(
        `${environment.applications.fortify.api}/IPPolicy/IsClientCompliant`
      )
      .pipe(map((response) => response.data));
  }

  /**
   * Checks IDP that user belongs
   * @returns Source of IDP version '6', '2.2', 'none'
   * Character related to the enviroments ((D or d) = Dev; (T or t) = Test; (P or p) = Prod; (M or m))
   */
  checkUserIDPSource(email: string): Observable<FindWaldo> {
    const host: string = environment.waldoApi;
    const env: string = environment.waldoEnv;
    const username: string = email;
    return this.http.post<FindWaldo>(
      `${host}/api/CaptureData/ReturnUserSource?env=${env}&username=${encodeURIComponent(
        username
      )}`,
      null
    );
  }

  /**
   * Checks if a user exists in TrackerPro
   * @returns Observable<LcpTracker>
   */
  checkUserExistsTrackerPro(email: string): Observable<LcpTracker> {
    return this.http.post<LcpTracker>(this.urlTrackerApi, { id: email });
  }

  /**
   * Checks if a user exists in Certified
   * @returns Observale<Certified>
   */
  checkUserExistsCertified(email: string): Observable<Certified> {
    return this.http.post<Certified>(this.urlCertifiedApi, { id: email });
  }

  /**
   * Create the enviroment param based on the current host of User Portal
   * @returns Character related to the enviroments ((D or d) = Dev; (T or t) = Test; (P or p) = Prod; (M or m))
   */
  getHostEnv(host: string): string {
    if (host.includes('dev')) {
      return 'd';
    } else if (host.includes('test')) {
      return 't';
    } else if (
      host.includes('UserPortal.Fortifyv2.lcptracker.net') ||
      host.includes('userportal.fortifyv2.lcptracker.net')
    ) {
      return 'p';
    } else {
      return 'd';
    }
  }
}
