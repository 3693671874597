import { Component, Input, OnInit } from '@angular/core';
import { listAnimation } from './how-can-i-animations';
import { HowCanIInterface } from './how-can-i';

const MAX_NUMBER_OF_COLUMNS = 3;
const MAX_LINKS_TO_DISPLAY_IN_THE_PRIMARY_ = 9;

@Component({
  selector: 'app-how-can-i',
  templateUrl: './how-can-i.component.html',
  styleUrls: ['./how-can-i.component.scss'],
  animations: [listAnimation]
})
export class HowCanIComponent implements OnInit {

  @Input() linksHowCanI: HowCanIInterface[] = [];
  linksResultByColumn: any[] = [];
  showMore = false;
  hiddenButtonShowMore = true;

  ngOnInit(): void {
    this.getLinks();
  }

  getLinks(): void {
    if (this.linksHowCanI.length <= MAX_LINKS_TO_DISPLAY_IN_THE_PRIMARY_) {
      this.hiddenButtonShowMore = false;
    }
    this.linksResultByColumn = this.addingElementsToColumn(this.linksHowCanI);
  }

  addingElementsToColumn(linksHowCanI: HowCanIInterface[]): Array<Object> {

    const columnsWithLinks = [];
    const countTotalLinksEntries = linksHowCanI.length;
    const numberOfLinksPerColumn = Math.floor(countTotalLinksEntries / MAX_NUMBER_OF_COLUMNS);
    const moduleRemained = Math.round(countTotalLinksEntries % MAX_NUMBER_OF_COLUMNS);
    let positionOfArray = 0;

    for (let index = 0; index < MAX_NUMBER_OF_COLUMNS; index++) {
      const linksPerColumn = (index < moduleRemained) ? numberOfLinksPerColumn + 1 : numberOfLinksPerColumn;
      columnsWithLinks[index] = linksHowCanI.slice(positionOfArray, positionOfArray + linksPerColumn);
      positionOfArray += linksPerColumn;
    }
    return columnsWithLinks;
  }
}
