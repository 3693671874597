import { Injectable } from '@angular/core';
import {
  Resolve
} from '@angular/router';
import { ContactService } from '../contact.service';


@Injectable({
  providedIn: 'root'
})
export class ContactContentResolver implements Resolve<any> {

  constructor(private _contanctService:ContactService) {}

  resolve(): Promise<any>  {
    return this._contanctService.fetchContactPage('network-only');
  }
}
