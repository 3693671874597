import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

//Avoids user to bypasses the onboarding  process
export const TokenExpiredGuard: CanActivateFn = () => {
  const authenticationService = inject(AuthenticationService);

  const userPortalAuthData = authenticationService.getAuthDataFromLocalStorage();

  const endSession = parseInt(userPortalAuthData.expirationDate || '0', 10);

  if (!endSession) {
    return true;
  }
  const endSessionDate = new Date(endSession);
  const currentDate = new Date();
  const remaining = endSessionDate.getTime() - currentDate.getTime();

  if (remaining > 0) {
    return true;
  }
  localStorage.removeItem('exp')
  userPortalAuthData.expirationDate = null;

  authenticationService.setNewAuthenticationDataObjectInLocalStorage(
    userPortalAuthData
  );

  authenticationService.logout(); 
  return false;
};
