import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { AuthenticatedResult, OidcSecurityService} from 'angular-auth-oidc-client';
import { filter, switchMap } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from 'src/environments/environment';

type onboardingClaim = string | number | boolean | string[];
@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss'],
})
export class LoginCallbackComponent implements OnInit {
  logo: string = 'assets/images/lcptracker-full-color.svg';
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  diameter = 50;

  header: string = `Loading...`;
  tagline: string = '';

  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.oidcSecurityService.isAuthenticated$
      .pipe(
        filter(
          (isAuthenticadedResult: AuthenticatedResult) =>
            isAuthenticadedResult.isAuthenticated
        ),
        switchMap(() => this.oidcSecurityService.getPayloadFromIdToken())
      )
      .subscribe({
        next: (response: Record<string, onboardingClaim>) => {
          const userPortalAuthData = this.authenticationService.getAuthDataFromLocalStorage();
          
          const storageInfo = JSON.parse(
            sessionStorage.getItem('0-UserPortSpa') || '{}'
          );

          if (storageInfo.hasOwnProperty('access_token_expires_at') && !userPortalAuthData.expirationDate) {
            userPortalAuthData.expirationDate = JSON.stringify(storageInfo['access_token_expires_at']);
            this.authenticationService.setNewAuthenticationDataObjectInLocalStorage(userPortalAuthData);
          }

          if (response['LastOnboarded']) {
            this.authenticationService.userName.next(
              response['preferred_username'].toString()
            );
            this.header = `You've logged in`;
            this.tagline = 'You will be redirected in a moment...';
            setTimeout(() => {
              this.router.navigateByUrl('dashboard');
            }, 2000);
          } else {
            this.header = 'Almost done!';
            this.tagline =
              'Just a couple more steps to finish your new profile!';
            setTimeout(() => {
              this.handleMissingOnboardingClaims(response);
            }, 3000);
          }
        },
      });
  }

  handleMissingOnboardingClaims(response: Record<string, onboardingClaim>) {
    if (!response['LastTermsAccepted']) {
      window.location.href = environment.onboardingClaims.agreements;
      return;
    }

    if (
      !response['LastProfileChanged'] ||
      response['LastProfileChanged'] == '01/01/9999'
    ) {
      document.location.href = environment.onboardingClaims.lastProfileChange;
      return;
    }

    if (
      !response['LastPasswordChanged'] ||
      response['LastPasswordChanged'] == '01/01/9999'
    ) {
      document.location.href = environment.onboardingClaims.password;
      return;
    }

    if (!response['TwoFactorType']) {
      document.location.href =
        environment.onboardingClaims.twoFactorAuthentication;
    }
  }
}
