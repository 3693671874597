import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Component } from '@angular/core';

@Component({
  selector: 'app-login-bottom-sheet-wrapper',
  template: `
    <username-credentials (closeComponent)="handleCloseEvent($event)"></username-credentials>
  `,
})
export class LoginBottomSheetWrapperComponent {

  constructor(private _bottomSheetRef: MatBottomSheetRef<LoginBottomSheetWrapperComponent>) { }

  handleCloseEvent($event: any): void {
    this._bottomSheetRef.dismiss();
  }
}