<div>
  <h6>Login history</h6>
  <div>
    <p class="name">{{data.name}}</p>
    <p class="email">{{data.email}}</p>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="login-history">
    <mat-progress-bar *ngIf="isLoading | async" mode="indeterminate"></mat-progress-bar>

    <div class="login-wrapper mt-6" *ngFor="let item of loginHistoryGraphQL | slice:0:100">
      <mat-icon>laptop_mac</mat-icon>
      <div class="w-11/12 flex justify-between">
        <div class="login-details">
          <p class="date">{{item.node.event| date}}</p>
          <div class="time-ip">
            <span class="time">{{item.node.event | date: 'shortTime'}}</span><span> | </span><span>IP: {{item.node.gatewayIPAddress}}</span>
          </div>
        </div>
        <div class="px-4">
          <span 
            class="inline-flex items-center justify-center rounded-md px-2 py-px mx-2 text-sm font-medium ring-1 ring-inset w-44"
            [ngClass]="validateAuthTypeBadgeColor(item.node.authType) ? 'bg-red-50 text-red-700 ring-red-600/10' : 'bg-green-50 text-green-700 ring-green-600/20'"
          >
            <mat-icon class="mr-2">{{validateAuthTypeBadgeColor(item.node.authType) ? 'close' : 'done'}}</mat-icon>
            {{validateAuthTypeMessage(item.node.authType)}}
          </span>
        </div>  
      </div>   
    </div>
    <div *ngIf="(errorServer | async)" class="no-results-table-banner-error">
      <p class="title text-center">Apologies, an error occurred while retrieving the login history. Please try again later.</p>
      <p class="subtitle">If the issue persists, please contact our support team for assistance.</p>
    </div> 
  </div>
</mat-dialog-content>
<div class="dialog-footer">
  <div [hidden]="loginHistoryGraphQL.length === 0">
    <mat-paginator #paginator [length]="this.paginationState.totalElements" [pageSize]="this.paginationState.pageSize"
      [pageSizeOptions]="[5, 10]" aria-label="Select page" showFirstLastButtons="true"
      (page)="handlePage($event)">
    </mat-paginator>
  </div>
  <div class="button-container">
    <button mat-button [mat-dialog-close]="true">Close</button>
  </div>
</div>
