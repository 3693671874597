import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-confirmation-bottom-sheet',
  template: `
  <app-bottom-sheet (closeComponent)="handleCloseEvent($event)"></app-bottom-sheet>
  `,
  styles: [
    `.custom-bottom-sheet-container{background-color: 'green'}`
  ]
})
export class ConfirmationBottomSheetComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<ConfirmationBottomSheetComponent>) { }

  handleCloseEvent($event: any): void {
    this._bottomSheetRef.dismiss();
  }

  ngOnInit(): void {
  }
}
