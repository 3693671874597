import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AppTilesContentResolver, DashboardContentResolver, ProfileUserContentResolver } from './resolvers/dashboard-content.resolver';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    resolve : {
      dashboard:DashboardContentResolver,
      profileUserInfo:ProfileUserContentResolver,
      appTiles:AppTilesContentResolver
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
