import { MatPaginatorModule } from '@angular/material/paginator';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

import { UsersComponent } from './components/users/users.component';
import { SupportConsoleRoutingModule } from './support-console.routing';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ShortTextPipe } from 'src/app/shared/pipes/short-text.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ReplacePipe } from 'src/app/shared/pipes/replace.pipe';
import {MatChipsModule} from '@angular/material/chips';
import { ActionsDetailComponent } from './components/children-components/actions-detail/actions-detail.component';
import { LoginHistoryDetailComponent } from './components/children-components/login-history-detail/login-history-detail.component';
import { OrganizationDetailComponent } from './components/children-components/organization-detail/organization-detail.component';
import { SubscriptionsDetailComponent } from './components/children-components/subscriptions-detail/subscriptions-detail.component';
import { UserDetailComponent } from './components/children-components/user-detail/user-detail.component';
import { LoginHistoryComponent } from './components/modals/login-history/login-history.component';
import { ChangeLogComponent } from './components/modals/change-log/change-log.component';
@NgModule({
  declarations: [
    UsersComponent,
    LoginHistoryComponent,
    ShortTextPipe,
    ChangeLogComponent,
    ReplacePipe,
    UserDetailComponent,
    LoginHistoryDetailComponent,
    OrganizationDetailComponent,
    SubscriptionsDetailComponent,
    ActionsDetailComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    SupportConsoleRoutingModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatPaginatorModule,
    MatDialogModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatChipsModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class SupportConsoleModule { }
