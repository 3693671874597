import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialsModule } from '../core/materialIo.module';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from './components/button/button.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { ConfirmationBottomSheetComponent } from './layouts/portal/confirmation-bottom-sheet.component';
import { DesktopLayoutComponent } from './layouts/portal/desktop-layout/desktop-layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { MobileLayoutComponent } from './layouts/portal/mobile-layout/mobile-layout.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PortalComponent } from './layouts/portal/portal.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';

@NgModule({
  declarations: [
    ButtonComponent,
    BottomSheetComponent,
    ConfirmationBottomSheetComponent,
    DesktopLayoutComponent,
    FooterComponent,
    HeaderComponent,
    InfoCardComponent,
    MobileLayoutComponent,
    NotificationComponent,
    PortalComponent,
    DialogComponent,
    SafeHtmlPipe,
    CustomSnackbarComponent,
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    ButtonComponent,
    DesktopLayoutComponent,
    FooterComponent,
    HeaderComponent,
    InfoCardComponent,
    MobileLayoutComponent,
    NotificationComponent,
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
