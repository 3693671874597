import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const MOBILE_DESKTOP_LAYOUT_LIMIT = '1200';
@Injectable({
  providedIn: 'root'
})
export class LayoutStateService {

  isDesktopLayoutSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isDesktopLayout$ = this.isDesktopLayoutSubject.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([`(min-width:${MOBILE_DESKTOP_LAYOUT_LIMIT}px)`])
      .subscribe((state: BreakpointState) => {
        this.isDesktopLayoutSubject.next(state.matches);
      });
  }
}
