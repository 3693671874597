import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GetUserDataByIDService } from '../services/get-user-data-by-id.service';


//Avoids user to bypasses the onboarding  process
export const  userDataGuard: CanActivateFn = async () => {
  const getUserDataByIDService = inject(GetUserDataByIDService);
 const response = await getUserDataByIDService.fetchUserDataByID('network-only')
 return response ? true : false
};
