<div class="callback-page-container">
    <div class="callback-page-card sm:mx-2.28">
        <img [src]="logo" alt="LCPtracker Logo" width="auto" height="auto">
        <div class="progress-spinner-container">
            <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [diameter]="diameter">
            </mat-progress-spinner>
        </div>
        <h4>{{header}}</h4>
        <p>{{tagline}}</p>
    </div>
    <footer></footer>
</div>