import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storage = localStorage; // change storage object here.

  constructor() { }
  //
  // General Storage utlized as a Service.
  // Helps isolate and uniformly consumes on application.
  //
  public isExisted (key: any) {
    return this.storage.getItem(key) !== null;
  }

  public clearAll () {
    this.storage.clear();
  }

  public setItem (key: any, data: any) {
    this.storage.setItem(key, JSON.stringify(data));
    return data;
  }

  public getItem (key: any) {
    let result: any;
    if (this.isExisted(key)) {
      if (this.storage.getItem(key) !== undefined) {
        result = this.storage.getItem(key);
      }
    }
    return result;
  }

  public remove (key: any) {
    let result = false;
    if (this.isExisted(key)) {
      this.storage.removeItem(key);
      result = true;
    }
    return result;
  }

}
