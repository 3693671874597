import { Injectable } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { BehaviorSubject } from 'rxjs';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';
import { GET_CONTACT_PAGE } from './graphql/contact-data.query';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  applyPolicy!: WatchQueryFetchPolicy;
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private graphqlHost: GraphqlHostService
  ) { }

  /**
   *
   * @param policy
   * @returns The contact page data pulled from Contentful Graphql API
   */

  fetchContactPage(policy: FetchPolicy): Promise<any> {
    this.isLoadingSubject.next(true);
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const optionalContext = null;
    const vars = { sectionId: 'Contact' };

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_CONTACT_PAGE,
        vars,
        optionalContext,
        this.applyPolicy
      ).then((response) => {
        resolve(response);
        this.isLoadingSubject.next(false);
      }).catch((error) => {
        reject(error);
      })
    })
  }

}
