import { Component, Inject, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../../../consts/consts';
import { PaginationState } from '../../../graphql/support-console.queries.graphql';
import { ChangeLogService } from '../../../services/change-log.service';
import { MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT } from '../../users/users.component';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit, OnDestroy {
  /*Dependency Injections Angular 14 Feature*/
  private _changeLogService = inject(ChangeLogService);
  private _breakpointObserver = inject(BreakpointObserver);
  private _dialogRef = inject(MatDialogRef<ChangeLogComponent>);

  /*Variables*/
  changeLogData: any[] = [];
  dataSource!: ChangeLogDataSource;
  paginationVars: any;
  paginationState: PaginationState = INIT_PAGINATION_STATE;
  isLoading = new Observable<boolean>();
  errorServer = new Observable<boolean>();
  searchDateControl: FormControl = new FormControl<string>('', { nonNullable: false });
  shouldDialogBeClosed: boolean = false;
  hasBeenSearchingSubject = new BehaviorSubject<boolean>(false);
  hasBeenSearching$ = this.hasBeenSearchingSubject.asObservable();
  resettingField = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  currentDate: Date = new Date();

  /* Columns Ref Constants */
  tableColsRefConsts = {
    note: 'Note',
    changedBy: 'Changed By',
    date: 'Date',
  }

    /** * Names of the properties of the Data Source * * @required */ @Input()
  tableHeaderReferences: string[] = [
    'Note',
    'Changed By',
    'Date',
  ];

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string, name: string | null; email: string | null, userName: string }
  ) { }

  ngOnInit(): void {
    this.initValues();
    this.loadingAndServerState();
    this.pageDataSearchInit();
    this.createDataSource();
    this.onSearchCriteriaFieldChanges();
    this.getChangeLogs();
    this.loadChangeLogData();
    this.forceCloseDialog();
  }

  /**
   * Initializes the values for pagination and search in the ChangeLogService.
   * This method sets initial values for pagination variables, pagination state, and search text.
   */
  initValues(): void {
    if (this._changeLogService.paginationVarsSubject) {
      this._changeLogService.paginationVarsSubject.next(INIT_PAGINATION_VARS);
    }
    if (this._changeLogService.paginationStateSubject) {
      this._changeLogService.paginationStateSubject.next(INIT_PAGINATION_STATE);
    }
    if (this._changeLogService.onSearchTextSubject) {
      this._changeLogService.onSearchTextSubject.next(this._changeLogService.currentDateInit());
    }
  }


  /**
  * Handles the search criteria from the form input
  */
  onSearchCriteriaFieldChanges(): void {
    this.searchDateControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(formValue => {
        if (formValue && !this.resettingField) {
          const date = new Date(formValue);
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // add 1 to get 1-based index
          const day = date.getDate();
          const formattedDate = `${year}-${month}-${day}`;
          this._changeLogService.onSearchTextSubject.next(formattedDate);
          this.hasBeenSearchingSubject.next(true);
          this.paginator.firstPage();
          this.getChangeLogs();
        }
      });
  }

  getChangeLogs(): void {
    this._changeLogService.combineChangeLogsAndUserEdited(this.data.id);
  }

  loadChangeLogData(): void {
    this._changeLogService.onChangeLogDataChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: response => {
        this.changeLogData = response;
      }
    });
  }


  /**
  * Initializes the loading var
  */
  loadingAndServerState(): void {
    if (this._changeLogService.isLoading$) {
      this.isLoading = this._changeLogService.isLoading$.pipe(
        takeUntil(this._unsubscribeAll),
      );
    }
    if (this._changeLogService.errorServer$) {
      this.errorServer = this._changeLogService.errorServer$.pipe(
        takeUntil(this._unsubscribeAll),
      );
    }
  }

  /**
  * Creates a new data source for the ChangeLogComponent.
  * Initializes the data source with an instance of ChangeLogDataSource,
  * passing the ChangeLogService as a dependency.
  */
  createDataSource(): void {
    this.dataSource = new ChangeLogDataSource(this._changeLogService);
  }

  /**
   * Initializes the pagination state and vars
   */
  pageDataSearchInit(): void {
    if (this._changeLogService.paginationVars$) {
      this._changeLogService.paginationVars$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: pageInfo => {
          this.paginationVars = pageInfo;
        },
        error: error => {
          console.error('Error to get the pagination vars obs: ', error);
        },
        complete: () => { },
      });
    }

    if (this._changeLogService.paginationState$) {
      this._changeLogService.paginationState$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: (pageState: PaginationState) => {
          this.paginationState = pageState;
        },
        error: error => {
          console.error('Error to get the pagination state obs: ', error);
        },
        complete: () => { },
      });
    }

  }


  /**
   * Monitors the screen width using BreakpointObserver and closes the dialog
   * if the screen width is below a certain threshold defined by MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT.
   */
  forceCloseDialog(): void {
    this._breakpointObserver
      .observe(`(max-width: ${MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT}px)`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.shouldDialogBeClosed = result.matches;
        if (this.shouldDialogBeClosed) {
          this._dialogRef.close(true); // Close the dialog if shouldDialogBeClosed is true
        }
      });
  }



  resetDateSearchAndPagination(): void {
    this.resettingField = true; // Set the resetting flag to true
    this.hasBeenSearchingSubject.next(false);
    this.paginationState.totalElements = 0;
    this._changeLogService.onSearchTextSubject.next(this._changeLogService.currentDateInit());
    this._changeLogService.paginationVarsSubject.next(INIT_PAGINATION_VARS);
    this.getChangeLogs();
    this.searchDateControl.setValue(null); // Reset the searchDateControl
    this.resettingField = false; // Reset the resetting flag to false
    this.paginator.firstPage();
  }



  /**
 * Handles the pagination events
 */
  handlePage($event: PageEvent): void {
    if (this.paginationState.pageSize !== $event.pageSize) {
      this.handleOnPageSizeChange($event);
    }

    if ($event.pageIndex === 0) {
      this.goToFirstPage($event);
      return;
    }

    if ($event.pageIndex == this.getNumberOfPages()) {
      this.goToLastPage($event);
      return;
    }

    if ($event.previousPageIndex !== undefined && $event.pageIndex < $event.previousPageIndex) {
      this.handlePreviousPage($event);
      return;
    }

    if ($event.previousPageIndex !== undefined && $event.pageIndex > $event.previousPageIndex) {
      this.handleNextPage($event);
    }
  }

  /**
   * Calculates number of pages base on the total elements and the current page size
   */
  getNumberOfPages(): number {
    if (this.paginationState.totalElements && this.paginationState.pageSize) {
      return (Math.ceil(this.paginationState.totalElements / this.paginationState.pageSize) - 1);
    }
    return 0;
  }

  /**
   * Changes the current pageSize and update the pagination vars to trigger the query
   * @param $event
   */
  handleOnPageSizeChange($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: null,
      before: null,
      last: null,
    };
    this._changeLogService.paginationVarsSubject.next(this.paginationVars);
  }

  /**
   * Changes page to the next one
   * @param $event
   */
  handleNextPage($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: this.paginationState.endCursor,
      before: null,
      last: null,
    };
    this._changeLogService.paginationVarsSubject.next(this.paginationVars);
    this._changeLogService.combineChangeLogsAndUserEdited(this.data.id);
    this.paginationState.currentPage = $event.pageIndex;
  }

  /**
   * Changes page to the next one
   * @param $event
   */
  handlePreviousPage($event: PageEvent): void {
    this.paginationVars = {
      first: null,
      after: null,
      before: this.paginationState.startCursor,
      last: $event.pageSize,
    };
    this._changeLogService.paginationVarsSubject.next(this.paginationVars);
    this._changeLogService.combineChangeLogsAndUserEdited(this.data.id);
    this.paginationState.currentPage = $event.pageIndex;
  }

  /**
   * Changes the page to the firts one
   * */
  goToFirstPage($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: null,
      before: null,
      last: null,
    };
    this._changeLogService.paginationVarsSubject.next(this.paginationVars);
    this._changeLogService.combineChangeLogsAndUserEdited(this.data.id);
  }

  /**
   * Changes the page to the last one
   * */
  goToLastPage($event: PageEvent): void {
    this._changeLogService.lastPageSizeChange.next($event.pageSize);
    this.paginationVars = {
      first: null,
      after: null,
      before: null,
      last: null,
    };
    if ($event.length % $event.pageSize !== 0) {
      this.paginationVars.last = $event.length % $event.pageSize;
      this._changeLogService.paginationVarsSubject.next(this.paginationVars);
    } else {
      this.paginationVars.last = $event.pageSize;
      this._changeLogService.paginationVarsSubject.next(this.paginationVars);
    }
    this._changeLogService.combineChangeLogsAndUserEdited(this.data.id);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}


/**
 * Custom data source for ChangeLogComponent.
 * Extends the DataSource class from Angular Material.
 * Connects to the ChangeLogService's onChangeLogDataChanged observable to provide data for the table.
 */
export class ChangeLogDataSource extends DataSource<any[]> {
  constructor(private _changeLogService: ChangeLogService) {
    super();
  }

  /**
   * Connects to the onChangeLogDataChanged observable of the ChangeLogService.
   * @returns An observable that emits the change log data when it changes.
   */
  connect(): Observable<any> {
    return this._changeLogService.onChangeLogDataChanged;
  }

  /**
   * Disconnects the data source.
   */
  disconnect(): void { }
}