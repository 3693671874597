import { AuthenticationService } from './../../authentication/services/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WhitelistGuard implements CanActivate {
  constructor(
    private auth: AuthenticationService,
    private router: Router) { }

  async canActivate(): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.auth.isUserIpInTheWhilist());
      const isUserIpInTheWhilistState = response;
      if (isUserIpInTheWhilistState && this.auth.canUseSupportToolsSubject.value && this.auth.validDomainEmail(this.auth.userName.value)) {
        return true;
      } else {
        this.router.navigate(['/dashboard']);
        return false;
      }
    } catch (error) {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }

}
