
  <swiper #usefulSwiper id="swiper" class="swiper-container" [config]="config">
    <div class="swiper-wrapper">
      <ng-container
        *ngFor="let slide of slides; let i = index"
        [ngSwitch]="slide.type">
        <ng-container *ngSwitchCase="'overlay'">
          <div class="swiper-slide">
            <div class="swiper-back" [ngStyle]="getStyles(i)"></div>
            <div
              [ngClass]="{
                'swiper-slide-overlay': true,
                left: slide.align == 'left',
                right: slide.align == 'right',
                'primary-back': slide.backColor == 'primary-back',
                'dark-primary-back': slide.backColor == 'dark-primary-back',
                'light-primary-back': slide.backColor == 'light-primary-back',
                'text-icons-back': slide.backColor == 'text-icons-back',
                'accent-back': slide.backColor == 'accent-back',
                'primary-text-back': slide.backColor == 'primary-text-back',
                'divider-back': slide.backColor == 'divider-back',
                'info-back': slide.backColor == 'info-back'}"></div>
            <div
              class="swiper-context left"
              [ngClass]="{
                'swiper-context': true,
                left: slide.align == 'left',
                right: slide.align == 'right'}">
              <div
                class="swiper-slide-overlay-heading large-content">
                <h4 class="heading-text-slide" >{{slide.heading}}</h4>
              </div>
              <p class="swiper-slide-overlay-p desc-text-slide">{{ slide.desc }}</p>
              <a  class="link-redirect-carousel" target="{{slide.target}}" href="{{slide.link}}">
                <button
                tabindex="0"
                *ngIf="slides != null || slide.cta != ''"
                class="btn btn-lg btn-info link-redirect-carousel"
                (click)="callToAction(slide)"
                [ngClass]="{'set-down-button': slide.heading.length < 50}">
                {{ slide.cta }}
              </button>
              </a>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </swiper>

