import { Observable } from 'rxjs/internal/Observable';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UsersService } from './../../services/users.service';
import { PAGINATION, INIT_PAGINATION_STATE, INIT_PAGINATION_VARS, SEARCH_FILTER_OPTIONS } from './../../consts/consts';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import {
  UserData, UsersEdge,
  PaginationVars,
  PaginationState,
} from '../../graphql/support-console.queries.graphql';
import { MatDialog } from '@angular/material/dialog';

export const MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT = '1000';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  /*Pagination and Search*/
  @ViewChild(MatPaginator) paginatorTop!: MatPaginator;
  paginationVars: PaginationVars = INIT_PAGINATION_VARS;
  paginationState: PaginationState = INIT_PAGINATION_STATE;
  combinedPageIndexSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  usersGraphQL: UsersEdge[] = [];
  FILTER_OPTIONS = SEARCH_FILTER_OPTIONS;
  /*Forms*/
  searchCriteria = new FormControl<string>('', Validators.minLength(2));
  filterSearchCriteria = new FormControl<string>(this.FILTER_OPTIONS[0].criteria);
  /*Loading State*/
  isLoading = new Observable<boolean>();
  /*Banner States*/
  expandPanelClicked!:string;
  expandPanelAfterRequest: string | null = null;
  showNotSupportBanner: boolean = false;
  /*Login History*/
  showFullAuthTypeBadge: boolean = false;
  editUserInfo: boolean = false;

  /*Handle Unsubcriptions*/
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _usersService: UsersService,
    private _breakpointObserver: BreakpointObserver,
    public _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initValues();
    this.bannerState();
    this.loginHistoryAuthTypeBadgeStyle();
    this.loadingState();
    this.pageDataSearchInit();
    this.onFilterCriteriaFieldChanges();
    this.onSearchCriteriaFieldChanges();
    this.getUsers();
  }

  initValues(): void {
    this._usersService.paginationVarsSubject.next(INIT_PAGINATION_VARS);
    this._usersService.paginationStateSubject.next(INIT_PAGINATION_STATE);
    this._usersService.onSearchTextSubject.next('');
  }

  loadUserDataOnExpansionPanelOpen(user: UsersEdge): void {
    this.expandPanelClicked = user.node.id;
  }

  updateUsersFromChild($event:any) {    
    this.getUsers();
    this.expandPanelAfterRequest =  $event.expandPanelAfterRequest;
  }

  setExpandPanelAfterRequest(event:any) {
    this.expandPanelAfterRequest =  event.expandPanelAfterRequest;
  }

  /**
   * Handles the search criteria text from the form input
   */
  onSearchCriteriaFieldChanges(): void {
    this.searchCriteria.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(650),
        distinctUntilChanged(),
      )
      .subscribe(formValue => {
        // When the form value is not empty and meets the minimum length requirement for searching
        if (formValue?.trim() && formValue.length >= PAGINATION.MINIMUM_LENGTH_TO_SEARCH) {
          const filter = this.filterSearchCriteria.value;
          this._usersService.searchAndFilterBy(formValue.trim(), filter);
          this.resetPaginationToInitialPage();
          this.getUsers();
        } else {
          // When the form value is empty or does not meet the minimum length requirement
          formValue ? this.evaluateValueLengthReset(formValue.length) : this.evaluateValueLengthReset(0);
        }
      });
  }

  /**
  * Listens for changes in the filter criteria selection.
  * Performs actions based on the selected value change.
  */
  onFilterCriteriaFieldChanges(): void {
    this.filterSearchCriteria.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(selectedValue => {

      //Call a function or update the search criteria based on the selected value
      if (selectedValue && (this.searchCriteria?.value ?? '').length >= 2) {
        this.resetPaginationToInitialPage();
        const searchValue = this.searchCriteria?.value ?? ''; // Provide a default value of an empty string if it's undefined
        this._usersService.searchAndFilterBy(searchValue.toString(), selectedValue);
        // Update the search criteria or perform any other desired actions
        this.getUsers();
      }
    });
  }

  /**
   * Listens to changes in the screen width to display a banner
   * "Support Console does not have mobile support"
   */
  bannerState(): void {
    this._breakpointObserver
      .observe(`(max-width: ${MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT}px)`).pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((result): void => {
        this.showNotSupportBanner = result.matches;
      });
  }

  /**
   * Listens to changes in the screen width to change
   * the style of the AuthType Badge in Login History Preview
   */
  loginHistoryAuthTypeBadgeStyle(): void {
    this._breakpointObserver
      .observe(`(max-width: 1500px)`).pipe(
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((result): void => {
        this.showFullAuthTypeBadge = result.matches;
      });
  }

  /**
   * Initializes the loading var
   */
  loadingState(): void {
    this.isLoading = this._usersService.isLoading$.pipe(takeUntil(this._unsubscribeAll));
  }

  /**
   * Initializes the pagination state and vars
   */
  pageDataSearchInit(): void {
    this._usersService.paginationVars$.subscribe({
      next: (pageInfo: PaginationVars) => {
        this.paginationVars = pageInfo;
      },
      error: error => {
        console.error('Error to get the pagination vars obs: ', error);
      },
      complete: () => { },
    });

    this._usersService.paginationState$.subscribe({
      next: (pageState: PaginationState) => {
        this.paginationState = pageState;
      },
      error: error => {
        console.error('Error to get the pagination state obs: ', error);
      },
      complete: () => { },
    });
  }

  /**
   * Resets the pagination to the initial page forcefully
   */
  resetPaginationToInitialPage(): void {
    this.paginationVars = INIT_PAGINATION_VARS;
    this._usersService.paginationVarsSubject.next(this.paginationVars);
    this.expandPanelAfterRequest = null;
    this.paginatorTop.firstPage();
  }


  /**
   * Called when the clean Search Box button is clicked
   * Cleans the results of the search and resets the data and pagination
   * @param $clicked
   */
  cleanPaginationAndSearch(clicked?: true): void {
    this._usersService.isLoadingSubject.next(true)
    this.usersGraphQL = [];
    this.paginatorTop.firstPage();
    this.paginatorTop.pageIndex = 0;
    this.paginationState.totalElements = 0;
    this._usersService.onSearchTextSubject.next('');
    if (clicked) {
      this.searchCriteria.reset();
      this.filterSearchCriteria.patchValue('searchByUsername');
    }
    this.expandPanelAfterRequest = null;
    this.paginationVars = INIT_PAGINATION_VARS;
    this._usersService.paginationVarsSubject.next(this.paginationVars);
  }



  /**
   * Calls the service to get a list of users
   */
  async getUsers(): Promise<void> {
    try {
      const res: UserData = await this._usersService.getUsers('network-only');
      this.usersGraphQL = res.edges;
    } catch (error) {
      console.error('Something went wrong for getUsers', error);
    }
  }

  /**
   * Evaluates the length of the search string to clean up the data
   * or reset the default search
   * @param value
   */
  evaluateValueLengthReset(value: number): void {
    if (value === 0) {
      this.cleanPaginationAndSearch();
      this.getUsers();
    } else {
      this.cleanPaginationAndSearch();
    }
  }

  /**
   * Handles the pagination events
   */
  handlePage($event: PageEvent): void {
    this.expandPanelAfterRequest = null;
    if (this.paginationState.pageSize !== $event.pageSize) {
      this.handleOnPageSizeChange($event);
    }

    if ($event.pageIndex === 0) {
      this.goToFirstPage($event);
      return;
    }

    if ($event.pageIndex == this.getNumberOfPages()) {
      this.goToLastPage($event);
      return;
    }

    if ($event.previousPageIndex !== undefined && $event.pageIndex < $event.previousPageIndex) {
      this.handlePreviousPage($event);
      return;
    }

    if ($event.previousPageIndex !== undefined && $event.pageIndex > $event.previousPageIndex) {
      this.handleNextPage($event);
    }
  }

  /**
   * Calculates number of pages base on the total elements and the current page size
   */
  getNumberOfPages(): number {
    if (this.paginationState.totalElements && this.paginationState.pageSize) {
      return (Math.ceil(this.paginationState.totalElements / this.paginationState.pageSize) - 1);
    }
    return 0;
  }

  /**
   * Changes the current pageSize and update the pagination vars to trigger the query
   * @param $event
   */
  handleOnPageSizeChange($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: null,
      before: null,
      last: null,
    };
    this._usersService.paginationVarsSubject.next(this.paginationVars);
    this.combinedPageIndexSubject.next($event.pageIndex);
  }

  /**
   * Changes page to the next one
   * @param $event
   */
  handleNextPage($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: this.paginationState.endCursor,
      before: null,
      last: null,
    };
    this._usersService.paginationVarsSubject.next(this.paginationVars);
    this.getUsers();
    this.combinedPageIndexSubject.next($event.pageIndex);
  }

  /**
   * Changes page to the next one
   * @param $event
   */
  handlePreviousPage($event: PageEvent): void {
    this.paginationVars = {
      first: null,
      after: null,
      before: this.paginationState.startCursor,
      last: $event.pageSize,
    };
    this._usersService.paginationVarsSubject.next(this.paginationVars);
    this.getUsers();
    this.combinedPageIndexSubject.next($event.pageIndex);
    this.paginationState.currentPage = $event.pageIndex;
  }

  /**
   * Changes the page to the firts one
   * */
  goToFirstPage($event: PageEvent): void {
    this.paginationVars = {
      first: $event.pageSize,
      after: null,
      before: null,
      last: null,
    };
    this._usersService.paginationVarsSubject.next(this.paginationVars);
    this.getUsers();
    this.combinedPageIndexSubject.next($event.pageIndex);
  }

  /**
   * Changes the page to the last one
   * */
  goToLastPage($event: PageEvent): void {
    // Notify subscribers of the last page size change
    this._usersService.lastPageSizeChange.next($event.pageSize);

    // Reset pagination variables
    this.paginationVars = {
      first: null,
      after: null,
      before: null,
      last: null,
    };

    // Calculate the appropriate last value based on the total length and page size
    if ($event.length % $event.pageSize !== 0) {
      //If the condition is true, it means there are remaining items that cannot form a complete page.
      this.paginationVars.last = $event.length % $event.pageSize;
    } else {
      //If the condition is false, it means the total length is evenly divisible by the page size, indicating 
      //that there are no remaining items.
      this.paginationVars.last = $event.pageSize;
    }

    // Notify subscribers of the updated pagination variables
    this._usersService.paginationVarsSubject.next(this.paginationVars);

    // Trigger the getUsers() method to fetch data for the last page
    this.getUsers();

    // Notify subscribers of the new page index
    this.combinedPageIndexSubject.next($event.pageIndex);
  }


  lockedEnd(lockedEnd: any): boolean {
    const now = new Date();
    const apiDate = new Date(lockedEnd);
    return apiDate > now;
  }

  /**
   * Resets the values related to pagination and search text.
   * This method is called to restore initial values for pagination variables,
   * pagination state, and search text in the UsersService.
   */
  resetValues(): void {
    // Reset pagination variables to initial values
    this._usersService.paginationVarsSubject.next(INIT_PAGINATION_VARS);

    // Reset pagination state to initial values
    this._usersService.paginationStateSubject.next(INIT_PAGINATION_STATE);

    // Reset last page size change
    this._usersService.lastPageSizeChange.next(0);

    // Clear the search text by setting it to an empty string
    this._usersService.onSearchTextSubject.next('');

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }
}
