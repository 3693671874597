<div class="info-card-container" *ngIf="infoCardData">
  <div class="info-card-container-wrapper">
    <header class="info-card-container-header">
      <div class="info-card-container-header-title">
        <h4 data-angular="information-card-title" class="information-card-title">{{infoCardData.title}}</h4>
      </div>
      <div class="info-card-container-header-legend" data-angular="information-card-hours">
        {{ infoCardData.hours}}
      </div>
    </header>

    <div class="info-card-container-content">
      <div class="info-card-container-content-contact">
        <mat-icon data-angular="information-card-icon-one">{{infoCardData.iconOne}}</mat-icon>
        <div class="info-card-container-content-contact-info">
          <h5 data-angular="information-card-phone">{{infoCardData.phone}}</h5>
        </div>
      </div>

      <div class="info-card-container-content-contact">
        <mat-icon data-angular="information-card-icon-two">{{infoCardData.iconTwo}}</mat-icon>
        <div class="info-card-container-content-contact-info">
          <h5 data-angular="information-card-email"><a href="mailto:{{infoCardData.email}}">{{infoCardData.email}}</a></h5>
        </div>
      </div>

      <div class="info-card-container-content-text">
        <p data-angular="information-card-description">
          {{infoCardData.description}}
        </p>
        <p data-angular="information-card-closing-statement">
          {{infoCardData.closingStatement}}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="info-card-container" *ngIf="infoCard">
  <div class="info-card-container-wrapper">
    <header class="info-card-container-header">
      <div class="info-card-container-header-title" *ngIf="infoCard.title">
        <h4>{{ infoCard.title }}</h4>
      </div>
      <div class="info-card-container-header-legend" *ngIf="infoCard.description">
        {{ infoCard.description }}
      </div>
    </header>
    <div class="info-card-container-content">
      <ng-container *ngFor="let type of infoCard.contacts; let i = index;">
        <ng-container *ngIf="type.slug; else regularDesc">
          <a tabindex="0" class="info-card-container-content-contact cursor-pointer link"
              (click)="contactAction(type)"
              (keyup.enter)="contactAction(type)"
              >
              <mat-icon>{{ type.icon }}</mat-icon>
              <div class="info-card-container-content-contact-info">
                  <h5>{{ type.label }}</h5>
              </div>
          </a>
        </ng-container>
        <ng-template #regularDesc>
          <div class="info-card-container-content-contact">
              <mat-icon>{{ type.icon }}</mat-icon>
              <div class="info-card-container-content-contact-info">
                  <h5>{{ type.label }}</h5>
              </div>
            </div>
        </ng-template>
      </ng-container>

      <div class="info-card-container-content-text" *ngIf="infoCard.link">
        <button mat-button color="primary"
          (click)="actionLink()"
          (keyup.enter)="actionLink()"
          >{{infoCard.link}}
        </button>
      </div>

      <div class="info-card-container-content-text" *ngIf="infoCard.closingStatement">
        <p data-angular="information-card-description">
          {{infoCard.closingStatement}}
        </p>
      </div>
      <div class="info-card-container-content-text" *ngIf="infoCard.signature">
        <p data-angular="information-card-closing-statement">
          {{infoCard.signature}}
        </p>
      </div>
    </div>
  </div>
</div>