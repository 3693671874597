import { Injectable, inject } from '@angular/core';
import { PaginationState, PaginationVars } from '../graphql/support-console.queries.graphql';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../consts/consts';
import { BehaviorSubject } from 'rxjs';
import { LOGIN_HISTORY_QUERY, Login, LoginHistoryQueryVars, LoginHistoryResponse } from '../graphql/login-history.queries.graphql';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class LoginHistoryService {
  /*Dependency Injections For Angular 14*/
  private _graphqlHostService = inject(GraphqlHostService);

  paginationVars: PaginationVars = INIT_PAGINATION_VARS;
  paginationState: PaginationState = INIT_PAGINATION_STATE;

  paginationVarsSubject = new BehaviorSubject<PaginationVars>(this.paginationVars);
  paginationVars$ = this.paginationVarsSubject.asObservable();
  paginationStateSubject = new BehaviorSubject<PaginationState>(this.paginationState);
  paginationState$ = this.paginationStateSubject.asObservable();
  lastPageSizeChange = new BehaviorSubject<number>(0);

  userId: string = '';

  /*Loading States*/
  applyPolicy!: WatchQueryFetchPolicy;
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  errorServerSubject = new BehaviorSubject<boolean>(false);
  errorServer$ = this.errorServerSubject.asObservable();

  constructor() { 
    this.paginationVars$.subscribe((response): void => {
      this.paginationVars = response;
    });
  }

  // Switch Case for Query Vars
  getDataBasedOnVars(): LoginHistoryQueryVars {
    return {
      first: this.paginationVars.first,
      after: this.paginationVars.after,
      last: this.paginationVars.last,
      before: this.paginationVars.before,
      userId: this.userId,
      date: new Date()
    };
  }

  updatePageState(requestResponse: LoginHistoryResponse, paginationVars: any): PaginationState {
    let pageState: PaginationState;
      if (requestResponse.userLoginHistory.pageInfo.hasNextPage) {
        pageState = {
          endCursor: requestResponse.userLoginHistory.pageInfo.endCursor,
          startCursor: requestResponse.userLoginHistory.pageInfo.startCursor,
          totalElements: requestResponse.userLoginHistory.totalCount,
          pageSize: paginationVars.first ? paginationVars.first : paginationVars.last,
        };
      } else {
        // If it's the last page, determine the pageSize based on the totalCount
        const pageSizeOnLastPage: number = paginationVars.first ? paginationVars.first : paginationVars.last;
        pageState = {
          endCursor: null,
          startCursor: requestResponse.userLoginHistory.pageInfo.startCursor,
          totalElements: requestResponse.userLoginHistory.totalCount,
          pageSize: requestResponse.userLoginHistory.totalCount <= pageSizeOnLastPage ? pageSizeOnLastPage : this.lastPageSizeChange.value,
        };
      }
    return pageState;
  }

  getLoginHistory(policy: FetchPolicy, userId: string): Promise<Login> {
    this.isLoadingSubject.next(true);
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const vars: LoginHistoryQueryVars = this.getDataBasedOnVars();
    vars.userId = userId;
    vars.date = this.getDateMonthBefore();
    const optionalContext = null;
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          LOGIN_HISTORY_QUERY,
          vars,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: LoginHistoryResponse) => {
          this.paginationStateSubject.next(this.updatePageState(response, vars));
          resolve(response.userLoginHistory);
          this.isLoadingSubject.next(false);
        })
        .catch((error) => {
          this.isLoadingSubject.next(false);
          reject(error);
        });
    });
  }

  getDateMonthBefore(): Date {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const dateMonthBefore = new Date();
    dateMonthBefore.setMonth(currentMonth - 1);

    return dateMonthBefore;
  }
}